import { createColumnHelper } from '@tanstack/react-table';
import { TFunction } from 'i18next';
import {
  SearchVariantItem,
  ArticleState,
  ArticleProcess,
} from '@texas/api/endpoints/searchApi';
import { StateIndicator } from '@texas/components/shared/indicators/StateIndicator';
import { StepsIndicator } from '@texas/components/shared/indicators/StepsIndicator';
import { Locale } from '@texas/i18n/types';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import { AngularNavLink } from '../shared/AngularNavLink';

export const TableColumns = (t: TFunction) => {
  const columnHelper = createColumnHelper<SearchVariantItem>();
  const columns = [
    columnHelper.accessor('itemCode', {
      cell: (info) => (
        <AngularNavLink
          to="article"
          navobj={{
            articleId: info.row.original.articleId,
          }}
        >
          {info.getValue()}
        </AngularNavLink>
      ),
      header: t('general.itemCode'),
    }),
    columnHelper.accessor('variantName', {
      cell: (info) => (
        <AngularNavLink
          to="variant"
          navobj={{
            articleId: info.row.original.articleId,
            variantId: info.row.original.variantId,
          }}
        >
          {info.getValue()}
        </AngularNavLink>
      ),
      header: t('variant.variant'),
      enableSorting: false,
    }),
    columnHelper.accessor('customerName', {
      cell: (info) => info.getValue(),
      header: t('general.brand'),
      enableSorting: false,
    }),
    columnHelper.accessor('concept', {
      cell: (info) => info.getValue(),
      header: t('general.concept'),
      enableSorting: false,
    }),
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: t('general.description'),
      enableSorting: false,
    }),
    columnHelper.accessor('branch', {
      cell: (info) => info.getValue(),
      header: t('general.branch'),
      enableSorting: false,
    }),
    columnHelper.accessor('sampleReadyDate', {
      cell: (info) => formatDate(Locale.En, info.getValue()),
      header: t('article.sampleReadyDate'),
      enableSorting: true,
    }),
    columnHelper.accessor('created', {
      cell: (info) => formatDate(Locale.En, info.getValue()),
      header: t('article.created'),
      enableSorting: true,
    }),
    columnHelper.accessor('state', {
      cell: (info) => (
        <StateIndicator
          state={
            ArticleState[
              info.getValue() as unknown as keyof typeof ArticleState
            ]
          }
        />
      ),
      header: t('general.state'),
      enableSorting: false,
    }),
    columnHelper.accessor('articleProcess', {
      cell: (info) =>
        ArticleState[
          info.row.original.state as unknown as keyof typeof ArticleState
        ] == ArticleState.Cancelled ? null : (
          <StepsIndicator
            currentStep={
              ArticleProcess[
                info.getValue() as unknown as keyof typeof ArticleProcess
              ]
            }
          />
        ),
      enableSorting: false,
      header: t('general.articleProcess'),
    }),
  ];

  return columns;
};
