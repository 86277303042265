import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  useDisclosure,
  useToast,
  Flex,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ServerError } from '../../../types';
import { request } from '../../../utils/helpers/httpHelpers';
import {
  UserProfile,
  userProfilesApi,
} from '@texas/api/endpoints/userProfilesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { Icons } from '@texas/components/shared/Icons';
import { DataTableContainer } from '@texas/components/shared/dataTable/DataTableContainer';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { ArchiveUserProfileDialog } from './ArchiveUserProfileDialog';
import { EditUserProfileModal } from './EditUserProfilesModal';
import { CreateUserProfileForm } from './forms/CreateUserProfileForm';
import { TableColumns } from './TableColumns';
import { TexasSwitch } from '@texas/components/shared/form/TexasSwitch';

interface UserProfilesProps {
  userId: number;
}

export function UserProfiles(props: UserProfilesProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    value: archiveValue,
    isOpen: isArchiveOpen,
    onOpen: onArchiveOpen,
    onClose: onArchiveClose,
  } = useValueDisclosure<UserProfile>();
  const {
    value: editValue,
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useValueDisclosure<UserProfile>();

  const { t } = useTranslation();
  const toast = useToast();

  const {
    data: userProfiles,
    refetch,
    error,
    loading,
  } = useApiResource(userProfilesApi.getUserProfilesFromUser);

  const [includeArchived, setIncludeArchived] = useState(false);

  const { request: restureUserProfileRequest } = useApiRequest(
    userProfilesApi.restoreUserProfile,
  );

  useEffect(() => {
    refetch(props.userId, includeArchived);
  }, [refetch, props.userId, includeArchived]);

  const performRestore = useCallback(
    async (id: number) => {
      await request(
        restureUserProfileRequest,
        [id],
        (_) => {
          toast({
            title: t('general.restored'),
            status: 'success',
            isClosable: true,
          });
          refetch(props.userId, includeArchived);
        },
        (error: ServerError) => {
          toast({
            title: t('general.restoreFailed'),
            description: error.message,
            status: 'error',
            isClosable: true,
          });
        },
      );
    },
    [
      restureUserProfileRequest,
      toast,
      t,
      refetch,
      props.userId,
      includeArchived,
    ],
  );

  const columns = useMemo(() => {
    return TableColumns(t, onArchiveOpen, performRestore, onEditOpen);
  }, [t, onArchiveOpen, performRestore, onEditOpen]);

  return (
    <>
      {archiveValue && (
        <ArchiveUserProfileDialog
          userProfile={archiveValue}
          isOpen={isArchiveOpen}
          onClose={onArchiveClose}
          onArchive={(_) => {
            refetch(props.userId, includeArchived);
          }}
        />
      )}

      <EditUserProfileModal
        userProfile={editValue}
        isOpen={isEditOpen}
        onClose={onEditClose}
        onProfileUpdated={(_) => {
          refetch(props.userId, includeArchived);
        }}
      />
      <Flex direction="column" p={4}>
        <Flex justify="space-between" pb={4}>
          <TexasSwitch
            checked={includeArchived}
            label={t('general.includeArchived')}
            onChange={(checked) => setIncludeArchived(checked)}
          />
          <Popover
            onClose={onClose}
            onOpen={onOpen}
            isOpen={isOpen}
            variant="above-angular"
          >
            <PopoverTrigger>
              <Button variant="texas-light">
                <>{t('general.addNew')}</> <Icons.ArrowDownRight ml={2} />
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader fontWeight="bold">
                {t('userProfile.addNewUserProfile')}
              </PopoverHeader>
              <PopoverBody>
                <CreateUserProfileForm
                  userId={props.userId}
                  onProfileCreated={(_) => {
                    refetch(props.userId, includeArchived);
                    onClose();
                  }}
                />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>

        <DataTableContainer
          w="100%"
          datatable={{
            data: userProfiles ?? [],
            isLoading: loading,
            columns: columns,
          }}
          error={error}
        />
      </Flex>
    </>
  );
}
