import { useRadioGroup, Flex } from '@chakra-ui/react';
import { Role } from '@texas/api/endpoints/userApi';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';
import { RadioCard } from './RadioCard';
import React from 'react';

interface Props {
  onChange: (nextValue: Role) => void;
  value: Role;
  options: { component: React.ReactNode; value: Role }[];
}

export const RoleRadioGroup = React.forwardRef(function RoleRadioGroup(
  { onChange, value, options }: Props,
  ref,
) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'role',
    value: convertToEnum(Role, value),
    onChange: (v) => onChange(convertToEnum(Role, v)),
  });

  const group = getRootProps();

  return (
    <Flex ref={ref} {...group} gap={2}>
      {options.map((option) => {
        const radio = getRadioProps({ value: option.value });
        return (
          <RadioCard key={option.value} props={radio}>
            {option.component}
          </RadioCard>
        );
      })}
    </Flex>
  );
});
