import { Text, useToast } from '@chakra-ui/react';
import { handlePaste } from './shared';
import React from 'react';
import { Accept, FileError } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

interface PasteProps {
  onUpload: (files: File[]) => void;
  multiple: boolean;
  accept?: Accept;
  validator?: (file: File) => FileError | readonly FileError[] | null;
}
export const PasteFileInput = React.forwardRef<HTMLInputElement, PasteProps>(
  function PasteFileInput(props, ref) {
    const toast = useToast();
    const { t } = useTranslation();

    async function onPaste(data: DataTransfer) {
      const pasteResult = await handlePaste(
        data,
        t,
        props.onUpload,
        props.multiple,
        props.accept,
        props.validator,
      );

      if (pasteResult && !pasteResult.success) {
        toast({
          position: 'bottom',
          title: t('fileBrowser.pasteFailed'),
          description: pasteResult.errors?.map((e, i) => (
            <Text key={i}>{e}</Text>
          )),
          status: 'error',
          isClosable: true,
        });
      }
    }
    return (
      <input
        style={{ height: '0' }}
        onPaste={(e) => {
          e.preventDefault();
          onPaste(e.clipboardData);
        }}
        ref={ref}
      />
    );
  },
);
