import { httpClient } from '../httpClient/httpClient';

export interface FileLink {
  id: number;
  name: string;
  originalName: string;
  identifier: string;
  size: number;
  extension: string;
  creatorId: number;
  creatorName: string;
  folderId: number;
  created: string;
  updated: string;
  archived: boolean;
  note: string;
}

export interface Folder {
  id: number;
  name: string;
  parentId: number;
  creatorId: number;
  organizationId: number;
  customerId: number;
  conceptId: number;
  articleId: number;
  articleVariantId: number;
  supplierId: number;
  created: string;
  updated: string;
  hasFolders: boolean;
  isReadOnly: boolean;
  isEditable: boolean;
  archived: boolean;
  folders: Folder[];
  files: FileLink[];
}

export interface CreateFolderRequest {
  name: string;
}
export interface UpdateFolderRequest extends CreateFolderRequest {
  archived?: boolean;
}

export interface UpdateFolderResponse {
  name: string;
  updated: string;
  archived: boolean;
}

export interface UpdateFileRequest {
  name: string;
  archived?: boolean;
  note: string;
}

export interface UpdateFileResponse {
  id: number;
  name: string;
  archived: boolean;
  note: string;
  updated: string;
}

export interface FileUsageResponse {
  document: boolean;
  artwork: boolean;
  photo: boolean;
  matrixPhoto: boolean;
}

export const filesApi = {
  uploadToFolder: (file: File, folderId: number) => {
    const data = new FormData();
    data.append('file', file);
    const abortController = new AbortController();

    return {
      uploadPromise: httpClient.post<FileLink>(
        `files/upload/folder/${folderId}`,
        {
          data,
          signal: abortController.signal,
        },
      ),
      abortController,
    };
  },
  getFolder: (folderId: number, includeArchived?: boolean) =>
    httpClient.get<Folder>(`folders/${folderId}`, {
      params: { includeArchived: includeArchived },
    }),
  updateFolder: (folderId: number, request: UpdateFolderRequest) =>
    httpClient.put<UpdateFolderResponse>(`folders/${folderId}`, {
      data: request,
    }),
  createFolder: (parentId: number, request: CreateFolderRequest) =>
    httpClient.post<Folder>(`folders/${parentId}/folders`, {
      data: request,
    }),
  updateFile: (identifier: string, request: UpdateFileRequest) =>
    httpClient.put<UpdateFileResponse>(`files/${identifier}`, {
      data: request,
    }),
  archiveFile: (fileId: number) =>
    httpClient.put<UpdateFileResponse>(`files/${fileId}/archive`),
  restoreFile: (fileId: number) =>
    httpClient.put<UpdateFileResponse>(`files/${fileId}/restore`),
  getFileLink: (id: number) => httpClient.get<FileLink>(`files/link/${id}`),
  getFileUsage: (id: number) =>
    httpClient.get<FileUsageResponse>(`files/usage/${id}`),
  previewPdf: (identifier: string) =>
    httpClient.get<string>(`files/${identifier}/preview/pdf`),
};
