import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Fade,
  Flex,
  HStack,
  SlideFade,
  Tooltip,
} from '@chakra-ui/react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import navbar from '@assets/navbar_bg.svg';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { AngularNavLink } from '@texas/components/shared/AngularNavLink';
import { RoleRestrict } from '@texas/components/shared/RoleRestrict';
import { Icons } from '@texas/components/shared/Icons';
import { IconWithBadge } from '@texas/components/shared/IconWithBadge';
import useChakraOutsideClick, {
  ModalState,
  reverseModalState,
} from '@texas/hooks/useChakraOutsideClick';
import { angularEvents } from 'src/bridge/angularEvents';
import { useActiveSession, useSession } from '@texas/hooks/useSession';
import { EditUser } from '../users/EditUser';
import { Role } from '@texas/api/endpoints/userApi';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';

const actionBarIconSize = 6;

// Since these views are rendered and handled in angular, we cannot map the data
// until we fetch the data in react and map ids -> names inside a context
const maskedBreadcrumbs = [
  {
    path: 'articles',
    children: [
      { path: ':id', breadcrumb: null },
      { path: ':id/variants', breadcrumb: null },
      { path: ':id/variants/:id', breadcrumb: null },
      { path: ':id/variants/:id/general', breadcrumb: null },
    ],
    breadcrumb: null,
  },
  {
    path: 'suppliers',
    children: [{ path: ':id', breadcrumb: null }],
  },
  {
    path: 'customers',
    children: [{ path: ':id', breadcrumb: null }],
  },
  {
    path: 'concepts',
    breadcrumb: null,
    children: [{ path: ':id', breadcrumb: null }],
  },
  {
    path: 'searcharticles',
    breadcrumb: 'Search Articles',
  },
];

export function ActionBar() {
  const breadcrumbs = useBreadcrumbs(maskedBreadcrumbs, {
    excludePaths: ['app', '/'],
  });
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState(false);
  const session = useActiveSession();
  const { value, onOpen, onClose, isOpen } = useValueDisclosure<number>();

  useEffect(() => {
    return angularEvents.pollReceived.subscribe((count) => {
      setNotifications(count > 0);
    });
  });
  return (
    <Flex w="100%" justifyContent="center">
      <Box pos="relative">
        <SlideFade offsetX={20} offsetY={0} in={true}>
          <Breadcrumb
            fontWeight="bold"
            fontSize="sm"
            color="gray.500"
            pos="absolute"
            right="100%"
            whiteSpace="pre"
          >
            {breadcrumbs.map(({ match, breadcrumb }, i) => (
              <BreadcrumbItem
                key={match.pathname}
                opacity={i < breadcrumbs.length - 1 ? 0.5 : 1}
              >
                <BreadcrumbLink
                  isCurrentPage={i < breadcrumbs.length - 1}
                  href={`/#${match.pathname}`}
                >
                  {breadcrumb}
                </BreadcrumbLink>
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </SlideFade>
        <Box pos="relative" w="25em">
          <Box
            zIndex="0"
            pos="absolute"
            h="100%"
            w="100%"
            sx={{
              maskSize: '100% 100%',
              maskImage: `url(${navbar})`,
            }}
            backgroundColor="texas.bg.800"
          />
          <HStack
            justifyContent="center"
            alignItems="center"
            padding={0.5}
            transform="translateY(-0.6em)"
            zIndex="1"
            pos="relative"
            css={{
              svg: {
                opacity: 0.6,
                cursor: 'pointer',
              },
              'a:hover, svg:hover': {
                opacity: 1,
              },
            }}
          >
            <Tooltip label={t('general.account')}>
              <Icons.AccountCircle
                _light={{
                  color: 'white',
                }}
                boxSize={actionBarIconSize}
                onClick={() => onOpen(session.currentUser.id)}
              />
            </Tooltip>
            <AngularNavLink to="tasks">
              <IconWithBadge
                tooltipLabel={t('general.tasks')}
                badge={notifications}
                icon={
                  <Icons.CalendarCheck
                    boxSize={actionBarIconSize}
                    opacity={0.6}
                    _light={{
                      color: 'white',
                    }}
                  />
                }
              />
            </AngularNavLink>
            <HiddenActions />
          </HStack>
        </Box>
      </Box>
      {value && <EditUser id={value} onClose={onClose} isOpen={isOpen} />}
    </Flex>
  );
}

export function HiddenActions() {
  const { ref, modalState, setModalState } =
    useChakraOutsideClick<HTMLDivElement>(ModalState.Closed);
  const { t } = useTranslation();
  const { logout } = useSession();
  return (
    <Box pos="relative" ref={ref}>
      <Tooltip label={t('general.more')}>
        <Icons.DotsHorizontal
          cursor="pointer"
          pos="relative"
          zIndex="1"
          onClick={() => {
            setModalState(reverseModalState(modalState));
          }}
          boxSize={actionBarIconSize}
          _light={{
            color: 'white',
          }}
        />
      </Tooltip>
      <Box
        zIndex="0"
        pos="absolute"
        top="0"
        // Calculation for correct offset
        left="calc(-100% + 32px - 0.5rem - 4px)"
      >
        <Fade in={modalState === ModalState.Open}>
          {modalState === ModalState.Open && (
            <Box
              borderRadius={22}
              backgroundColor="texas.bg.700"
              px={2}
              pl="32px"
            >
              <HStack>
                <RoleRestrict
                  allow={[Role.SystemAdministrator, Role.InternalAdministrator]}
                >
                  <AngularNavLink to="configuration">
                    <Tooltip label={t('actionBar.configuration')}>
                      <Icons.Cog
                        boxSize={actionBarIconSize}
                        _light={{
                          color: 'white',
                        }}
                      />
                    </Tooltip>
                  </AngularNavLink>
                </RoleRestrict>

                <RoleRestrict allow={[Role.SystemAdministrator]}>
                  <AngularNavLink to="archive">
                    <Tooltip label={t('actionBar.archive')}>
                      <Icons.Archive
                        boxSize={actionBarIconSize}
                        _light={{
                          color: 'white',
                        }}
                      />
                    </Tooltip>
                  </AngularNavLink>
                </RoleRestrict>

                <Button
                  variant="unstyled"
                  boxSize="auto"
                  minW="auto"
                  onClick={async () => {
                    await logout();
                  }}
                >
                  <Tooltip label={t('actionBar.signOut')}>
                    <Icons.Logout
                      boxSize={actionBarIconSize}
                      _light={{
                        color: 'white',
                      }}
                    />
                  </Tooltip>
                </Button>
              </HStack>
            </Box>
          )}
        </Fade>
      </Box>
    </Box>
  );
}
