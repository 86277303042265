import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  FormControl,
  Input,
  Flex,
} from '@chakra-ui/react';
import { authApi } from '@texas/api/endpoints/authApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Icons } from '../shared/Icons';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';
import { ErrorLabel } from '../shared/ErrorLabel';
import { useTranslation } from 'react-i18next';

interface ForgotPasswordForm {
  email: string;
}

export function ForgotPasswordModal({ label }: { label: string }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        variant="link"
        color="whiteAlpha.800"
        onClick={onOpen}
        pt={2}
        textDecoration="underline"
        textUnderlineOffset={3}
      >
        {label}
      </Button>

      <Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <InnerModal label={label} onClose={onClose} />
      </Modal>
    </>
  );
}

function InnerModal({
  onClose,
  label,
}: {
  onClose: () => void;
  label: string;
}) {
  const [sent, setSent] = useState(false);

  return (
    <ModalContent>
      <ModalHeader as={Flex} gap={2} alignItems="center">
        <Icons.LockQuestion />
        <Text>{label}</Text>
      </ModalHeader>
      <ModalCloseButton />
      {sent ? (
        <Sent onClose={onClose} />
      ) : (
        <Form onSent={() => setSent(true)} />
      )}
    </ModalContent>
  );
}

function Sent({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();
  return (
    <>
      <ModalBody>
        <Text animation={fadeInScaleAnimation()}>
          {t('auth.sentInstructions')}
        </Text>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>{t('general.close')}</Button>
      </ModalFooter>
    </>
  );
}

function Form({ onSent }: { onSent: () => void }) {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<ForgotPasswordForm>();

  const { request } = useApiRequest(authApi.sendResetPasswordToken);
  const { t } = useTranslation();

  const onSubmit = async (data: ForgotPasswordForm) => {
    await request(data.email);
    onSent();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        <Text>{t('auth.requestInstructions')}</Text>
        <FormControl mt={2} isInvalid={!!errors.email?.message}>
          <Input
            type="email"
            placeholder={t('general.email')}
            {...register('email', { required: true })}
            variant="texas"
          />
          <ErrorLabel text={errors.email?.message} />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          {t('auth.sendLink')}
        </Button>
      </ModalFooter>
    </form>
  );
}
