// @ngInject
const relationsResolve = (article, ResourcesService) =>
  ResourcesService.loadRelations('article', article, [
    'matrixNodeX',
    'matrixNodeY',
    'concept',
    'productGroup',
  ]);

// @ngInject
const usersResolve = (currentOrganization, UsersApiService) =>
  UsersApiService.getAllByOrganizationId(currentOrganization.id);

// @ngInject
const tagsResolve = (currentOrganization, ArticleTagsApiService) =>
  ArticleTagsApiService.getAllByOrganizationId(currentOrganization.id);

// @ngInject
const conceptsResolve = (article, ConceptsApiService) =>
  ConceptsApiService.getAllByCustomerId(article.customerId);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('article.management', {
    url: '/management',
    templateUrl: 'main/article/management/management.tpl.html',
    controller: 'ArticleManagementController as vm',
    resolve: {
      articleRelations: relationsResolve,
      users: usersResolve,
      concepts: conceptsResolve,
      tags: tagsResolve,
    },
  });
};

class ArticleManagementController {
  constructor(
    $scope,
    $location,
    $filter,
    article,
    HelperService,
    AuthService,
    ArticleVariantDialogService,
    ArticlesApiService,
    loadSpinnerService,
    toasterService,
    ArticleDialogService,
    currentUser,
    ConceptsApiService,
    CopyArticleDialogService,
    concepts,
    productGroups,
    ProductGroupsApiService,
    users,
    tags,
    SavingService,
    variantOptions,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$filter = $filter;
    this.article = article;
    this.HelperService = HelperService;
    this.AuthService = AuthService;
    this.CopyArticleDialogService = CopyArticleDialogService;
    this.ArticleVariantDialogService = ArticleVariantDialogService;
    this.ArticlesApiService = ArticlesApiService;
    this.loadSpinnerService = loadSpinnerService;
    this.toasterService = toasterService;
    this.ArticleDialogService = ArticleDialogService;
    this.currentUser = currentUser;
    this.ConceptsApiService = ConceptsApiService;
    this.ProductGroupsApiService = ProductGroupsApiService;
    this.SavingService = SavingService;
    this.$location = $location;

    this.originalArticle = article;
    this.variantOptions = variantOptions;
    this.users = users;
    this.concepts = concepts;
    this.productGroups = productGroups;
    this.tags = tags;
    this.selectedConcept = null;
    this.selectedProductGroup = null;
    this.selectedOwner = null;
    this.currentUserCanEdit = AuthService.hasAnyClaim([
      'SystemAdministrator',
      'InternalAdministrator',
      'InternalAgent',
    ]);
    this.currentUserCanEditBrandOwner = AuthService.hasAnyClaim([
      'SystemAdministrator',
    ]);
    this.saveObj = SavingService.registerSave({
      onSave: () => this.save(this.getChangedData()),
      onDiscard: () => this.discard(),
      onValidate: () => this.validate(),
    });

    this.setArticleClone();
    this.setWatchers();
    this.resetForm();
  }

  resetForm() {
    this.selectedConcept = this.article.conceptId ? this.article.concept : null;
    this.selectedProductGroup = this.article.productGroupId
      ? this.article.productGroup
      : null;
    if (this.articleForm) {
      this.articleForm.$setPristine(true);
    }
  }

  setArticleClone() {
    this.article = _.extend({}, this.originalArticle);
    this.article.owner = _.extend({}, this.originalArticle.owner);
    this.article.concept = _.extend({}, this.originalArticle.concept);
    this.article.productGroup = _.extend({}, this.originalArticle.productGroup);
    this.article.articleTags = _.clone(this.originalArticle.articleTags);
  }

  newVariant() {
    this.ArticleVariantDialogService.showDialog({ articleId: this.article.id });
  }

  copyArticle() {
    this.CopyArticleDialogService.showDialog({
      customerId: this.article.customerId,
      articleId: this.article.id,
      itemCodeMinLength: this.article.itemCodeMinLength,
      itemCodeMaxLength: this.article.itemCodeMaxLength,
      conceptId: this.article.conceptId ? this.article.conceptId : null,
      productGroupId: this.article.productGroupId
        ? this.article.productGroupId
        : null,
    }).then((newArticle) =>
      this.$location.path(`app/articles/${newArticle.id}`),
    );
  }

  getChangedData() {
    const fields = [
      'name',
      'itemCode',
      'season',
      'state',
      'ownerId',
      'conceptId',
      'productGroupId',
    ];

    const changedData = this.HelperService.getChangedData(
      this.originalArticle,
      this.article,
      fields,
    );

    const changedTags = this.HelperService.isArrayModified(
      this.originalArticle.articleTags,
      this.article.articleTags,
      ['name'],
    );
    if (changedTags) {
      changedData.articleTags = this.article.articleTags;
    }

    return changedData;
  }

  save(data) {
    this.validationErrors = [];
    this.loadSpinnerService.start('mainSpinner');
    this.ArticlesApiService.updateArticle(data, this.article.id)
      .then(
        () => {
          this.setArticleClone();
          this.toasterService.success();
        },
        (error) => {
          this.validationErrors = error.errors;
        },
      )
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  validate() {
    if (!this.articleForm.$valid) {
      this.validationErrors = [{ message: 'Form is not valid' }];
      return false;
    }
  }

  archive() {
    this.save({ archived: !this.article.archived });
  }

  discard() {
    this.validationErrors = [];
    this.setArticleClone();
    this.resetForm();
  }

  setOwner(owner) {
    if (this.currentUserCanEditBrandOwner) {
      this.article.ownerId = owner ? owner.id : 0;
      this.article.owner = owner;
    }
  }

  setConcept(concept) {
    this.article.conceptId = concept ? concept.id : 0;
    this.article.concept = concept;
  }

  getCurrentConcepts() {
    const concepts = _.filter(this.concepts, { archived: false });
    if (
      this.originalArticle.concept &&
      !_.some(concepts, { id: this.originalArticle.concept.id })
    ) {
      concepts.push(this.originalArticle.concept);
    }
    return concepts;
  }

  setProductGroup(productGroup) {
    this.article.productGroupId = productGroup ? productGroup.id : 0;
    this.article.productGroup = productGroup;
  }

  getCurrentProductGroups() {
    const productGroups = _.filter(this.productGroups, { archived: false });
    if (
      this.originalArticle.productGroup &&
      !_.some(productGroups, { id: this.originalArticle.productGroup.id })
    ) {
      productGroups.push(this.originalArticle.productGroup);
    }
    return productGroups;
  }

  filterProductGroups(query) {
    return _.orderBy(
      this.$filter('filter')(this.getCurrentProductGroups(), { value: query }),
      ['value'],
    );
  }

  filterUsers(query) {
    if (this.currentUserCanEditBrandOwner) {
      return this.$filter('filter')(this.users, { uniqueName: query });
    }
  }

  filterConcepts(query) {
    return _.orderBy(
      this.$filter('filter')(this.getCurrentConcepts(), { name: query }),
      ['name'],
    );
  }

  filterTags(query) {
    return _.chain(this.$filter('filter')(this.tags, { name: query }))
      .filter((tag) => !_.find(this.article.articleTags, { name: tag.name }))
      .value();
  }

  addTag($chip) {
    if (_.isString($chip)) {
      $chip = { name: $chip };
    }

    if (
      _.some(
        this.article.articleTags,
        ({ name }) => name.toLowerCase() === $chip.name.toLowerCase(),
      )
    ) {
      return null;
    } else {
      return $chip.id ? { name: $chip.name } : null;
    }
  }

  hasMatrixNodes() {
    return (
      !!_.get(this.article, 'matrixNodeX.matrixNodeValues') &&
      !!_.get(this.article, 'matrixNodeY.matrixNodeValues')
    );
  }

  hasBranches() {
    return !!_.get(this.currentUser, 'organization.branches');
  }

  configure() {
    if (!this.hasMatrixNodes() && !this.hasBranches()) {
      return;
    }

    this.ArticleDialogService.showDialog({
      article: this.originalArticle,
    }).then((article) => {
      if (
        this.variantOptions.selectedBranch !== null &&
        !_.find(
          article.branches,
          (b) => b.id === this.variantOptions.selectedBranch.id,
        )
      ) {
        this.variantOptions.selectedBranch = null;
      }
    });
  }

  setWatchers() {
    this.$scope.$watch(
      () => {
        return !_.isEmpty(this.getChangedData());
      },
      (hasChanges) => {
        if (!hasChanges) {
          this.articleForm.$setPristine();
        }
        this.saveObj.hasChanges = hasChanges;
      },
    );

    this.$scope.$on('$destroy', () => {
      this.SavingService.deregisterSave(this.saveObj);
    });
  }
}

angular
  .module('main.article.management', [])
  .config(config)
  .controller('ArticleManagementController', ArticleManagementController);
