import { Flex, Badge } from '@chakra-ui/react';
import { ProductApprovalRejectReason } from '@texas/api/endpoints/productApprovalsApi';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';
import { useTranslation } from 'react-i18next';
import { rejectReasonAsFriendlyName } from '../shared';

export function RejectReasons({
  reasons,
}: {
  reasons: ProductApprovalRejectReason[] | null;
}) {
  const { t } = useTranslation();
  if (!reasons) return null;

  const friendlyReasons = reasons.map((r) =>
    rejectReasonAsFriendlyName(
      t,
      convertToEnum(ProductApprovalRejectReason, r),
    ),
  );
  return (
    <Flex display="inline-flex" justifyContent="end" gap={1} flexWrap="wrap">
      {friendlyReasons.map((x, i) => (
        <Badge key={i}>{x}</Badge>
      ))}
    </Flex>
  );
}
