import { ChakraComponent, Link } from '@chakra-ui/react';
import { LinkProps } from 'react-router-dom';
import { reactEvents } from 'src/bridge/reactEvents';

export interface AngularNavLinkProps extends LinkProps {
  navobj?: any;
  variant?: string;
}

type LinkComponent = ChakraComponent<'a', AngularNavLinkProps>;

export const AngularNavLink = ((props: AngularNavLinkProps) => {
  const isCurrent = window.location.hash.includes(props.to.toString());

  return (
    <Link
      {...props}
      {...(isCurrent ? { 'aria-current': 'page' } : null)}
      textDecoration="none"
      onClick={(e) => {
        if (props.onClick !== undefined) {
          props.onClick(e);
        }

        reactEvents.routingChanged.dispatch({
          to: props.to.toString(),
          navObj: props.navobj,
        });
      }}
    >
      {props.children}
    </Link>
  );
}) as LinkComponent;
