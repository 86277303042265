import { BodyOverride } from '../BodyOverride';
import { FileBrowserContent } from './FileBrowserContent';

export function FileBrowserTab({
  rootFolderId,
  isArticle,
}: {
  rootFolderId: number;
  isArticle: boolean;
}) {
  return (
    <BodyOverride>
      <FileBrowserContent rootFolderId={rootFolderId} isArticle={isArticle} />
    </BodyOverride>
  );
}
