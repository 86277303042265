import { FilterOptionIdsQuery } from './api/endpoints/productApprovalsApi';
import { objectToQueryString } from './utils/helpers/commonHelpers';

export const clientEndpoints = {
  articleProductApproval: (articleId: number, query?: FilterOptionIdsQuery) =>
    `#app/articles/${articleId}/product-approval${objectToQueryString(query)}`,
  previewImage: (identifier: string, size: number) =>
    `/api/files/${identifier}/preview/${size}`,
  previewPdf: (identifier: string) => `/api/files/${identifier}/preview/pdf`,
  previewArticleArtworkImage: (variantId: number, fileId: number) =>
    `/api/variants/${variantId}/artworkimage?id=${fileId}`,
  previewArticlePhoto: (variantId: number, fileId: number) =>
    `/api/variants/${variantId}/photo?id=${fileId}`,
  downloadFile: (identifier: string) => `/api/files/${identifier}`,
};
