import {
  Box,
  Flex,
  IconButton,
  Image,
  Tag,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { Icons } from '@texas/components/shared/Icons';
import {
  fadeInRightAnimation,
  fadeInScaleAnimation,
} from '@texas/resources/animations/animations';
import placeholderImg from '@texas/assets/placeholder-250.png';
import { useTranslation } from 'react-i18next';
import { FileRejection, useDropzone } from 'react-dropzone';
import { acceptedImageFormats } from '@texas/utils/helpers/filesHelper';
import { clientEndpoints } from '@texas/clientEndpoints';
import { useCallback, useRef, useState } from 'react';
import { BlurTag } from '@texas/components/shared/blur/BlurTag';
import { sizeValidator } from './shared';
import { FileUpload } from '@texas/types';
import { FileUploads } from './FileUploads';
import { FileRejections } from './FileRejections';
import { PasteFileInput } from './PasteFileInput';
import { ExpandImage } from '../ExpandImage';

export function ImageDropzone({
  imageId,
  imageIdentifier,
  imageSrc,
  imageArchived,
  allowEdit,
  onUpload,
  isUploading,
  uploadFailed,
  fileUploads,
  abortFileUpload,
  onRemovefileOpen,
  onFileBrowserOpen,
  children,
}: {
  imageId: number;
  imageIdentifier: string;
  imageSrc: string;
  imageArchived: boolean;
  allowEdit: boolean;
  onUpload: (files: File[]) => void;
  isUploading: boolean;
  uploadFailed: boolean;
  fileUploads: FileUpload[];
  abortFileUpload: (file: FileUpload) => void;
  onRemovefileOpen: () => void;
  onFileBrowserOpen: () => void;
  children?: React.ReactNode;
}) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileRejections, setFileRejections] = useState<FileRejection[]>();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { getRootProps, getInputProps, isDragActive, isDragReject, open } =
    useDropzone({
      onDrop: handleDrop,
      useFsAccessApi: false,
      noClick: true,
      accept: acceptedImageFormats,
      multiple: false,
      validator: (file) => sizeValidator(file, t),
    });

  function handleDrop(acceptedFiles: File[], fileRejections: FileRejection[]) {
    if (!allowEdit) return;

    if (fileRejections.length) {
      setFileRejections(fileRejections);
      onOpen();
    }

    if (acceptedFiles.length) {
      onUpload(acceptedFiles);
    }
  }

  const setFocus = useCallback(() => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  }, [inputRef]);

  const setBlur = useCallback(() => {
    if (!inputRef.current) return;
    inputRef.current.blur();
  }, [inputRef]);

  return (
    <Box
      animation={fadeInScaleAnimation()}
      onMouseEnter={() => setFocus()}
      onMouseLeave={() => setBlur()}
      {...getRootProps()}
    >
      {(isUploading || uploadFailed) && (
        <FileUploads
          fileUploads={fileUploads}
          abortFileUpload={abortFileUpload}
        />
      )}
      <Box role="group" position="relative" w="fit-content">
        <Flex maxH={imageId ? '300px' : '150px'}>
          <ExpandImage identifier={imageIdentifier}>
            <Image
              key={imageId}
              src={imageSrc}
              fallbackSrc={placeholderImg}
              objectFit={imageId ? 'contain' : 'cover'}
              filter={isDragActive && allowEdit ? 'blur(3px)' : 'none'}
              boxSize="300px"
            />
          </ExpandImage>
          {isDragActive && allowEdit && (
            <BlurTag
              validText={t('fileBrowser.uploadAndReplaceImage')}
              rejectText={t('fileBrowser.notValid')}
              isValid={!isDragReject}
            />
          )}
          {imageArchived && (
            <Tag
              position="absolute"
              left={1}
              top={1}
              colorScheme="red"
              w="fit-content"
              mb={2}
            >
              {t('general.archived')}
            </Tag>
          )}
        </Flex>
        <Flex
          animation={fadeInRightAnimation()}
          pos="absolute"
          right={2}
          direction="column"
          gap={1}
          display="none"
          top={2}
          bg="blackAlpha.800"
          padding={2}
          _light={{ bg: 'whiteAlpha.800' }}
          _groupHover={{ display: isDragActive ? 'none' : 'flex' }}
        >
          {allowEdit && imageIdentifier && (
            <Tooltip label={t('general.remove')}>
              <IconButton
                icon={<Icons.Close boxSize={6} />}
                aria-label={t('fileBrowser.removeFile')}
                size="sm"
                variant="texas-solid"
                onClick={() => {
                  onRemovefileOpen();
                }}
              />
            </Tooltip>
          )}
          {imageIdentifier && (
            <Tooltip label={t('fileBrowser.downloadFile')}>
              <IconButton
                icon={<Icons.Download boxSize={6} />}
                aria-label={t('fileBrowser.downloadFile')}
                size="sm"
                variant="no-bg"
                as="a"
                href={clientEndpoints.downloadFile(imageIdentifier)}
                target="_blank"
                download={true}
              />
            </Tooltip>
          )}
          {allowEdit && (
            <>
              <Tooltip label={t('fileBrowser.chooseFromLibrary')}>
                <IconButton
                  icon={<Icons.OpenInNew boxSize={6} />}
                  aria-label={t('fileBrowser.chooseFromLibrary')}
                  size="sm"
                  variant="no-bg"
                  onClick={() => {
                    onFileBrowserOpen();
                    setFileRejections([]);
                  }}
                />
              </Tooltip>
              <Tooltip label={t('fileBrowser.uploadFile')}>
                <IconButton
                  icon={<Icons.Upload boxSize={6} />}
                  aria-label={t('fileBrowser.uploadFile')}
                  size="sm"
                  variant="no-bg"
                  onClick={open}
                />
              </Tooltip>
            </>
          )}
        </Flex>
        {children}
      </Box>
      <FileRejections
        isOpen={isOpen}
        onClose={onClose}
        fileRejections={fileRejections}
      />
      {allowEdit && (
        <PasteFileInput
          ref={inputRef}
          onUpload={onUpload}
          multiple={false}
          accept={acceptedImageFormats}
        />
      )}
      <input id="dropzone" multiple={false} {...getInputProps()} />
    </Box>
  );
}
