import { useTranslation } from 'react-i18next';
import { ServerError } from '@texas/types';
import {
  ArticleProcess,
  ArticleState,
  SearchVariantsResponse,
  VariantsSearchQuery,
} from '@texas/api/endpoints/searchApi';
import {
  Card,
  CardBody,
  Image,
  Flex,
  GridItem,
  Text,
  Spacer,
  Tag,
  Spinner,
  Box,
} from '@chakra-ui/react';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';
import placeholderImg from '@texas/assets/placeholder-50.png';
import { StateIndicator } from '../indicators/StateIndicator';
import { StepsIndicator } from '../indicators/StepsIndicator';
import { ErrorDetails } from '../alert/ErrorDetails';
import { clientEndpoints } from '@texas/clientEndpoints';
import { ArticleSearchProps } from '../../searchArticles/types';
import { defaultPageSize } from '../dataTable/defaultTableOptions';
import { MutableRefObject } from 'react';
import { Pagination } from '../dataTable/pagination/Pagination';
import { SelectSortOption } from './SelectSortOption';
import { ExpandImage } from '../ExpandImage';
import { AngularNavLink } from '../AngularNavLink';

export interface SearchArticlesCardProps {
  articles: SearchVariantsResponse | null;
  searchPage: ArticleSearchProps;
  searchParamsRef: MutableRefObject<VariantsSearchQuery>;
  setSearchParamsWithRef: (params: VariantsSearchQuery) => void;
  error: ServerError | null;
  loading: boolean;
}

export function ArticlesCardView({
  articles,
  searchPage,
  searchParamsRef,
  setSearchParamsWithRef,
  error,
  loading,
}: SearchArticlesCardProps) {
  const { t } = useTranslation();

  return (
    <>
      {loading && (
        <Flex alignItems="center" justifyContent="center" pt={4}>
          <Spinner />
        </Flex>
      )}
      <ErrorDetails error={error} />
      <Flex justifyContent="flex-end">
        <SelectSortOption
          searchParamsRef={searchParamsRef}
          setSearchParamsWithRef={setSearchParamsWithRef}
        />
      </Flex>
      <Flex
        maxW="100%"
        overflowX="auto"
        overflowY="auto"
        m={4}
        __css={{ container: 'cardBox / inline-size' }}
      >
        <Box
          gap={6}
          __css={{
            display: 'grid',
            '@container cardBox (min-width: 0px)': {
              gridTemplateColumns: 'repeat(1, 1fr)',
            },
            '@container cardBox (min-width: 480px)': {
              gridTemplateColumns: 'repeat(2, 1fr)',
            },
            '@container cardBox (min-width: 800px)': {
              gridTemplateColumns: 'repeat(3, 1fr)',
            },
            '@container cardBox (min-width: 1200px)': {
              gridTemplateColumns: 'repeat(4, 1fr)',
            },
            '@container cardBox (min-width: 1600px)': {
              gridTemplateColumns: 'repeat(6, minmax(200px, auto))',
            },
          }}
        >
          {articles?.pagedItems.items.map((x, i) => (
            <GridItem key={i}>
              <Card
                variant="hover-shadow"
                animation={fadeInScaleAnimation()}
                height="100%"
              >
                <CardBody>
                  <Flex direction="column" gap={1} height="100%">
                    <ExpandImage identifier={x.imageIdentifier}>
                      <Flex
                        flexGrow={1}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Image
                          maxH="150px"
                          src={
                            x.imageIdentifier
                              ? clientEndpoints.previewImage(
                                  x.imageIdentifier,
                                  256,
                                )
                              : undefined
                          }
                          fallbackSrc={placeholderImg}
                          objectFit="contain"
                        />
                      </Flex>
                    </ExpandImage>
                    <Flex alignContent="baseline" flexWrap="wrap" gap={1}>
                      <AngularNavLink
                        to="variant"
                        navobj={{
                          articleId: x.articleId,
                          variantId: x.variantId,
                        }}
                      >
                        {x.itemCode}
                      </AngularNavLink>
                      <Spacer />
                      <Flex opacity="0.9" alignItems="center">
                        <StateIndicator
                          boxSize={5}
                          state={
                            ArticleState[
                              x.state as unknown as keyof typeof ArticleState
                            ]
                          }
                        />
                      </Flex>
                      {x.mainVariant && (
                        <Tag
                          variant="colored"
                          size="sm"
                          textTransform="uppercase"
                          fontWeight="bold"
                          height="fit-content"
                          alignSelf="center"
                        >
                          {t('general.main')}
                        </Tag>
                      )}
                      <Tag
                        variant="lighter"
                        size="sm"
                        fontWeight="bold"
                        height="fit-content"
                        alignSelf="center"
                      >
                        {x.branch}
                      </Tag>
                    </Flex>
                    <Text variant="small">{x.name}</Text>
                    {ArticleState[
                      x.state as unknown as keyof typeof ArticleState
                    ] == ArticleState.Cancelled ? null : (
                      <StepsIndicator
                        height={3}
                        currentStep={
                          ArticleProcess[
                            x.articleProcess as unknown as keyof typeof ArticleProcess
                          ]
                        }
                      />
                    )}
                    <Flex gap={2} flexWrap="wrap" pt={2}>
                      <Tag whiteSpace="nowrap">{x.customerName}</Tag>
                      {x.concept && <Tag whiteSpace="nowrap">{x.concept}</Tag>}
                      {x.productGroup && (
                        <Tag whiteSpace="nowrap">{x.productGroup}</Tag>
                      )}
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </GridItem>
          ))}
        </Box>
      </Flex>
      <Flex justifyContent="flex-end">
        <Pagination
          totalItems={articles?.pagedItems.totalItems ?? 0}
          pageSize={defaultPageSize}
          currentPage={searchPage.searchParams.page}
          onPageChange={(page) =>
            setSearchParamsWithRef({
              ...searchParamsRef.current,
              page,
            })
          }
        />
      </Flex>
    </>
  );
}
