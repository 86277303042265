import { UseRadioProps, useRadio, Box, Flex } from '@chakra-ui/react';

export function RadioCard({
  props,
  children,
}: {
  props: UseRadioProps;
  children: React.ReactNode;
}) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Flex h="full" {...checkbox} cursor="pointer" role="group">
        {children}
      </Flex>
    </Box>
  );
}
