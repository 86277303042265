import { Flex } from '@chakra-ui/react';
import { RejectModal } from './RejectModal';
import { AcceptModal } from './AcceptModal';
import { ProductApprovalState } from '@texas/api/endpoints/productApprovalsApi';
import { RevertToPendingModal } from './RevertToPendingModal';

export function SelectState({
  id,
  state,
  branchId,
  variantId,
  onChanged,
}: {
  id: number;
  state: ProductApprovalState;
  branchId: number;
  variantId: number;
  onChanged: (id?: number) => void;
}) {
  return (
    <Flex gap={1}>
      {state !== ProductApprovalState.Rejected && (
        <RejectModal productApprovalId={id} onReject={() => onChanged(id)} />
      )}
      {state !== ProductApprovalState.Accepted && (
        <AcceptModal
          productApprovalId={id}
          branchId={branchId}
          variantId={variantId}
          onAccept={() => onChanged(id)}
        />
      )}
      {state !== ProductApprovalState.Pending && (
        <RevertToPendingModal
          productApprovalId={id}
          onRevert={() => onChanged(id)}
        />
      )}
    </Flex>
  );
}
