import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  useToast,
  Text,
} from '@chakra-ui/react';
import { ErrorDetails } from '../alert/ErrorDetails';
import { SharedDisclosureProps } from '../types';
import { useRef } from 'react';
import { request } from '../../../utils/helpers/httpHelpers';
import { ServerError } from '../../../types';
import { ApiRequestType, useApiRequest } from '@texas/api/hooks/useApiRequest';
import { Icons } from '../Icons';
import { fadeInRightAnimation } from '@texas/resources/animations/animations';

interface VerifyDialogWithRequestProps<A extends any[], R>
  extends SharedDisclosureProps {
  headerTitle: string;
  children: React.ReactNode;
  secondaryButtonTitle: string;
  primaryButtonTitle: string;

  onPerformed?: (data: R) => void;
  primaryRequest: ApiRequestType<A, R>;
  args: A;

  onSuccessTitle: string;
  onFailureTitle: string;
}

export function VerifyDialogWithRequest<A extends any[], T>(
  props: VerifyDialogWithRequestProps<A, T>,
) {
  const cancelRef = useRef(null);
  const toast = useToast();

  const {
    request: remoteRequest,
    error,
    loading,
  } = useApiRequest(props.primaryRequest);

  return (
    <AlertDialog
      isOpen={props.isOpen}
      leastDestructiveRef={cancelRef}
      onClose={props.onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <ErrorDetails error={error} />

          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            <Flex gap={2} align="center">
              <Icons.AlertCircle boxSize="6" />
              <Text animation={fadeInRightAnimation()}>
                {props.headerTitle}
              </Text>
            </Flex>
          </AlertDialogHeader>

          <AlertDialogBody>{props.children}</AlertDialogBody>
          <AlertDialogFooter>
            <Button
              variant="texas-light"
              ref={cancelRef}
              onClick={props.onClose}
            >
              {props.secondaryButtonTitle}
            </Button>
            <Button
              isDisabled={loading}
              isLoading={loading}
              colorScheme="red"
              onClick={async () => {
                await performRequest();
              }}
              ml={3}
            >
              {props.primaryButtonTitle}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );

  async function performRequest() {
    await request(
      remoteRequest,
      props.args,
      (data) => {
        toast({
          title: props.onSuccessTitle,
          status: 'success',
          isClosable: true,
        });
        props.onClose();
        props.onPerformed?.(data);
      },
      (error: ServerError) => {
        toast({
          title: props.onFailureTitle,
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  }
}
