import {
  Box,
  Button,
  DarkMode,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import { BodyOverride } from '../shared/BodyOverride';
import { Slogan } from './Slogan';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { authApi } from '@texas/api/endpoints/authApi';
import { reactEvents } from '@bridge/reactEvents';
import { useTranslation } from 'react-i18next';
import { ErrorLabel } from '../shared/ErrorLabel';
import { AngularNavLink } from '../shared/AngularNavLink';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ErrorDetails } from '../shared/alert/ErrorDetails';
import { ForgotPasswordModal } from './ForgotPasswordModal';

interface ResetPasswordForm {
  password: string;
  confirmPassword: string;
}

export function ResetPassword() {
  return (
    <DarkMode>
      <BodyOverride>
        <Content />
      </BodyOverride>
    </DarkMode>
  );
}

function Content() {
  const [searchParams] = useSearchParams();
  const params = {
    userId: searchParams.get('userId'),
    token: searchParams.get('token'),
  };

  if (!params.token) {
    // eslint-disable-next-line react/jsx-no-literals
    return <Text>Error: Missing token</Text>;
  }

  if (!params.userId) {
    // eslint-disable-next-line react/jsx-no-literals
    return <Text>Error: Missing userId</Text>;
  }

  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      h="100dvh"
    >
      <Flex gap={12} flexDir="column" w="fit-content">
        <Slogan />
        <Form userId={params.userId} token={params.token} />
      </Flex>
    </Flex>
  );
}

function Form({ userId, token }: { userId: string; token: string }) {
  const { t } = useTranslation();
  const toast = useToast();
  const { request, error } = useApiRequest(authApi.resetPassword);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isValid },
    getValues,
  } = useForm<ResetPasswordForm>({ mode: 'onChange' });

  async function submit(data: ResetPasswordForm) {
    const response = await request(userId, token, data.password);

    if (!response.hasError) {
      toast({ title: 'Password updated', status: 'success' });
      reactEvents.routingChanged.dispatch({ to: 'login' });
    }
  }

  return (
    <>
      {error && (
        <Box>
          <ErrorDetails error={error} />
          <ForgotPasswordModal label={t('auth.requestNewLink')} />
        </Box>
      )}
      <form onSubmit={handleSubmit(submit)}>
        <Flex flexDir="column" gap={4}>
          <FormControl isInvalid={!!errors.password?.message}>
            <FormLabel>{t('auth.newPassword')}</FormLabel>
            <Input
              {...register('password', {
                required: true,
                minLength: {
                  value: 8,
                  message: t('errors.minLength', { count: 8 }),
                },
              })}
              type="password"
              variant="texas"
            />
            <ErrorLabel text={errors.password?.message} />
          </FormControl>
          <FormControl isInvalid={!!errors.confirmPassword?.message}>
            <FormLabel>{t('auth.confirmPassword')}</FormLabel>
            <Input
              {...register('confirmPassword', {
                required: true,
                validate: (value) =>
                  value === getValues('password') || t('errors.matching'),
              })}
              type="password"
              variant="texas"
            />
            <ErrorLabel text={errors.confirmPassword?.message} />
          </FormControl>

          <Button
            isDisabled={!isValid}
            isLoading={isSubmitting}
            variant="texas-login"
            type="submit"
            mt={4}
          >
            {t('auth.resetPassword')}
          </Button>
          <AngularNavLink
            to="login"
            color="white"
            textAlign="center"
            fontSize="sm"
          >
            {t('auth.backToLogin')}
          </AngularNavLink>
        </Flex>
      </form>
    </>
  );
}
