import { Branch } from '@texas/api/endpoints/branchesApi';
import { PurchaseInformation } from '@texas/api/endpoints/purchaseInformationApi';
import { ExportArticleItem } from '@texas/api/endpoints/searchApi';
import { GarpData } from '@texas/api/endpoints/variantsApi';
import { SignalDispatcher } from 'ste-signals';
import { SimpleEventDispatcher } from 'ste-simple-events';

export interface ExportArticleEvent {
  articles: ExportArticleItem[];
  branchIds: number[];
  hideBranchIds: boolean;
  template?: number;
}

export interface NavigationPath {
  to: string;
  navObj?: any;
}

export interface UpdateValue {
  id: number;
  value: string;
}

export interface LockedState {
  locked: boolean;
}

export interface Id {
  id: number;
}

export const reactEvents = {
  excelExportClicked: new SimpleEventDispatcher<ExportArticleEvent>(),
  routingChanged: new SimpleEventDispatcher<NavigationPath>(),
  variantFinalized: new SimpleEventDispatcher<GarpData>(),
  branchCreated: new SimpleEventDispatcher<Branch>(),
  supplierAdded: new SimpleEventDispatcher<PurchaseInformation>(),
  variantNameChanged: new SimpleEventDispatcher<UpdateValue>(),
  lockedStateChanged: new SimpleEventDispatcher<LockedState>(),
  supplierCreated: new SignalDispatcher(),
  productApprovalsCreated: new SignalDispatcher(),
  productApprovalChanged: new SimpleEventDispatcher<Id>(),
};
