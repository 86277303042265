// @ngInject
const currentUserResolve = (AuthService) => AuthService.getUser();

// @ngInject
const currentOrganizationResolve = (OrganizationsApiService, currentUser) =>
  OrganizationsApiService.getById(currentUser.organization.id);

// @ngInject
const branchesResolve = (BranchesApiService, currentUser) =>
  BranchesApiService.getAllByOrganizationId(currentUser.organization.id);

// @ngInject
const config = ($stateProvider, $urlRouterProvider) => {
  $stateProvider.state('app', {
    url: '/app',
    templateUrl: 'app.tpl.html',
    resolve: {
      currentUser: currentUserResolve,
      currentOrganization: currentOrganizationResolve,
      branches: branchesResolve,
    },
    redirectTo: () => 'customers',
    data: { pageTitle: 'Dashboard' },
  });

  $stateProvider.state('login', {
    url: '',
    templateUrl: 'main/login.tpl.html',
    data: { pageTitle: 'Login' },
  });

  $stateProvider.state('reset-password', {
    url: '/reset-password',
    templateUrl: 'main/resetPassword.tpl.html',
    data: { pageTitle: 'Reset password' },
  });

  $urlRouterProvider.otherwise('/');
};

angular
  .module('app', [
    'app.vendor',
    'app.core',
    'app.main',
    'app.bridgeEvents',
    'app.bridgeComponents',
  ])
  .config(config);
