import {
  Flex,
  Box,
  Input,
  Button,
  FormControl,
  FormLabel,
  DarkMode,
  Spinner,
  Heading,
} from '@chakra-ui/react';
import bgVideo from '@texas/assets/login_bg_video.mp4';
import {
  blinkAnimation,
  fadeInAnimation,
  fadeInScaleAnimation,
} from '@texas/resources/animations/animations';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorDetails } from '../shared/alert/ErrorDetails';
import { useSession } from '@texas/hooks/useSession';
import { ForgotPasswordModal } from './ForgotPasswordModal';
import { Slogan } from './Slogan';
import { SessionStatus } from '@texas/api/endpoints/sessionApi';
import { reactEvents } from '@bridge/reactEvents';

interface LoginForm {
  email: string;
  password: string;
}

export function Login() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<LoginForm>();

  const { login, loginError, sessionState } = useSession();

  const onSubmit = async (data: LoginForm) => {
    await login(data.email, data.password);
  };

  if (sessionState.status === SessionStatus.Active) {
    reactEvents.routingChanged.dispatch({ to: 'customers' });

    return (
      <DarkMode>
        <Flex
          flexDir="column"
          minH="100dvh"
          position="relative"
          bg="black"
          overflow="hidden"
        >
          <Flex
            flexGrow={1}
            justifyContent="center"
            h="100%"
            zIndex={1}
            pos="relative"
            w="fit-content"
            align="center"
            pl="24"
            color="white"
            gap={4}
            animation={fadeInScaleAnimation()}
          >
            <Spinner />
            <Heading fontSize="lg" animation={blinkAnimation()}>
              {t('auth.redirecting')}
            </Heading>
          </Flex>
        </Flex>
      </DarkMode>
    );
  }

  return (
    <DarkMode>
      <Flex
        flexDir="column"
        minH="100dvh"
        position="relative"
        bg="black"
        overflow="hidden"
      >
        <Flex
          flexGrow={1}
          flexDir="column"
          justifyContent="center"
          h="100%"
          zIndex={1}
          pos="relative"
          w="fit-content"
          pl="24"
        >
          <Slogan />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex
              animation={fadeInAnimation()}
              pt={24}
              gap={4}
              flexDir="column"
            >
              <Box color="white">
                <ErrorDetails error={loginError} />
              </Box>
              <FormControl>
                <FormLabel>{t('general.email')}</FormLabel>
                <Input
                  type="email"
                  {...register('email', { required: true })}
                  variant="texas"
                />
              </FormControl>
              <Flex flexDir="column" gap={1}>
                <FormControl>
                  <FormLabel>{t('auth.password')}</FormLabel>
                  <Input
                    {...register('password', { required: true })}
                    type="password"
                    variant="texas"
                  />
                </FormControl>
              </Flex>

              <Button
                isLoading={isSubmitting}
                variant="texas-login"
                type="submit"
                mt={4}
              >
                {t('auth.login')}
              </Button>
            </Flex>
          </form>
          <ForgotPasswordModal label={t('auth.forgotPassword')} />
        </Flex>
        <video
          playsInline={true}
          autoPlay={true}
          muted={true}
          loop={true}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0.6,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        >
          <source src={bgVideo} type="video/mp4" />
        </video>
        <Box
          position="absolute"
          inset={0}
          background="linear-gradient(235deg, transparent, #000000);"
        />
        <Box
          position="absolute"
          inset={0}
          width="120%"
          background="radial-gradient(transparent, black);"
        />
      </Flex>
    </DarkMode>
  );
}
