import { createColumnHelper } from '@tanstack/react-table';
import { Locale } from '@texas/i18n/types';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import { TFunction } from 'i18next';
import { Image } from '@chakra-ui/react';
import placeholderImg from '@texas/assets/placeholder-50.png';
import {
  SearchVariantItem,
  ArticleState,
  ArticleProcess,
} from '@texas/api/endpoints/searchApi';
import { Icons } from '../shared/Icons';
import { StateIndicator } from '../shared/indicators/StateIndicator';
import { StepsIndicator } from '../shared/indicators/StepsIndicator';
import { clientEndpoints } from '@texas/clientEndpoints';
import { AngularNavLink } from '../shared/AngularNavLink';
import { ExpandImage } from '../shared/ExpandImage';

export const TableColumns = (t: TFunction) => {
  const columnHelper = createColumnHelper<SearchVariantItem>();
  const columns = [
    columnHelper.accessor('imageIdentifier', {
      cell: (info) => (
        <ExpandImage identifier={info.getValue()} variant="scale">
          <Image
            boxSize="12"
            src={
              info.getValue()
                ? clientEndpoints.previewImage(info.getValue(), 256)
                : undefined
            }
            fallbackSrc={placeholderImg}
            objectFit="contain"
          />
        </ExpandImage>
      ),
      header: t('general.photo'),
      enableSorting: false,
    }),
    columnHelper.accessor('mainVariant', {
      cell: (info) => (info.getValue() ? <Icons.Checkmark /> : ''),
      header: t('general.main'),
      enableSorting: false,
    }),
    columnHelper.accessor('customerName', {
      cell: (info) => (
        <AngularNavLink
          to="customer"
          navobj={{ customerId: info.row.original.customerId }}
        >
          {info.getValue()} - {info.row.original.customerIdentifier}
        </AngularNavLink>
      ),
      header: t('general.brand'),
      enableSorting: false,
    }),
    columnHelper.accessor('concept', {
      cell: (info) => (
        <AngularNavLink
          to="concept"
          navobj={{ conceptId: info.row.original.conceptId }}
        >
          {info.getValue()}
        </AngularNavLink>
      ),
      header: t('general.concept'),
      enableSorting: false,
    }),
    columnHelper.accessor('itemCode', {
      cell: (info) => (
        <AngularNavLink
          to="article"
          navobj={{ articleId: info.row.original.articleId }}
        >
          {info.getValue()}
        </AngularNavLink>
      ),
      header: t('general.itemCode'),
    }),
    columnHelper.accessor('variantName', {
      cell: (info) => (
        <AngularNavLink
          to="variant"
          navobj={{
            variantId: info.row.original.variantId,
            articleId: info.row.original.articleId,
          }}
        >
          {info.getValue()}
        </AngularNavLink>
      ),
      header: t('variant.variant'),
      enableSorting: true,
    }),
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: t('general.description'),
      enableSorting: false,
    }),
    columnHelper.accessor('color', {
      cell: (info) => info.getValue(),
      header: t('general.color'),
      enableSorting: false,
    }),
    columnHelper.accessor('size', {
      cell: (info) => info.getValue(),
      header: t('general.size'),
      enableSorting: false,
    }),
    columnHelper.accessor('productGroup', {
      cell: (info) => info.getValue(),
      header: t('general.productGroup'),
      enableSorting: true,
    }),
    columnHelper.accessor('material', {
      cell: (info) => info.getValue(),
      header: t('general.material'),
      enableSorting: false,
    }),
    columnHelper.accessor('created', {
      cell: (info) => formatDate(Locale.En, info.getValue()),
      header: t('article.created'),
      enableSorting: true,
    }),
    columnHelper.accessor('sampleReadyDate', {
      cell: (info) => formatDate(Locale.En, info.getValue()),
      header: t('article.sampleReadyDate'),
      enableSorting: true,
    }),
    columnHelper.accessor('branch', {
      cell: (info) => info.getValue(),
      header: t('general.branch'),
      enableSorting: false,
    }),
    columnHelper.accessor('state', {
      cell: (info) => (
        <StateIndicator
          state={
            ArticleState[
              info.getValue() as unknown as keyof typeof ArticleState
            ]
          }
        />
      ),
      header: t('general.state'),
      enableSorting: false,
    }),
    columnHelper.accessor('articleProcess', {
      cell: (info) =>
        ArticleState[
          info.row.original.state as unknown as keyof typeof ArticleState
        ] == ArticleState.Cancelled ? null : (
          <StepsIndicator
            currentStep={
              ArticleProcess[
                info.getValue() as unknown as keyof typeof ArticleProcess
              ]
            }
          />
        ),
      enableSorting: false,
      header: t('general.articleProcess'),
    }),
  ];

  return columns;
};
