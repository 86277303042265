import { PagedItems } from '@texas/types';
import { httpClient } from '../httpClient/httpClient';

export interface SlimUser {
  name: string;
  id: number;
}

export interface User {
  id: number;
  name: string;
  email: string;
  username: string;
  branchId?: number;
  countryId?: number;
  role: Role;
  archived: string | null;
  emailNotifications: boolean;
}

export interface UserListItem {
  id: number;
  name: string;
  email: string;
  archived: string | null;
  branch: string | null;
  role: Role | null;
}

export interface UserSearchQuery {
  searchTerm: string;
  includeArchived: boolean;
  sortBy: string;
  sortDesc: boolean;
  role: Role | null;
  page: number;
  pageSize: number;
}

export enum Role {
  SystemAdministrator = 1,
  InternalAdministrator = 2,
  InternalAgent = 3,
  Brand = 4,
  Supplier = 5,
}

export interface UpdateUserRequest {
  name: string;
  email: string;
  branchId?: number;
  countryId?: number;
  providedPassword?: string;
  emailNotifications: boolean;
}

export interface UpdateUserRoleRequest {
  role: Role;
  providedPassword: string;
}

export interface CreateUserRequest {
  name: string;
  email: string;
  role: Role;
  providedPassword: string | null;
  branchId?: number;
  countryId?: number;
  emailNotifications: boolean;
  sendWelcomeMail: boolean;
}

export const userApi = {
  getMe: () => httpClient.get<User>('users/me'),
  getUsers: () => httpClient.get<User[]>('users'),
  archive: (id: number) => httpClient.put<User>(`users/${id}/archive`),
  restore: (id: number) => httpClient.put<User>(`users/${id}/restore`),
  signOutDevices: (id: number) =>
    httpClient.put<User>(`users/${id}/sign-out-devices`),
  sendWelcomeMail: (id: number) => httpClient.put<User>(`users/${id}/welcome`),
  searchUsers: (params: UserSearchQuery) =>
    httpClient.get<PagedItems<UserListItem>>('users/search', { params }),
  getUser: (id: number) => httpClient.get<User>(`users/${id}`),
  updateUser: (id: number, request: UpdateUserRequest) =>
    httpClient.put<User>(`users/${id}`, { data: request }),
  updateMe: (request: UpdateUserRequest) =>
    httpClient.put<User>(`users/update-me`, { data: request }),
  createUser: (request: CreateUserRequest) =>
    httpClient.post('users', { data: request }),
  updateUserRole: (id: number, request: UpdateUserRoleRequest) =>
    httpClient.put<User>(`users/${id}/update-role`, { data: request }),
};
