// @ngInject
const articleTemplatesResolve = (currentUser, OrganizationsApiService) =>
  OrganizationsApiService.loadArticleTemplates(currentUser.organization.id);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.articleTemplates', {
    url: '/articletemplates',
    controller: 'ArticleTemplatesConfigurationController as vm',
    templateUrl:
      'main/configuration/articleTemplates/articleTemplates.tpl.html',
    data: { pageTitle: 'Article templates' },
    resolve: {
      articleTemplates: articleTemplatesResolve,
    },
  });
};

class ArticleTemplatesConfigurationController {
  constructor(
    $scope,
    DS,
    currentUser,
    ArticleTemplateDialogService,
    articleTemplates,
    AuthService,
  ) {
    'ngInject';

    this._ArticleTemplateDialogService = ArticleTemplateDialogService;
    this.articleTemplates = articleTemplates;
    this.noop = _.noop;
    this.allowedToCreateNew = AuthService.hasAnyClaim(['SystemAdministrator']);

    this.query = {
      filter: '',
      order: 'displayOrder',
      limit: 50,
      page: 1,
    };

    $scope.$watch('vm.query.filter', () => {
      this.query.page = 1;
    });

    $scope.$watch(
      () => {
        return DS.lastModified('articleTemplate');
      },
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.articleTemplates = currentUser.organization.articleTemplates;
        }
      },
    );
  }

  openArticleTemplate(articleTemplate) {
    this._ArticleTemplateDialogService.showDialog({
      organization: this.organization,
      articleTemplate,
      allowedToEdit: this.allowedToCreateNew,
    });
  }

  newArticleTemplate() {
    this._ArticleTemplateDialogService.showDialog({
      organization: this.organization,
      allowedToEdit: this.allowedToCreateNew,
    });
  }
}

angular
  .module('configuration.articleTemplates', [])
  .config(config)
  .controller(
    'ArticleTemplatesConfigurationController',
    ArticleTemplatesConfigurationController,
  );
