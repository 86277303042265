import { ApiRequestType } from '@texas/api/hooks/useApiRequest';
import { ServerError } from '../../types';

export async function request<TData, A extends any[]>(
  request: ApiRequestType<A, TData>,
  data: A,
  onSuccess: (data: TData) => void,
  onFailure?: (error: ServerError) => void,
) {
  const response = await request(...data);

  if (!response.hasError) {
    onSuccess(response.data);
  } else {
    onFailure?.(response.error);
  }
}
