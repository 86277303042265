// @ngInject
const usersResolve = (customer, UsersApiService) =>
  UsersApiService.getAllByOrganizationId(customer.organizationId);

// @ngInject
const relationsResolve = (customer, users, ResourcesService) =>
  ResourcesService.loadRelations('customer', customer, ['_users']);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('customer.users', {
    url: '/users',
    templateUrl: 'main/customer/users/users.tpl.html',
    controller: 'CustomerUsersController as vm',
    resolve: {
      users: usersResolve,
      relationsResolve,
    },
  });
};

class CustomerUsersController {
  constructor(
    $filter,
    $scope,
    customer,
    users,
    loadSpinnerService,
    CustomersApiService,
    HelperService,
    toasterService,
    SavingService,
    AuthService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$filter = $filter;
    this.SavingService = SavingService;
    this.HelperService = HelperService;
    this.loadSpinnerService = loadSpinnerService;
    this.CustomersApiService = CustomersApiService;
    this.toasterService = toasterService;

    this.users = users;
    this.originalCustomer = customer;
    this.validationErrors = [];
    this.saveObj = SavingService.registerSave({
      onSave: () => this.save(this.getChangedData()),
      onDiscard: () => this.discard(),
    });
    this.currentUserCanEdit = AuthService.hasAnyClaim([
      'SystemAdministrator',
      'InternalAdministrator',
    ]);
    this.setCustomerClone();
    this.setupWatchers();
  }

  setCustomerClone() {
    this.customer = _.extend({}, this.originalCustomer);
    this.customer.users = _.clone(this.originalCustomer._users);
  }

  getChangedData() {
    const changedInfo = {};

    const changedUsers = this.HelperService.isArrayModified(
      _.map(this.originalCustomer.users, 'id'),
      _.map(this.customer.users, 'id'),
    );
    if (changedUsers) {
      changedInfo.users = this.customer.users;
    }

    return changedInfo;
  }

  save(data) {
    if (this.currentUserCanEdit) {
      this.validationErrors = [];
      this.loadSpinnerService.start('mainSpinner');
      this.CustomersApiService.updateCustomerInfo(data, this.customer.id)
        .then(
          () => {
            this.toasterService.success();
          },
          (error) => {
            this.validationErrors = error.errors;
          },
        )
        .finally(() => {
          this.loadSpinnerService.stop('mainSpinner');
        });
    }
  }

  discard() {
    this.validationErrors = [];
    this.setCustomerClone();
  }

  getUsers(query) {
    return _.filter(
      this.$filter('filter')(this.users, { uniqueName: query }),
      (user) => {
        return !_.find(this.customer.users, { id: user.id });
      },
    );
  }

  setupWatchers() {
    this.$scope.$watch(
      () => {
        return !_.isEmpty(this.getChangedData());
      },
      (hasChanges) => {
        this.saveObj.hasChanges = hasChanges;
      },
    );

    this.$scope.$on('$destroy', () => {
      this.SavingService.deregisterSave(this.saveObj);
    });
  }
}

angular
  .module('main.customer.users', [])
  .config(config)
  .controller('CustomerUsersController', CustomerUsersController);
