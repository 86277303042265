angular.module('services.dialogs', [
  'services.dialogs.customerDialog',
  'services.dialogs.articleDialog',
  'services.dialogs.fileReplaceDialog',
  'services.dialogs.folderDialog',
  'services.dialogs.variantDialog',
  'services.dialogs.loginDialog',
  'services.dialogs.branchDialog',
  'services.dialogs.articleTemplateDialog',
  'services.dialogs.matrixCellDialog',
  'services.dialogs.matrixNodeDialog',
  'services.dialogs.currencyDialog',
  'services.dialogs.matrixGroupDialog',
  'services.dialogs.countryDialog',
  'services.dialogs.configItemDialog',
  'services.dialogs.declineConfirmationDialog',
  'services.dialogs.copyArticleDialog',
  'services.dialogs.exportDialog',
  'services.dialogs.noteDialog',
  'services.dialogs.exportArticleExcelDialog',
  'services.dialogs.purchaseInfoDialog',
  'services.dialogs.priceHistoryDialog',
  'services.dialogs.informationHistoryDialog',
  'services.dialogs.materialDialog',
  'services.dialogs.productGroupDialog',
  'services.dialogs.techniqueDialog',
]);
