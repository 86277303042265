export type BetaFeature = 'composition' | 'productApproval' | 'articleFlowV1';

// TODO: Move this into the server with proper validation
const betaUsers: Record<number, { features: BetaFeature[] } | undefined> = {
  202: {
    features: ['composition', 'productApproval'],
  },
  24: {
    features: ['composition', 'productApproval'],
  },
  117: {
    features: ['composition', 'productApproval'],
  },
  85: {
    features: ['composition', 'productApproval'],
  },
  51: {
    features: ['composition', 'productApproval'],
  },
  156: {
    features: ['composition', 'productApproval'],
  },
  52: {
    features: ['composition', 'productApproval'],
  },
  183: {
    features: ['composition', 'productApproval'],
  },
  46: {
    features: ['composition', 'productApproval'],
  },
  98: {
    features: ['composition', 'productApproval'],
  },
  245: {
    features: ['composition', 'productApproval'],
  },
  162: {
    features: ['composition', 'productApproval'],
  },
  118: {
    features: ['composition', 'productApproval'],
  },
  215: {
    features: ['composition', 'productApproval'],
  },
};

export function hasAccessToFeature(userId: number, feature: BetaFeature) {
  const env = process.env.APP_ENVIRONMENT;

  if (env !== 'production') return true;

  return betaUsers[userId]?.features.some((f) => f === feature) ?? false;
}
