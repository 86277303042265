import { angularEvents } from '@bridge/angularEvents';
import { reactEvents } from '@bridge/reactEvents';

// @ngInject
const purchaseInfosResolve = ($stateParams, ArticlesApiService) =>
  ArticlesApiService.getVariantPurchaseInfos($stateParams.variantId);

// @ngInject
const tierPricesResolve = ($stateParams, ArticlesApiService) =>
  ArticlesApiService.getTierPrices($stateParams.variantId);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('variant.purchase', {
    url: '/purchase',
    templateUrl: 'main/article/variant/purchase/purchase.tpl.html',
    controller: 'VariantPurchaseController as vm',
    resolve: {
      purchaseInfos: purchaseInfosResolve,
      tierPrices: tierPricesResolve,
    },
  });
};

class VariantPurchaseController {
  constructor(
    $q,
    $scope,
    $filter,
    ArticlesApiService,
    SuppliersApiService,
    ResourcesService,
    AuthService,
    HelperService,
    toasterService,
    loadSpinnerService,
    CurrenciesApiService,
    SavingService,
    variantOptions,
    article,
    variant,
    purchaseInfos,
    currentOrganization,
    PurchaseInfoDialogService,
    GarpApiService,
    ExportArticleExcelDialogService,
    InformationHistoryDialogService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$scope = $scope;
    this.$filter = $filter;
    this.ArticlesApiService = ArticlesApiService;
    this.SuppliersApiService = SuppliersApiService;
    this.ResourcesService = ResourcesService;
    this.AuthService = AuthService;
    this.HelperService = HelperService;
    this.toasterService = toasterService;
    this.loadSpinnerService = loadSpinnerService;
    this.CurrenciesApiService = CurrenciesApiService;
    this.SavingService = SavingService;
    this.variantOptions = variantOptions;
    this.article = article;
    this.variant = variant;
    this.PurchaseInfoDialogService = PurchaseInfoDialogService;
    this.GarpApiService = GarpApiService;
    this.ExportArticleExcelDialogService = ExportArticleExcelDialogService;
    this.InformationHistoryDialogService = InformationHistoryDialogService;
    this.currentOrganization = currentOrganization;

    this.matrixNodeX = article.matrixNodeX;
    this.matrixNodeY = article.matrixNodeY;
    this.alwaysShowBranches = currentOrganization.branches.length > 1;
    this.branches = article._branches;
    this.handlingFees = currentOrganization.handlingFees;
    this.freightCosts = currentOrganization.freightCosts;
    this.tierPrices = [];
    this.suppliers = [];
    this.currencies = [];
    this.selectedPurchaseInfo = null;
    this.selectedBranch = null;
    this.selectedSupplierIsArchived = false;
    this.originalIsReady = true;
    this.originalMainIsReady = true;
    this.isLoaded = false;
    this.isEdited = false;
    this.currentUserCanEdit = AuthService.hasAnyClaim([
      'SystemAdministrator',
      'InternalAdministrator',
      'InternalAgent',
    ]);
    this.organizationConfig = currentOrganization.config;

    this.setPurchaseInfos(purchaseInfos);
    this.selectBranch(variantOptions.selectedBranch || this.branches[0]);
    this.setupWatchers();

    this.finalizedEvent = reactEvents.variantFinalized.subscribe((data) => {
      const originalPurchaseInfo = this.getOriginalPurchaseInfo();
      if (originalPurchaseInfo && originalPurchaseInfo.isReady) {
        originalPurchaseInfo.syncedOn = data.syncedOn;
        this.setSelectedPurchaseInfoClone();
        this.setSelectedPurchaseInfoData();
      }
      this.reloadVariantData();
    });

    this.supplierAddedEvent = reactEvents.supplierAdded.subscribe(
      (purchaseInfo) => {
        this.loadSpinnerService.start('mainSpinner');
        ArticlesApiService.getVariantPurchaseInfo(purchaseInfo.id)
          .then((data) => {
            this.purchaseInfos.push(data);
            this.setSelectedPurchaseInfo(data.supplierId);
          })
          .finally(() => {
            this.loadSpinnerService.stop('mainSpinner');
          });
      },
    );
  }

  setupWatchers() {
    this.$scope.$watch(
      () => this.article.branches,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.reloadVariantData().then(() => {
            this.branches = this.article._branches;
            if (!_.some(this.branches, { id: this.selectedBranch.id })) {
              this.selectBranch(this.branches[0]);
            }
          });
        }
      },
      true,
    );

    const purchaseSaveObj = this.SavingService.registerSave({
      onSave: () => this.savePurchaseInfo(),
      onDiscard: () => this.discardPurchaseInfo(),
      onValidate: () => this.validate(),
    });
    this.$scope.$watch(
      () => !_.isEmpty(this.getChangedPurchaseInfoData()),
      (hasChanges) => {
        if (!hasChanges) {
          this.resetPurchaseInfoForm();
        }
        purchaseSaveObj.hasChanges = hasChanges;
      },
    );

    this.$scope.$on('$destroy', () => {
      this.SavingService.deregisterSave(purchaseSaveObj);
      this.finalizedEvent();
      this.supplierAddedEvent();
    });
  }

  reloadVariantData() {
    return this.$q
      .all([
        this.ArticlesApiService.getVariantPurchaseInfos(this.variant.id),
        this.ArticlesApiService.getTierPrices(this.variant.id),
      ])
      .then((data) => {
        this.setCurrentTierPrices();
        this.setPurchaseInfos(data[0]);
      });
  }

  copyPrices() {
    this.reloadVariantData().then(() => this.selectBranch(this.selectedBranch));
  }

  validate() {
    if (!this.purchaseForm.$valid) {
      if (this.purchaseForm.fixedExchangeRate.$invalid) {
        this.validationErrors = [{ message: 'Exchange rate is not valid' }];
      } else {
        this.validationErrors = [{ message: 'Form is not valid' }];
      }
      return false;
    }
  }

  setPurchaseInfos(purchaseInfos) {
    this.purchaseInfos = _.filter(purchaseInfos, (p) => !!p.supplierId);
  }

  setSelectedPurchaseInfo(supplierId) {
    this.validationErrors = [];
    this.selectedSupplierId = supplierId;
    if (this.selectedSupplierId) {
      this.setSelectedPurchaseInfoClone();
      this.loadPurchaseInfoData(this.getOriginalPurchaseInfo()).then(() => {
        this.setSelectedPurchaseInfoData();
        this.isLoaded = true;
      });
    } else {
      this.selectedSupplierId = null;
      this.selectedPurchaseInfo = null;
    }
    this.resetPurchaseInfoForm();
  }

  setSelectedPurchaseInfoClone() {
    this.selectedPurchaseInfo = _.extend({}, this.getOriginalPurchaseInfo());
  }

  setSelectedPurchaseInfoData() {
    this.setSupplier(this.selectedPurchaseInfo.supplierId);
    this.updateCurrency(this.selectedPurchaseInfo.currencyId);
    this.setHandlingFee(this.selectedPurchaseInfo.handlingFeeId);
    this.setFreightCost(this.selectedPurchaseInfo.freightCostId);
    const purchaseInfo = this.getOriginalPurchaseInfo();
    this.originalIsReady = purchaseInfo.isReady;
    this.isEdited = purchaseInfo.isEdited;

    const mainPurchaseInfo = _.find(this.purchaseInfos, {
      branchId: this.selectedBranch.id,
      isMain: true,
    });
    this.originalMainIsReady = mainPurchaseInfo
      ? mainPurchaseInfo.isReady
      : false;
  }

  setSupplier(supplierId, manual = false) {
    const supplier = supplierId
      ? _.find(this.getCurrentSuppliers(), { id: supplierId })
      : null;
    if (supplier) {
      this.selectedPurchaseInfo.supplierId = supplierId;
      this.selectedPurchaseInfo.supplier = supplier;
      this.selectedSupplierIsArchived = supplier.archived;
      const currencyId = this.getCurrentCurrencyId(supplier);
      if (
        manual &&
        currencyId &&
        currencyId !== this.selectedPurchaseInfo.currencyId &&
        !this.isDefaultCurrency(this.selectedPurchaseInfo.currencyId)
      ) {
        this.updateCurrency(currencyId);
      }
    } else {
      this.selectedPurchaseInfo.supplierId = 0;
      this.selectedPurchaseInfo.supplier = null;
    }
  }

  setCurrency(currencyId) {
    const currency = currencyId
      ? _.find(this.getCurrentCurrencies(), { id: currencyId })
      : null;
    if (currency) {
      this.selectedPurchaseInfo.currencyId = currencyId;
      this.selectedPurchaseInfo.currency = currency;
    } else {
      this.selectedPurchaseInfo.currencyId = 0;
      this.selectedPurchaseInfo.currency = null;
    }
  }

  updateCurrency(currencyId) {
    if (
      currencyId &&
      currencyId !== this.getOriginalPurchaseInfo().currencyId
    ) {
      this.resetExchangeRate(currencyId);
    }
    this.setCurrency(currencyId);
  }

  setHandlingFee(handlingFeeId) {
    const handlingFee = handlingFeeId
      ? _.find(this.getCurrentHandlingFees(), { id: handlingFeeId })
      : null;
    if (handlingFee) {
      this.selectedPurchaseInfo.handlingFeeId = handlingFeeId;
      this.selectedPurchaseInfo.handlingFee = handlingFee;
    } else {
      this.selectedPurchaseInfo.handlingFeeId = 0;
      this.selectedPurchaseInfo.handlingFee = null;
    }
  }

  setFreightCost(freightCostId) {
    const freightCost = freightCostId
      ? _.find(this.getCurrentFreightCosts(), { id: freightCostId })
      : null;
    if (freightCost) {
      this.selectedPurchaseInfo.freightCostId = freightCostId;
      this.selectedPurchaseInfo.freightCost = freightCost;
    } else {
      this.selectedPurchaseInfo.freightCostId = 0;
      this.selectedPurchaseInfo.freightCost = null;
    }
  }

  setCurrentTierPrices() {
    this.tierPrices = _.chain(this.variant.tierPrices)
      .filter({ branchId: this.selectedBranch.id })
      .sortBy('quantity')
      .value();
  }

  filterCurrencies(query) {
    const currencies = this.getCurrentCurrencies();
    return _.orderBy(
      this.$filter('filter')(currencies, { code: query }),
      'code',
    );
  }

  filterHandlingFees(query) {
    const handlingFees = this.getCurrentHandlingFees();
    return _.orderBy(
      _.filter(handlingFees, (h) =>
        this.HelperService.containsText(h, { value: query, name: query }),
      ),
      ['displayOrder', 'value'],
    );
  }

  filterFreightCosts(query) {
    const freightCosts = this.getCurrentFreightCosts();
    return _.orderBy(
      _.filter(freightCosts, (f) =>
        this.HelperService.containsText(f, { value: query, name: query }),
      ),
      ['displayOrder', 'value'],
    );
  }

  getOriginalPurchaseInfo() {
    return _.find(this.purchaseInfos, {
      branchId: this.selectedBranch.id,
      supplierId: this.selectedSupplierId,
    });
  }

  getCurrentSuppliers() {
    const suppliers = _.clone(this.suppliers);
    const purchaseInfo = this.getOriginalPurchaseInfo();
    if (
      purchaseInfo &&
      purchaseInfo.supplier &&
      !_.some(suppliers, { id: purchaseInfo.supplier.id })
    ) {
      suppliers.push(purchaseInfo.supplier);
    }
    return suppliers;
  }

  getCurrentCurrencies() {
    const currencies = _.clone(this.currencies);
    const purchaseInfo = this.getOriginalPurchaseInfo();
    if (
      purchaseInfo &&
      purchaseInfo.currency &&
      !_.some(currencies, { id: purchaseInfo.currency.id })
    ) {
      currencies.push(purchaseInfo.currency);
    }
    return this.replaceWithSupplierCurrency(currencies);
  }

  replaceWithSupplierCurrency(currencies) {
    const purchaseInfo = this.getOriginalPurchaseInfo();
    const currentCurrencyId = this.getCurrentCurrencyId(
      this.selectedPurchaseInfo.supplier,
    );

    if (this.selectedPurchaseInfo.supplier) {
      const branch = _.find(this.selectedPurchaseInfo.supplier.branchData, {
        branchId: purchaseInfo.branchId,
      });
      if (currentCurrencyId && _.some(currencies, { id: currentCurrencyId })) {
        const currency = _.find(currencies, { id: branch.currencyId });
        if (currency && branch.buyingRate && branch.useCustomBuyingRate) {
          currency.buyingRate = branch.buyingRate;
        }
      }
    }
    return currencies;
  }

  getCurrentHandlingFees() {
    const handlingFees = _.clone(this.handlingFees);
    const purchaseInfo = this.getOriginalPurchaseInfo();
    if (
      purchaseInfo &&
      purchaseInfo.handlingFee &&
      !_.some(handlingFees, { id: purchaseInfo.handlingFee.id })
    ) {
      handlingFees.push(purchaseInfo.handlingFee);
    }
    return handlingFees;
  }

  getCurrentFreightCosts() {
    const freightCosts = _.clone(this.freightCosts);
    const purchaseInfo = this.getOriginalPurchaseInfo();
    if (
      purchaseInfo &&
      purchaseInfo.freightCost &&
      !_.some(freightCosts, { id: purchaseInfo.freightCost.id })
    ) {
      freightCosts.push(purchaseInfo.freightCost);
    }
    return freightCosts;
  }

  selectBranch(branch) {
    this.variantOptions.selectedBranch = branch;
    this.selectedBranch = branch;
    this.isLoaded = false;
    const selectedSupplierId = _.chain(this.purchaseInfos)
      .filter({ branchId: branch.id })
      .sortBy('isMain')
      .reverse()
      .head()
      .get('supplierId')
      .value();

    this.setCurrentTierPrices();
    this.setSelectedPurchaseInfo(selectedSupplierId);
    this.loadBranchData(branch.id);
  }

  loadBranchData(branchId) {
    this.loadSpinnerService.start('mainSpinner');
    return this.$q
      .all([
        this.CurrenciesApiService.getAllByBranchId(branchId),
        this.SuppliersApiService.getAllByBranchId(branchId),
      ])
      .then((data) => {
        this.currencies = data[0];
        this.suppliers = data[1];
      })
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  loadPurchaseInfoData(purchaseInfo) {
    this.loadSpinnerService.start('mainSpinner');
    return this.$q
      .all([
        this.ResourcesService.loadRelations(
          'variantPurchaseInfo',
          purchaseInfo,
          ['currency', 'handlingFee', 'freightCost', 'supplier'],
        ),
      ])
      .finally(() => {
        this.loadSpinnerService.stop('mainSpinner');
      });
  }

  resetExchangeRate(currencyId) {
    const currency = _.find(this.getCurrentCurrencies(), { id: currencyId });
    if (
      currency &&
      this.selectedPurchaseInfo.fixedExchangeRate !== currency.buyingRate
    ) {
      this.selectedPurchaseInfo.fixedExchangeRate = currency.buyingRate;

      if (this.purchaseForm.fixedExchangeRate) {
        this.purchaseForm.fixedExchangeRate.$setDirty();
      }
    }
  }

  getChangedPurchaseInfoData() {
    if (!this.selectedPurchaseInfo) {
      return null;
    }

    const original = this.getOriginalPurchaseInfo();
    if (!original) {
      return null;
    }

    const fields = [
      'freightCostId',
      'handlingFeeId',
      'currencyId',
      'moq',
      'mouldCharge',
      'sampleCharge',
      'surcharge',
      'notes',
      'isReady',
    ];

    if (!this.isDefaultCurrency(this.selectedPurchaseInfo.currencyId)) {
      fields.push('fixedExchangeRate');
    }

    return this.HelperService.getChangedData(
      original,
      this.selectedPurchaseInfo,
      fields,
    );
  }

  savePurchaseInfo(cb) {
    this.validationErrors = [];
    this.loadSpinnerService.start('mainSpinner');
    this.ArticlesApiService.updatePurchaseInfo(
      this.getChangedPurchaseInfoData(),
      this.selectedPurchaseInfo.id,
    )
      .then(
        (updatedPurchaseInfo) => {
          const originalPurchaseInfo = this.getOriginalPurchaseInfo();
          angular.extend(originalPurchaseInfo, updatedPurchaseInfo);
          this.resetPurchaseInfoForm();
          this.setSelectedPurchaseInfoClone();
          this.setSelectedPurchaseInfoData();
          if (cb) {
            cb();
          } else {
            this.toasterService.success();
          }
        },
        (error) =>
          (this.validationErrors = this.validationErrors.concat(error.errors)),
      )
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  resetPurchaseInfoForm() {
    if (this.purchaseForm) {
      this.purchaseForm.$setPristine();
    }
  }

  discardPurchaseInfo() {
    this.validationErrors = [];
    this.resetPurchaseInfoForm();
    this.setSelectedPurchaseInfoClone();
    this.setSelectedPurchaseInfoData();
  }

  isDefaultCurrency(currencyId) {
    return currencyId === this.selectedBranch.defaultCurrencyId;
  }

  isBranchReady(branch) {
    if (!branch) {
      return false;
    }

    const purchaseInfo = _.find(this.purchaseInfos, {
      branchId: branch.id,
      isMain: true,
    });
    return purchaseInfo && purchaseInfo.isReady;
  }

  getCurrentCurrencyId(supplier) {
    if (!supplier) {
      return null;
    }
    const branchData = _.find(supplier.branchData, {
      branchId: this.selectedBranch.id,
    });
    return branchData ? branchData.currencyId : null;
  }

  newPurchaseInfo() {
    angularEvents.addSupplierClicked.dispatch({
      variantId: this.variant.id,
      branchId: this.selectedBranch.id,
      branchName: this.selectedBranch.name,
    });
  }

  exportToExcel(article) {
    this.ExportArticleExcelDialogService.showDialog({
      selectedArticles: [article],
      selectedTemplate: 2,
      organization: this.currentOrganization,
    });
  }

  getPurchaseInfoHistory(name) {
    this.InformationHistoryDialogService.showDialog(
      'purchase',
      name,
      this.variant.id,
      this.selectedSupplierId,
      this.selectedPurchaseInfo.currencyId,
      this.currentOrganization,
    );
  }
}

angular
  .module('main.article.variant.purchase', [])
  .config(config)
  .controller('VariantPurchaseController', VariantPurchaseController);
