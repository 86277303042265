// @ngInject
const matrixNodesResolve = (currentUser, OrganizationsApiService) =>
  OrganizationsApiService.loadMatrixNodes(currentUser.organization.id);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.matrixNodes', {
    url: '/matrixnodes',
    controller: 'MatrixNodesConfigurationController as vm',
    templateUrl: 'main/configuration/matrixNodes/matrixNodes.tpl.html',
    data: { pageTitle: 'Matrix nodes' },
    resolve: {
      matrixNodes: matrixNodesResolve,
    },
  });
};

class MatrixNodesConfigurationController {
  constructor(
    $scope,
    DS,
    currentOrganization,
    MatrixNodeDialogService,
    matrixNodes,
    AuthService,
  ) {
    'ngInject';

    this.currentOrganization = currentOrganization;
    this.MatrixNodeDialogService = MatrixNodeDialogService;

    this.allowedToCreateNew = AuthService.hasAnyClaim(['SystemAdministrator']);
    this.matrixNodes = matrixNodes;
    this.noop = _.noop;
    this.query = {
      filter: '',
      order: 'displayOrder',
      limit: 50,
      page: 1,
    };

    $scope.$watch('vm.query.filter', () => {
      this.query.page = 1;
    });

    $scope.$watch(
      () => DS.lastModified('matrixNode'),
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.matrixNodes = currentOrganization.matrixNodes;
        }
      },
    );
  }

  openMatrixNode(matrixNode) {
    this.MatrixNodeDialogService.showDialog({
      organization: this.currentOrganization,
      matrixNode,
      allowedToEdit: this.allowedToCreateNew,
    });
  }

  newMatrixNode() {
    this.MatrixNodeDialogService.showDialog({
      organization: this.currentOrganization,
      allowedToEdit: this.allowedToCreateNew,
    });
  }
}

angular
  .module('configuration.matrixNodes', [])
  .config(config)
  .controller(
    'MatrixNodesConfigurationController',
    MatrixNodesConfigurationController,
  );
