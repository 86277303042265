import { TFunction } from 'i18next';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Badge,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { Icons } from '@texas/components/shared/Icons';
import { ArchivedTooltip } from '@texas/components/shared/tooltip/ArchivedTooltip';
import { Material } from '@texas/api/endpoints/metadata/materialsApi';

export const materialTableColumns = (
  t: TFunction,
  isMixed: boolean,
  onArchiveOpen: (material: Material) => void,
  onRestore: (id: number) => Promise<void>,
  onEditOpen: (id: number) => void,
) => {
  const columnHelper = createColumnHelper<Material>();
  const columns = [
    columnHelper.accessor('code', {
      cell: (info) => info.getValue(),
      header: t('general.code'),
      maxSize: 100,
    }),
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: t('general.name'),
      maxSize: 200,
    }),
    columnHelper.accessor('description', {
      cell: (info) => info.getValue(),
      header: t('general.description'),
      maxSize: 200,
    }),
    columnHelper.accessor('articleTextField', {
      cell: (info) => info.getValue(),
      header: t('configuration.articleTextField'),
      enableSorting: false,
    }),
    columnHelper.accessor('displayOrder', {
      cell: (info) => info.getValue(),
      header: t('branch.displayOrder'),
      enableSorting: false,
    }),
    ...(isMixed
      ? [
          columnHelper.accessor('combinationOf', {
            cell: (info) => (
              <Flex gap={2} flexWrap="wrap">
                {info.getValue().map((v) => (
                  <Badge
                    key={v.id}
                    color="texas.sand.50"
                    _light={{
                      backgroundColor: 'blackAlpha.100',
                      color: 'texas.burntSienna.500',
                    }}
                  >
                    {v.name}
                  </Badge>
                ))}
              </Flex>
            ),
            header: t('configuration.combinationOf'),
            enableSorting: false,
          }),
        ]
      : []),
    columnHelper.accessor('id', {
      cell: (info) => (
        <>
          <Flex justifyContent="end" alignItems="center" gap={4}>
            {info.row.original.archived && (
              <ArchivedTooltip archived={info.row.original.archived} />
            )}
            <Menu placement="left" variant="table">
              <MenuButton float="right" aria-label={t('general.more')}>
                <Icons.DotsHorizontal boxSize={6} />
              </MenuButton>
              <MenuList key={info.row.original.id}>
                <MenuItem
                  icon={<Icons.Pencil boxSize={5} />}
                  onClick={() => onEditOpen(info.row.original.id)}
                >
                  {t('general.edit')}
                </MenuItem>
                {info.row.original.archived ? (
                  <MenuItem
                    color="texas.sand.100"
                    icon={<Icons.Undo boxSize={5} />}
                    onClick={() => onRestore(info.row.original.id)}
                  >
                    {t('general.restore')}
                  </MenuItem>
                ) : (
                  <MenuItem
                    color="texas.sand.50"
                    icon={<Icons.Archive boxSize={5} />}
                    onClick={() => onArchiveOpen(info.row.original)}
                  >
                    {t('general.archive')}
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </Flex>
        </>
      ),
      header: '',
      enableSorting: false,
      maxSize: 100,
    }),
  ];

  return columns;
};
