class ArticlesTabController {
  constructor(
    $q,
    $filter,
    $stateParams,
    $state,
    loadSpinnerService,
    ConceptsApiService,
    ArticleDialogService,
    FilesApiService,
    $location,
    concept,
    customer,
    ArticlesApiService,
    currentOrganization,
    userSettings,
    ArticleTagsApiService,
    GarpApiService,
    ExportArticleExcelDialogService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$filter = $filter;
    this.$stateParams = $stateParams;
    this.$state = $state;
    this.loadSpinnerService = loadSpinnerService;
    this.ConceptsApiService = ConceptsApiService;
    this.ArticleDialogService = ArticleDialogService;
    this.FilesApiService = FilesApiService;
    this.$location = $location;
    this.ArticlesApiService = ArticlesApiService;
    this.concept = concept;
    this.customer = customer;
    this.userSettings = userSettings;
    this.currentOrganization = currentOrganization;
    this.ArticleTagsApiService = ArticleTagsApiService;
    this.GarpApiService = GarpApiService;
    this.ExportArticleExcelDialogService = ExportArticleExcelDialogService;

    this.hasConcept = !!concept;
    this.filter = '';
    this.concepts = [];
    this.articles = [];
    this.selectedConcept = null;
    this.query = { order: '-concept.id', limit: 50, page: 1 };
    this.displayTextField = 'itemCode';
    this.states = [
      { id: 0, name: 'Registered' },
      { id: 3, name: 'On Hold' },
      { id: 4, name: 'Cancelled' },
      { id: 1, name: 'Accepted' },
      { id: 2, name: 'Rejected' },
    ];
    this.selectedArticles = [];

    if (!this.hasConcept) {
      this.loadConcepts();
    } else {
      this.concepts.push(concept);
    }

    // Rebinding these to retain `this`
    this.filterConcepts = (concept) => this.filterConcept(concept);
    this.filterArticles = (article) => this.filterArticle(article);
    this.setGridType(userSettings.articlesGridType);
    this.setDefaultVariantState();
    this.loadArticleTags();
  }

  resetTablePosition() {
    this.query.page = 1;
  }

  setGridType(type) {
    this.userSettings.articlesGridType = type || 'cards';
  }

  setDefaultVariantState() {
    this.userSettings.variantState =
      this.userSettings.variantState || this.states[0];
  }

  newArticle(isCopy) {
    const options = {};
    options.isCopy = isCopy;
    if (this.concept) {
      options.customerId = this.concept.customerId;
      options.conceptId = this.concept.id;
    }
    if (this.customer) {
      options.customerId = this.customer.id;
    }
    this.ArticleDialogService.showDialog(options).then((article) =>
      this.$location.path(`app/articles/${article.id}`),
    );
  }

  loadConcepts() {
    this.loadSpinnerService.start('mainSpinner');
    const promises = [];

    const conceptsDfd = this.$q.defer();
    this.ConceptsApiService.getAllByCustomerId(this.customer.id).then(
      (concepts) => {
        this.concepts = concepts;
        if (!isNaN(this.$stateParams.filterByConceptId)) {
          this.selectedConcept = _.find(this.concepts, {
            id: parseInt(this.$stateParams.filterByConceptId, 10),
          });
        }
        conceptsDfd.resolve();
      },
      (error) => conceptsDfd.reject(error),
    );
    promises.push(conceptsDfd.promise);

    const articlesDfd = this.$q.defer();
    this.ArticlesApiService.getAllByCustomerId(this.customer.id).then(
      (articles) => {
        this.articles = _.filter(articles, { conceptId: 0 });
        articlesDfd.resolve();
      },
      (error) => articlesDfd.reject(error),
    );
    promises.push(articlesDfd.promise);

    this.$q
      .all(promises)
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  loadArticleTags() {
    this.loadSpinnerService.start('mainSpinner');
    this.ArticleTagsApiService.getAllByOrganizationId(
      this.currentOrganization.id,
    )
      .then((articleTags) => {
        this.articleTags = articleTags;
      })
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  getAllArticles() {
    return _.flatten(_.map(this.concepts, 'articles')).concat(this.articles);
  }

  conceptSearch(query) {
    return this.$filter('filter')(this.concepts, { name: query });
  }

  stateSearch(query) {
    return this.$filter('filter')(this.states, { name: query });
  }

  getState(state) {
    return this.states.find((s) => s.id === state);
  }

  tagSearch(query) {
    return this.$filter('filter')(this.articleTags, { name: query });
  }

  filterConcept(concept) {
    if (!_.some(concept.articles, this.filterArticles)) {
      return this.selectedConcept && this.selectedConcept.id === concept.id;
    }

    if (this.selectedConcept && this.selectedConcept.id !== concept.id) {
      return false;
    }

    return !concept.archived;
  }

  filterArticle(article) {
    if (
      !_.isEmpty(this.filter) &&
      article.name.toLowerCase().indexOf(this.filter.toLowerCase()) === -1 &&
      (article.itemCode || '')
        .toLowerCase()
        .indexOf(this.filter.toLowerCase()) === -1
    ) {
      return false;
    }

    if (this.selectedConcept && this.selectedConcept.id !== article.conceptId) {
      return false;
    }

    if (
      this.userSettings.variantState &&
      this.userSettings.variantState.id !== article.state
    ) {
      return false;
    }

    if (
      this.selectedTag &&
      !_.some(article.articleTags, { id: this.selectedTag.id })
    ) {
      return false;
    }

    return !article.archived;
  }

  goToArticle(article) {
    this.$state.go('article', { articleId: article.id });
  }

  goToConcept(concept) {
    this.$state.go('concept.articles', { conceptId: concept.id });
  }

  getPreviewUrl(article) {
    if (!_.isEmpty(article.photoIdentifier)) {
      return this.FilesApiService.getFilePreviewUrl(
        article.photoIdentifier,
        250,
      );
    } else if (!_.isEmpty(article.artworkIdentifier)) {
      return this.FilesApiService.getFilePreviewUrl(
        article.artworkIdentifier,
        250,
      );
    } else {
      return '/assets/images/250x250.png';
    }
  }

  exportArticlesToExcel(articles) {
    this.exportToExcel(_.filter(articles, (a) => this.filterArticle(a)));
  }

  exportToExcel(selectedArticles) {
    this.ExportArticleExcelDialogService.showDialog({
      selectedArticles,
      organization: this.currentOrganization,
    });
  }
}

angular
  .module('tabs.articles', [])
  .controller('ArticlesTabController', ArticlesTabController);
