import { useCallback, useEffect, useState } from 'react';
import { createContextValue } from '../utils/contextState';
import {
  SessionState,
  SessionStatus,
  sessionApi,
} from '@texas/api/endpoints/sessionApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { authApi } from '@texas/api/endpoints/authApi';
import { reactEvents } from '@bridge/reactEvents';

export const useSessionState = () => {
  const [sessionState, setSessionState] = useState<SessionState>({
    status: SessionStatus.Inactive,
  });
  const { request: loginRequest, error: loginError } = useApiRequest(
    authApi.login,
  );
  const { request: logoutRequest } = useApiRequest(authApi.logout);

  const getSession = useCallback(async () => {
    setSessionState({ status: SessionStatus.Fetching });
    const response = await sessionApi.getSession();

    if (!response.hasError) {
      setSessionState({
        status: SessionStatus.Active,
        session: response.data,
      });
    } else {
      setSessionState({
        status: SessionStatus.Error,
        error: response.error,
      });
    }
  }, []);

  useEffect(() => {
    getSession();
  }, [getSession]);

  const login = useCallback(
    async (email: string, password: string) => {
      const response = await loginRequest(email, password);

      if (response.hasError) {
        return;
      }

      await getSession();

      reactEvents.routingChanged.dispatch({ to: 'customers' });
    },
    [getSession, loginRequest],
  );

  const logout = useCallback(async () => {
    const response = await logoutRequest();

    if (response.hasError) {
      return;
    }

    setSessionState({ status: SessionStatus.Inactive });

    window.location.href = '/';
  }, [logoutRequest]);

  return { sessionState, login, loginError, logout };
};

export const [SessionStateProvider, useSession] =
  createContextValue<ReturnType<typeof useSessionState>>();

export function useActiveSession() {
  const { sessionState } = useSession();

  if (sessionState.status !== SessionStatus.Active) {
    throw new Error('No active session');
  }

  return sessionState.session;
}
