// @ngInject
const ConceptsApiService = ($q, DS, ResourcesService) => ({
  getAllByCustomerId(customerId, useCache) {
    const deferred = $q.defer();

    DS.findAll('concept', null, {
      bypassCache: !useCache,
      useFilter: true,
      params: { customerId, organizationId: false },
    }).then(
      (response) => {
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  getById(conceptId, useCache) {
    const deferred = $q.defer();

    DS.find('concept', conceptId, {
      bypassCache: !useCache,
      params: { customerId: false },
    }).then(
      (response) => {
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  updateConceptInfo(data, conceptId) {
    const deferred = $q.defer();

    DS.update('concept', conceptId, data, {
      cacheResponse: false,
      params: { customerId: false },
    }).then(
      (response) => {
        const concept = DS.get('concept', conceptId);
        concept.name = response.name;
        concept.archived = response.archived;
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
});

angular
  .module('resources.concepts', [])
  .factory('ConceptsApiService', ConceptsApiService);
