// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('variant.versions', {
    controller: 'VersionsController as vm',
    parent: 'variant',
    url: '/versions',
    templateUrl: 'main/article/variant/versions/versions.tpl.html',
    data: { pageTitle: 'Versions' },
  });
};

class VersionsController {
  constructor(variant) {
    'ngInject';

    this.variant = variant;
    this.article = variant.article;
    this.nodeGroup = {
      nodeXValues: this.article.nodeXValues,
      nodeYValues: this.article.nodeYValues,
    };
  }
}

angular
  .module('main.article.variant.versions', [])
  .config(config)
  .controller('VersionsController', VersionsController);
