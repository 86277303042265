import { Box } from '@chakra-ui/react';
import { OptionsOverview } from '../compositions/optionOverview/OptionsOverview';
import { BodyOverride } from '@texas/components/shared/BodyOverride';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import {
  formatNodeGroup,
  nodeGroupIsPopulated,
} from '@texas/utils/helpers/nodeGroupHelpers';
import { DragAndDrop } from '@texas/components/shared/dragAndDrop/DragAndDrop';
import { useEffect, useState } from 'react';
import { compositionGroupApi } from '@texas/api/endpoints/compositionGroupApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { MatrixView } from '../compositions/matrix/MatrixView';
import { compositionGroupEvents } from '../compositions/shared/events';

export function VariantOptions({
  variantId,
  articleNodeGroup,
}: {
  variantId: number;
  articleNodeGroup: ArticleNodeGroup;
}) {
  return (
    <BodyOverride>
      <Box mx="auto">
        {!nodeGroupIsPopulated(articleNodeGroup) ? (
          <OptionsOverview variantId={variantId} enableCopy={false} />
        ) : (
          <MatrixComposition
            variantId={variantId}
            articleNodeGroup={articleNodeGroup}
          />
        )}
      </Box>
    </BodyOverride>
  );
}

function MatrixComposition({
  variantId,
  articleNodeGroup,
}: {
  variantId: number;
  articleNodeGroup: ArticleNodeGroup;
}) {
  const [selectedCell, setSelectedCell] = useState<
    [number, number, number, number] | null
  >(null);
  const { data, refetch, loading } = useApiResource(
    compositionGroupApi.getAllCellGroups,
  );
  useEffect(() => {
    refetch(variantId);
  }, [refetch, variantId]);

  useEffect(() => {
    refetch(variantId);
  }, [refetch, variantId]);

  useEffect(() => {
    const unsub1 = compositionGroupEvents.compositionGroupsChanged.sub(() => {
      refetch(variantId);
    });
    const unsub2 = compositionGroupEvents.compositionMaterialUpdated.sub(() => {
      refetch(variantId);
    });
    const unsub3 = compositionGroupEvents.optionArchivedOrRestored.sub(() => {
      refetch(variantId);
    });
    return () => {
      unsub1();
      unsub2();
      unsub3();
    };
  }, [refetch, variantId]);

  return (
    <DragAndDrop dndProps={{ autoScroll: false }}>
      <>
        <Box bg="texas.bg.980" _light={{ bg: 'gray.50' }} mb={6}>
          <MatrixView
            cells={data}
            refetchCells={() => refetch(variantId)}
            cellsLoading={loading}
            selectedCell={selectedCell}
            onCellSelected={(cell) => {
              setSelectedCell(cell);
            }}
            articleNodeGroup={articleNodeGroup}
            variantId={variantId}
          />
        </Box>
        {selectedCell && (
          <OptionsOverview
            enableCopy={true}
            label={formatNodeGroup(
              articleNodeGroup.nodeXValues[selectedCell[2]],
              articleNodeGroup.nodeYValues[selectedCell[3]],
              true,
            )}
            variantId={variantId}
            cellX={selectedCell[0]}
            cellY={selectedCell[1]}
          />
        )}
      </>
    </DragAndDrop>
  );
}
