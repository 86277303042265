// @ngInject
const AuthService = (
  $rootScope,
  $q,
  $state,
  $window,
  UsersApiService,
  AuthApiService,
) => {
  let _isAuthenticated = false;
  let _user = null;

  const isAuthenticated = () => {
    return _isAuthenticated;
  };

  const hasAnyClaim = (roles) => {
    if (!isAuthenticated() || !_user.role) {
      return false;
    }

    return roles.includes(_user.role);
  };

  const showLogin = () => {
    $state.go('login');
    return;
  };

  const getUser = () => {
    const deferred = $q.defer();

    // If we already have the user, just return it
    if (_user) {
      deferred.resolve(_user);
    } else {
      UsersApiService.getSelf().then(
        (user) => {
          _user = user;
          _isAuthenticated = true;
          deferred.resolve(_user);
        },
        () => {
          deferred.reject();
        },
      );
    }

    return deferred.promise;
  };

  const logout = () => {
    AuthApiService.logout().then(() => {
      // Reload the app
      $window.location.href = '/';
    });
  };

  return {
    isAuthenticated,
    hasAnyClaim,
    getUser,
    showLogin,
    logout,
  };
};

angular.module('security.authService', []).factory('AuthService', AuthService);
