import {
  Text,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
  FormControl,
  Input,
  Heading,
  FormLabel,
  useToast,
  TabList,
  Tab,
  Tabs,
  TabPanels,
  TabPanel,
  Switch,
} from '@chakra-ui/react';
import { defaultIconSize, Icons } from '../shared/Icons';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { Role, UpdateUserRequest, userApi } from '@texas/api/endpoints/userApi';
import { useEffect } from 'react';
import { SubmitButton } from '../shared/form/SubmitButton';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { Controller, useForm } from 'react-hook-form';
import { ErrorLabel } from '../shared/ErrorLabel';
import { useActiveSession } from '@texas/hooks/useSession';
import { TexasSelect } from '../shared/form/TexasSelect';
import { branchesApi } from '@texas/api/endpoints/branchesApi';
import { ReactSelectOption } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useTranslation } from 'react-i18next';
import { countriesApi } from '@texas/api/endpoints/metadata/countriesApi';
import { RoleIcon } from '../shared/RoleIcon';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';
import { roleFriendlyName } from '@texas/utils/helpers/claimHelpers';
import { userEvents } from './events';
import { ErrorDetails } from '../shared/alert/ErrorDetails';
import {
  fadeInRightAnimation,
  fadeInScaleAnimation,
} from '@texas/resources/animations/animations';
import { SharedDisclosureProps } from '../shared/types';
import { UserProfiles } from './keyAccountManager/UserProfiles';
import { RoleRestrict } from '../shared/RoleRestrict';

interface Props extends SharedDisclosureProps {
  id: number;
}

export function EditUser({ id, isOpen, onClose }: Props) {
  const { t } = useTranslation();
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('user.editUser')}</ModalHeader>
          <ModalCloseButton />
          <InnerModal id={id} onClose={onClose} />
        </ModalContent>
      </Modal>
    </>
  );
}

function InnerModal({ id, onClose }: { id: number; onClose: () => void }) {
  const toast = useToast();
  const { t } = useTranslation();
  const {
    data,
    refetch,
    loading: fetchLoading,
  } = useApiResource(userApi.getUser);
  const {
    data: branches,
    refetch: refetchBranches,
    loading: branchesLoading,
  } = useApiResource(branchesApi.getBranches);
  const {
    data: countries,
    refetch: refetchCountries,
    loading: countriesLoading,
    set: setCountries,
  } = useApiResource(countriesApi.getCountries);

  const { request: updateRequest, error } = useApiRequest(userApi.updateUser);
  const session = useActiveSession();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting, isValid, dirtyFields },
    reset,
    watch,
  } = useForm<UpdateUserRequest>();

  useEffect(() => {
    refetch(id);
    refetchBranches();
  }, [id, refetch, refetchBranches]);

  const branchId = watch('branchId');
  useEffect(() => {
    if (!branchId) {
      setCountries([]);
      return;
    }
    refetchCountries(branchId);
  }, [branchId, refetchCountries, setCountries]);

  useEffect(() => {
    reset({
      name: data?.name,
      email: data?.email,
      branchId: data?.branchId,
      countryId: data?.countryId,
      emailNotifications: data?.emailNotifications,
    });
  }, [
    data?.branchId,
    data?.countryId,
    data?.email,
    data?.emailNotifications,
    data?.name,
    reset,
  ]);

  const editingSelf = session.currentUser.id === id;

  const onSubmit = async (data: UpdateUserRequest) =>
    await request(updateRequest, [id, data], (_) => {
      toast({
        title: t('general.updated'),
        status: 'success',
        isClosable: true,
      });
      if (editingSelf) userEvents.currentUserUpdated.dispatch();
      userEvents.userUpdated.dispatch();
      onClose();
    });

  return (
    <>
      <Tabs>
        <TabList>
          <Tab>{t('general.information')}</Tab>
          <RoleRestrict allow={[Role.SystemAdministrator]}>
            <Tab>{t('userProfile.userProfiles')}</Tab>
          </RoleRestrict>
        </TabList>

        <TabPanels>
          <TabPanel p={0}>
            {error && <ErrorDetails error={error} />}
            <Box bg="texas.bg.800" _light={{ bg: 'gray.50' }}>
              <ModalBody>
                <Flex gap={2} alignItems="center" py={2}>
                  {data && (
                    <>
                      <RoleIcon
                        animation={fadeInScaleAnimation()}
                        userRole={convertToEnum(Role, data.role)}
                        boxSize={6}
                      />
                      <Flex flexDir="column" animation={fadeInRightAnimation()}>
                        <Text fontSize="xs" opacity={0.6}>
                          {editingSelf
                            ? t('user.youAre')
                            : t('user.thisUserIs')}
                        </Text>
                        <Heading fontSize="md">
                          {roleFriendlyName(convertToEnum(Role, data.role), t)}
                        </Heading>
                      </Flex>
                    </>
                  )}
                </Flex>
              </ModalBody>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalBody as={Flex} flexDir="column" gap={2}>
                <FormControl isInvalid={!!errors.email?.message}>
                  <FormLabel>{t('general.email')}</FormLabel>
                  <Input
                    type="email"
                    {...register('email', { required: true })}
                    variant="texas"
                  />
                  <ErrorLabel text={errors.email?.message} />
                </FormControl>
                <FormControl isInvalid={!!errors.name?.message}>
                  <FormLabel>{t('general.name')}</FormLabel>
                  <Input {...register('name')} variant="texas" />
                  <ErrorLabel text={errors.name?.message} />
                </FormControl>
                <FormControl>
                  <FormLabel>{t('user.belongsToBranch')}</FormLabel>
                  <Controller
                    name="branchId"
                    control={control}
                    render={({ field }) => (
                      <TexasSelect
                        {...field}
                        value={branches
                          ?.map((x) => ({ label: x.name, value: x.id }))
                          .find((x) => x.value === field.value)}
                        onChange={(value) =>
                          field.onChange(value?.value ?? null)
                        }
                        isClearable={true}
                        isLoading={branchesLoading}
                        options={
                          branches?.map<ReactSelectOption>((u) => ({
                            label: u.name,
                            value: u.id,
                          })) ?? []
                        }
                      />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>{t('user.operatesInCountry')}</FormLabel>
                  <Controller
                    name="countryId"
                    control={control}
                    render={({ field }) => (
                      <TexasSelect
                        {...field}
                        value={countries
                          ?.map((x) => ({ label: x.name, value: x.id }))
                          .find((x) => x.value === field.value)}
                        onChange={(value) =>
                          field.onChange(value?.value ?? null)
                        }
                        isClearable={true}
                        isDisabled={!branchId}
                        isLoading={countriesLoading}
                        options={
                          countries?.map<ReactSelectOption>((u) => ({
                            label: u.name,
                            value: u.id,
                          })) ?? []
                        }
                      />
                    )}
                  />
                </FormControl>
                <FormControl display="flex" alignItems="center" pt={2} gap={2}>
                  <Controller
                    name="emailNotifications"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        isChecked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  <FormLabel mb="0">{t('user.emailNotifications')}</FormLabel>
                </FormControl>
              </ModalBody>
              <ModalFooter
                bg="texas.bg.950"
                borderBottomRadius="md"
                as={Flex}
                flexDir="column"
                gap={4}
                _light={{ bg: 'gray.50' }}
              >
                {dirtyFields.email && (
                  <FormControl animation={fadeInScaleAnimation()}>
                    <FormLabel>
                      <Flex gap={2}>
                        <Icons.ShieldKey boxSize={defaultIconSize} />
                        <Text>{t('user.passwordRequiredEmail')}</Text>
                      </Flex>
                    </FormLabel>
                    <Input
                      type="password"
                      placeholder={t('user.enterYourPassword')}
                      {...register('providedPassword')}
                      variant="texas"
                    />
                  </FormControl>
                )}
                <SubmitButton
                  disabled={!isValid}
                  loading={fetchLoading || isSubmitting}
                >
                  {t('general.update')}
                </SubmitButton>
              </ModalFooter>
            </form>
          </TabPanel>
          <RoleRestrict allow={[Role.SystemAdministrator]}>
            <TabPanel p={0}>
              <UserProfiles userId={id} />
            </TabPanel>
          </RoleRestrict>
        </TabPanels>
      </Tabs>
    </>
  );
}
