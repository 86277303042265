import { Spinner, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export function LoadingSessionView() {
  const { t } = useTranslation();
  return (
    <Flex
      position="fixed"
      color="gray.100"
      inset="0"
      zIndex="overlay"
      alignItems="center"
      justifyContent="center"
    >
      <Text fontWeight="bold" fontSize="xl">
        {t('session.loadingTexas')}
      </Text>
      <Spinner thickness="3px" />
    </Flex>
  );
}
