import { Role } from '@texas/api/endpoints/userApi';
import { useActiveSession } from '@texas/hooks/useSession';
import { hasRole } from '@texas/utils/helpers/claimHelpers';

interface RoleRestrictProps {
  allow: Role[];
  children: React.ReactNode;
}

export function RoleRestrict(props: RoleRestrictProps) {
  const session = useActiveSession();
  if (!hasRole(session.currentUser.role, props.allow)) {
    return null;
  }

  return <>{props.children}</>;
}
