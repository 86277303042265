import {
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import {
  CreateFolderRequest,
  filesApi,
  Folder,
} from '@texas/api/endpoints/filesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { SubmitButton } from '@texas/components/shared/form/SubmitButton';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface CreateFolderProps extends SharedDisclosureProps {
  parentId: number;
  onCreated: (f: Folder) => void;
}
export function CreateFolderModal({
  parentId,
  onCreated,
  onClose,
  isOpen,
}: CreateFolderProps) {
  const { t } = useTranslation();
  const toast = useToast();
  const { request: createRequest, error } = useApiRequest(
    filesApi.createFolder,
  );

  const defaultValues: CreateFolderRequest = {
    name: '',
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<CreateFolderRequest>({ defaultValues });

  const onSubmit = async (data: CreateFolderRequest) =>
    await request(
      createRequest,
      [parentId, data],
      (f) => {
        reset(defaultValues);
        toast({
          title: t('general.created'),
          status: 'success',
          isClosable: true,
        });
        onCreated(f);
        onClose();
      },
      (error: ServerError) => {
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontWeight="bold">
          {t('fileBrowser.newFolder')}
        </ModalHeader>
        <ModalBody>
          {error && <ErrorDetails error={error} />}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex direction="column" gap={2} pb={4}>
              <FormControl isRequired={true}>
                <TexasFormLabel>{t('general.name')}</TexasFormLabel>
                <Input
                  {...register('name', {
                    required: true,
                  })}
                  variant="outline"
                  placeholder={t('general.name')}
                  autoFocus={true}
                />
                <ErrorLabel text={errors.name?.message} />
              </FormControl>
              <SubmitButton loading={isSubmitting}>
                {t('general.create')}
              </SubmitButton>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
