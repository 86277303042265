import {
  Box,
  Grid,
  GridItem,
  Flex,
  Text,
  Card,
  CardBody,
} from '@chakra-ui/react';
import { useFileUploads } from '@texas/api/hooks/useFileUploads';
import { Icons } from '@texas/components/shared/Icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderOverview } from './FolderOverview';
import { FolderListItem } from './FolderListItem';
import { FileBrowserMode } from './FileBrowserModal';
import { FileLink } from '@texas/api/endpoints/filesApi';
import { fileEvents } from '../dropzone/events';
import { DropZone } from '../dropzone/DropZone';
import { FileUploads } from '../dropzone/FileUploads';

export function FileBrowserContent({
  rootFolderId,
  isArticle,
  mode = 'Edit',
  onSelect,
  extensionFilter,
  rejectExtensionFilter,
}: {
  rootFolderId: number;
  isArticle: boolean;
  mode?: FileBrowserMode;
  onSelect?: (image: FileLink) => void;
  extensionFilter?: string[];
  rejectExtensionFilter?: string[];
}) {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedFolderId, setSelectedFolderId] =
    useState<number>(rootFolderId);

  const onUploadcomplete = useCallback((file: FileLink) => {
    fileEvents.fileUploadComplete.dispatch(file);
  }, []);

  const { fileUploads, uploadFiles, abortFileUpload, resetFileUploads } =
    useFileUploads(onUploadcomplete);

  useEffect(() => {
    resetFileUploads();
  }, [selectedFolderId, resetFileUploads]);

  return (
    <Card variant="dark" ref={containerRef}>
      <CardBody>
        <Grid
          templateAreas={isArticle ? `"main"` : `"nav main"`}
          gridTemplateColumns={isArticle ? 'auto' : 'min-content auto'}
          gap={4}
        >
          {!isArticle && (
            <GridItem
              _light={{ bg: 'white', borderColor: 'gray.50' }}
              area="nav"
              pr={6}
              borderRight="1px solid"
              borderColor="gray.900"
            >
              <Flex direction="column">
                <Flex
                  justifyContent="flex-start"
                  alignItems="center"
                  borderRadius="md"
                  gap={2}
                  mb={4}
                >
                  <Icons.FolderOutline
                    _light={{ color: 'texas.bg.800' }}
                    boxSize={7}
                  />
                  <Text variant="header-medium" fontSize="2xl">
                    {t('fileBrowser.folders')}
                  </Text>
                </Flex>
                <FolderListItem
                  level={0}
                  folderId={rootFolderId}
                  mode={mode}
                  selectedFolder={selectedFolderId}
                  setSelectedFolder={setSelectedFolderId}
                />
              </Flex>
            </GridItem>
          )}
          <GridItem _light={{ bg: 'white' }} area="main">
            {mode === 'Edit' && (
              <>
                <Text variant="header-medium" fontSize="2xl" pb={4}>
                  {isArticle
                    ? t('fileBrowser.fileLibrary')
                    : t('fileBrowser.files')}
                </Text>
                <Flex>
                  <Box w={80} pb={4}>
                    <DropZone
                      multiple={true}
                      onUpload={(files) => uploadFiles(files, selectedFolderId)}
                    />
                  </Box>
                  <Flex
                    py={4}
                    pl={4}
                    gap={4}
                    alignContent="flex-start"
                    flexWrap="wrap"
                  >
                    <FileUploads
                      fileUploads={fileUploads}
                      abortFileUpload={abortFileUpload}
                    />
                  </Flex>
                </Flex>
              </>
            )}
            {selectedFolderId && (
              <FolderOverview
                id={selectedFolderId}
                mode={mode}
                onSelect={onSelect}
                extensionFilter={extensionFilter}
                rejectExtensionFilter={rejectExtensionFilter}
                containerRef={containerRef}
              />
            )}
          </GridItem>
        </Grid>
      </CardBody>
    </Card>
  );
}
