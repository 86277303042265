import { Tag, TagLabel, Text, useToast } from '@chakra-ui/react';
import {
  FileLink,
  filesApi,
  UpdateFileResponse,
} from '@texas/api/endpoints/filesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { VerifyDialogWithFunction } from '@texas/components/shared/dialog/VerifyDialogWithFunction';
import { ErrorsList } from '@texas/components/shared/ErrorsList';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from '../Icons';

interface ArchiveFileProps extends SharedDisclosureProps {
  file: FileLink;
  onArchived: (f: UpdateFileResponse) => void;
}

export function ArchiveFile({
  file,
  isOpen,
  onClose,
  onArchived,
}: ArchiveFileProps) {
  const { t } = useTranslation();
  const toast = useToast();
  const { data: fileUsage, refetch } = useApiResource(filesApi.getFileUsage);

  useEffect(() => {
    refetch(file.id);
  }, [file, refetch]);

  const { request: archiveRequest } = useApiRequest(filesApi.archiveFile);

  const onArchive = async () => {
    await request(
      archiveRequest,
      [file.id],
      (response) => {
        toast({
          title: t('general.successfullyArchived'),
          status: 'success',
          isClosable: true,
        });
        onClose();
        onArchived(response);
      },
      (error: ServerError) => {
        toast({
          title: t('general.archiveFailed'),
          description: <ErrorsList errors={error.errors} />,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };

  return (
    <VerifyDialogWithFunction
      headerTitle={t('fileBrowser.archiveFile')}
      secondaryButtonTitle={t('general.cancel')}
      primaryButtonTitle={t('general.confirm')}
      isOpen={isOpen}
      onClose={onClose}
      toPerform={async () => {
        await onArchive();
      }}
    >
      {fileUsage?.artwork && (
        <Tag colorScheme="red">
          <Icons.AlertCircleOutline boxSize={5} mr={2} />
          <TagLabel>{t('fileBrowser.fileUsage.usedAsArtwork')}</TagLabel>
        </Tag>
      )}
      {fileUsage?.photo && (
        <Tag colorScheme="red">
          <Icons.AlertCircleOutline boxSize={5} mr={2} />
          <TagLabel>{t('fileBrowser.fileUsage.usedAsPhoto')}</TagLabel>
        </Tag>
      )}
      {fileUsage?.document && (
        <Tag colorScheme="red">
          <Icons.AlertCircleOutline boxSize={5} mr={2} />
          <TagLabel>{t('fileBrowser.fileUsage.usedAsDocument')}</TagLabel>
        </Tag>
      )}
      {fileUsage?.matrixPhoto && (
        <Tag colorScheme="red">
          <Icons.AlertCircleOutline boxSize={5} mr={2} />
          <TagLabel> {t('fileBrowser.fileUsage.usedAsMatrixPhoto')}</TagLabel>
        </Tag>
      )}
      <Text pt={2}>{t('alert.areYouSure')}</Text>
    </VerifyDialogWithFunction>
  );
}
