import { httpClient } from '@texas/api/httpClient/httpClient';

export interface Country {
  name: string;
  code: string;
  id: number;
}

export const countriesApi = {
  getCountries: (branchId: number) =>
    httpClient.get<Country[]>(`metadata/countries/branch/${branchId}`),
};
