import { keyframes } from '@emotion/react';

const shakeAnimation = (duration?: number) => {
  const animKeyframes = keyframes`
  0% { transform: translateX(0) }
  15% { transform: translateX(0.1em) }
  30% { transform: translateX(-0.15em) }
  45% { transform: translateX(0.2em) }
  60% { transform: translateX(-0.2em) }
  75% { transform: translateX(0.15em) }
  90% { transform: translateX(-0.1em) }
  100% { transform: translateX(0) }`;

  return `${animKeyframes} ${duration ?? 500}ms linear`;
};

const fadeInScaleAnimation = (duration?: number, delay?: number) => {
  const animKeyframes = keyframes`
  0% { opacity: 0; transform: scale(0.9) }
  90% { transform: scale(1.01) }
  100% { opacity: 1; transform: scale(1) }`;
  return `${animKeyframes} ${duration ?? 200}ms ease ${delay ?? 0}ms`;
};

const fadeInScalePopAnimation = (duration?: number, delay?: number) => {
  const animKeyframes = keyframes`
  0% { opacity: 0; transform: scale(0.9) }
  40% { opacity: 1; transform: scale(1.3) }
  80% { transform: scale(1.3) }
  100% { opacity: 1; transform: scale(1) }`;
  return `${animKeyframes} ${duration ?? 400}ms ease ${delay ?? 0}ms`;
};

const fadeInAnimation = (duration?: number) => {
  const animKeyframes = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }`;
  return `${animKeyframes} ${duration ?? 200}ms linear`;
};

const fadeInLeftAnimation = (duration?: number, delay?: number) => {
  const animKeyframes = keyframes`
  0% { opacity: 0; transform: translateX(-1em) }
  100% { opacity: 1; transform: translateX(0) };`;
  return `${animKeyframes} ${duration ?? 200}ms ease ${delay ?? 0}ms`;
};
const fadeInRightAnimation = (duration?: number, delay?: number) => {
  const animKeyframes = keyframes`
  0% { opacity: 0; transform: translateX(1em) }
  100% { opacity: 1; transform: translateX(0) };`;
  return `${animKeyframes} ${duration ?? 200}ms ease ${delay ?? 0}ms`;
};

const scaleInWidthAnimation = (
  toWidth: string,
  duration?: number,
  delay?: number,
) => {
  const animKeyframes = keyframes`
  0% { max-width: 0 }
  100% { max-width: ${toWidth} };`;
  return `${animKeyframes} ${duration ?? 200}ms ease ${
    delay ?? 0
  }ms 1 normal both`;
};
const scaleInHeightAnimation = (
  toHeight: string,
  duration?: number,
  delay?: number,
) => {
  const animKeyframes = keyframes`
  0% { max-height: 0; opacity: 0 }
  100% { max-height: ${toHeight}; opacity: 1 };`;
  return `${animKeyframes} ${duration ?? 200}ms ease ${
    delay ?? 0
  }ms 1 normal both`;
};

const fadeInOutAnimation = (duration: number) => {
  const animKeyframes = keyframes`
  0% { opacity: 0 }
  10% { opacity: 1 }
  90% { opacity: 1 }
  100% { opacity: 0 }`;
  return `${animKeyframes} ${duration}ms linear`;
};

const scaleInAnimation = (duration?: number) => {
  const animKeyframes = keyframes`
  0% { transform: scale(0) }
  100% { transform: scale(1) };`;
  return `${animKeyframes} ${duration ?? 200}ms ease`;
};

const stretchAnimation = () => {
  const animKeyframes = keyframes`
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  7% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  10% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  13% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  20% {
    -webkit-transform: scale3d(.95, 1.05, 1);
            transform: scale3d(.95, 1.05, 1);
  }
  25% {
    -webkit-transform: scale3d(1.05, .95, 1);
            transform: scale3d(1.05, .95, 1);
  }
  30% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }`;
  return `${animKeyframes} 4000ms ease infinite`;
};

const blinkAnimation = () => {
  const animKeyframes = keyframes`
  0% { opacity: 1 }
  50% { opacity: 0.5 }
  100% { opacity: 1`;
  return `${animKeyframes} 1.5s linear infinite`;
};

export {
  shakeAnimation,
  fadeInAnimation,
  fadeInLeftAnimation,
  fadeInOutAnimation,
  fadeInScaleAnimation,
  scaleInWidthAnimation,
  scaleInHeightAnimation,
  stretchAnimation,
  scaleInAnimation,
  blinkAnimation,
  fadeInRightAnimation,
  fadeInScalePopAnimation,
};
