import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';

interface Props {
  length: number;
  maxLength: number;
}

export function FormCounter({ length, maxLength }: Props) {
  const { t } = useTranslation();
  return (
    <Text
      fontSize="xs"
      color={length > maxLength ? 'texas.danger.100' : 'gray.400'}
      _light={{
        color: length > maxLength ? 'red.600' : 'gray.400',
      }}
      pt={1}
    >
      {t('form.maxCounter', {
        current: length,
        max: maxLength,
      })}
    </Text>
  );
}
