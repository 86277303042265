// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('customers', {
    parent: 'main',
    url: '/customers',
    views: {
      'main-content': {
        controller: 'CustomersController as vm',
        templateUrl: 'main/customers/customers.tpl.html',
      },
    },
    data: { pageTitle: 'Customers' },
  });
};

class CustomersController {
  constructor(
    currentUser,
    $filter,
    $scope,
    customerTypes,
    $state,
    CustomerDialogService,
    ArticleDialogService,
  ) {
    'ngInject';

    this.currentUser = currentUser;
    this.$filter = $filter;
    this.customerTypes = customerTypes;
    this.$state = $state;
    this.$scope = $scope;
    this.CustomerDialogService = CustomerDialogService;
    this.ArticleDialogService = ArticleDialogService;
    this.selectedCustomerType = customerTypes[0];
  }

  newCustomer() {
    this.CustomerDialogService.showDialog({
      organization: this.currentUser.organization,
      defaultType: this.selectedCustomerType,
    }).then((customer) => {
      this.goToCustomer(customer);
    });
  }

  newArticle(isCopy) {
    this.ArticleDialogService.showDialog({
      organization: this.currentUser.organization,
      isCopy,
    }).then((article) => {
      this.goToArticle(article);
    });
  }

  goToConcept(concept) {
    this.$state.go('concept', { conceptId: concept.id });
  }
  goToArticle(article) {
    this.$state.go('article', { articleId: article.id });
  }
  goToCustomer(customer) {
    this.$state.go('customer', { customerId: customer.id });
  }
}

angular
  .module('main.customers', [])
  .config(config)
  .controller('CustomersController', CustomersController);
