import {
  ArticleState,
  VariantsSearchQuery,
} from '@texas/api/endpoints/searchApi';
import { FilterOptionType } from '../../filter/types';
import { ArticleSearchProps, FilterOptions } from '../../searchArticles/types';
import { defaultEmptyFilters } from '../../searchArticles/useSearchArticles';
import { defaultPage } from './defaultWidgetsOptions';

const defaultSearchParams: VariantsSearchQuery = {
  searchTerm: '',
  sortBy: '',
  sortDesc: false,
  page: defaultPage,
  ...defaultEmptyFilters,
  showAllVariants: undefined,
  includeArchived: undefined,
  showMyBrands: false,
};

export const defaultRegisteredWidgetOptions = (
  customerId: number | undefined,
  conceptId: number | undefined,
) => {
  return widgetOptions(customerId, conceptId);
};

function widgetOptions(
  customerId: number | undefined,
  conceptId: number | undefined,
) {
  if (customerId) {
    return brandsOptions(customerId);
  } else if (conceptId) {
    return conceptOptions(conceptId);
  }

  throw Error('Customer or concept properties not declared');
}

const brandsOptions = (customerId: number) => {
  const defaultSearchPage: ArticleSearchProps = {
    filters: [FilterOptionType.Customers, FilterOptionType.States],
    searchParams: {
      ...defaultSearchParams,
      customerIds: [customerId],
      states: [ArticleState.Registered],
    },
  };

  const filterOptions: FilterOptions = {
    [FilterOptionType.Customers]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.ShowMyBrands]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.States]: {
      disabled: true,
      static: false,
    },
  };

  return { defaultSearchPage, filterOptions };
};

const conceptOptions = (conceptId: number) => {
  const defaultSearchPage: ArticleSearchProps = {
    filters: [FilterOptionType.Concepts, FilterOptionType.States],
    searchParams: {
      ...defaultSearchParams,
      conceptIds: [conceptId],
      states: [ArticleState.Registered],
    },
    view: 'table',
  };

  const filterOptions: FilterOptions = {
    [FilterOptionType.Customers]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.Concepts]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.ShowMyBrands]: {
      static: true,
      disabled: true,
    },
    [FilterOptionType.States]: {
      disabled: true,
      static: false,
    },
  };

  return { defaultSearchPage, filterOptions };
};
