import { Flex, Text, useToast, useDisclosure } from '@chakra-ui/react';
import { FileLink } from '@texas/api/endpoints/filesApi';
import {
  VariantFilesInfo,
  variantsApi,
} from '@texas/api/endpoints/variantsApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useFileUploads } from '@texas/api/hooks/useFileUploads';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { ErrorsList } from '@texas/components/shared/ErrorsList';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FileBrowserModal } from '../../../../shared/files/FileBrowserModal';
import { FileDropzone } from '../../../../shared/dropzone/FileDropzone';
import { DocumentDropzone } from '../../../../shared/dropzone/DocumentDropzone';
import { notImageValidator } from '@texas/components/shared/dropzone/shared';
import { imageExtensions } from '@texas/utils/helpers/filesHelper';

export function ArtworkDocument({
  variantId,
  variantFilesInfo,
  setVariantFilesInfo,
  allowEdit,
}: {
  variantId: number;
  variantFilesInfo: VariantFilesInfo;
  setVariantFilesInfo: (value: SetStateAction<VariantFilesInfo | null>) => void;
  allowEdit: boolean;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const { request: updateRequest } = useApiRequest(
    variantsApi.updateArtworkDocument,
  );

  const {
    isOpen: isRemovefileOpen,
    onClose: onRemovefileClose,
    onOpen: onRemovefileOpen,
  } = useDisclosure();

  const {
    isOpen: isFileBrowserOpen,
    onClose: onFileBrowserClose,
    onOpen: onFileBrowserOpen,
  } = useDisclosure();

  const updateDocument = useCallback(
    (document: FileLink) => {
      request(
        updateRequest,
        [variantId, document.id],
        (_) => {
          toast({
            title: t('fileBrowser.updatedFile'),
            status: 'success',
            isClosable: true,
          });
          setVariantFilesInfo({
            ...variantFilesInfo,
            artworkDocumentId: document.id,
            artworkDocument: document,
          });
        },
        (error: ServerError) => {
          toast({
            title: t('fileBrowser.updateFileFailed'),
            description: <ErrorsList errors={error.errors} />,
            status: 'error',
            isClosable: true,
          });
        },
      );
    },
    [setVariantFilesInfo, t, toast, updateRequest, variantFilesInfo, variantId],
  );

  const {
    fileUploads,
    isUploading,
    uploadFailed,
    uploadFiles,
    abortFileUpload,
  } = useFileUploads(updateDocument);

  return (
    <Flex direction="column" gap={2}>
      <Text variant="header">{t('variant.document')}</Text>
      <Flex justifyContent="space-between" gap={4}>
        {variantFilesInfo.artworkDocument && (
          <Flex w="fit-content">
            <DocumentDropzone
              document={variantFilesInfo.artworkDocument}
              allowEdit={allowEdit}
              onUpload={(files) =>
                uploadFiles(files, variantFilesInfo.folderId)
              }
              isUploading={isUploading}
              uploadFailed={uploadFailed}
              fileUploads={fileUploads}
              abortFileUpload={abortFileUpload}
              onRemovefileOpen={onRemovefileOpen}
              onFileBrowserOpen={onFileBrowserOpen}
            />{' '}
          </Flex>
        )}
        {allowEdit && !variantFilesInfo.artworkDocumentId && (
          <FileDropzone
            isUploading={isUploading}
            uploadFailed={uploadFailed}
            fileUploads={fileUploads}
            abortFileUpload={abortFileUpload}
            onUpload={(files) => uploadFiles(files, variantFilesInfo.folderId)}
            onFileBrowserOpen={onFileBrowserOpen}
            validator={(file) => notImageValidator(file, t)}
          />
        )}
      </Flex>
      <VerifyDialogWithRequest
        headerTitle={t('fileBrowser.removeFile')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        primaryRequest={variantsApi.deleteArtworkDocument}
        args={[variantId]}
        isOpen={isRemovefileOpen}
        onClose={onRemovefileClose}
        onPerformed={() =>
          setVariantFilesInfo({
            ...variantFilesInfo,
            artworkDocumentId: undefined,
            artworkDocument: null,
          })
        }
        onSuccessTitle={t('fileBrowser.fileRemoved')}
        onFailureTitle={t('fileBrowser.fileRemoveFailed')}
      >
        {t('alert.areYouSure')}
      </VerifyDialogWithRequest>
      <FileBrowserModal
        rootFolderId={variantFilesInfo.folderId}
        mode="Select"
        isOpen={isFileBrowserOpen}
        onClose={onFileBrowserClose}
        onSelect={(file: FileLink) => {
          updateDocument(file);
          onFileBrowserClose();
        }}
        rejectExtensionFilter={imageExtensions}
      />
    </Flex>
  );
}
