import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const config = createMultiStyleConfigHelpers(inputAnatomy.keys);

const texas = config.definePartsStyle({
  field: {
    bg: 'texas.bg.800',
    px: 3,
    py: 5,
    borderBottom: '2px solid',
    borderBottomColor: 'gray.600',
    borderRadius: 'none',
    color: 'white',
    _placeholder: {
      color: 'gray.200',
    },
    transition: 'border-color 200ms ease',
    _focus: {
      borderBottomColor: 'texas.sand.100',
    },
    _light: {
      bg: 'gray.10',
      _placeholder: {
        color: 'gray.400',
      },
      borderBottomColor: 'gray.100',
      _focus: {
        borderBottomColor: 'texas.burntSienna.100',
      },
    },
  },
});

const baseStyle = config.definePartsStyle({
  field: {
    _disabled: {
      opacity: 0.8,
    },
    _light: { color: 'texas.bg.900' },
    _dark: {
      ':-webkit-autofill': {
        WebkitTextFillColor: 'white !important',
      },
      ':-webkit-autofill:hover': {
        WebkitTextFillColor: 'white !important',
      },
      ':-webkit-autofill:focus': {
        WebkitTextFillColor: 'white !important',
      },
    },
  },
  addon: {
    height: 'fit-content',
    bg: 'transparent',
    color: 'gray.200',
    _light: { color: 'gray.500' },
  },
  element: {
    color: 'gray.200',
    fontWeight: 'bold',
    _light: { color: 'gray.500' },
  },
});
export default config.defineMultiStyleConfig({
  defaultProps: { size: 'sm' },
  baseStyle,
  variants: {
    texas,
  },
});
