import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { productApprovalsApi } from '@texas/api/endpoints/productApprovalsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { Icons } from '@texas/components/shared/Icons';
import { Locale } from '@texas/i18n/types';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { RejectReasons } from './shared/RejectReasons';

export function ProductApprovalHistoryModal({
  productApprovalId,
  number,
}: {
  productApprovalId: number;
  number: string;
}) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Tooltip label={t('productApproval.viewChanges')}>
        <IconButton
          onClick={onOpen}
          variant="texas-outline-light"
          size="sm"
          icon={<Icons.History boxSize="4" />}
          aria-label={t('productApproval.history')}
        />
      </Tooltip>
      <Modal isCentered={true} size="3xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t('productApproval.historyPA', { number: number })}
          </ModalHeader>
          <ModalCloseButton />
          <ModalInner productApprovalId={productApprovalId} />
        </ModalContent>
      </Modal>
    </>
  );
}

function ModalInner({ productApprovalId }: { productApprovalId: number }) {
  const { t } = useTranslation();
  const {
    data: histories,
    refetch,
    loading,
    error,
  } = useApiResource(productApprovalsApi.getHistory);

  useEffect(() => {
    refetch(productApprovalId);
  }, [refetch, productApprovalId]);

  return (
    <ModalBody mb={6}>
      {loading && <Spinner />}
      {error && <ErrorDetails error={error} />}
      {histories && histories.length == 0 && (
        <Text>{t('productApproval.noHistory')}</Text>
      )}
      {!loading && histories && histories.length > 0 && (
        <TableContainer flexGrow={1}>
          <Table variant="configuration">
            <Thead>
              <Tr>
                <Th>{t('general.user')}</Th>
                <Th>{t('general.state')}</Th>
                <Th>{t('general.date')}</Th>
                <Th>{t('productApproval.rejectReason.reason')}</Th>
                <Th>{t('general.note')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {histories.map((x) => {
                return (
                  <Tr key={x.id}>
                    <Td>{x.user}</Td>
                    <Td>{x.approvalState}</Td>
                    <Td>{formatDate(Locale.En, x.date)}</Td>
                    <Td>
                      <RejectReasons reasons={x.rejectReasons} />
                    </Td>
                    <Td whiteSpace="normal">{x.note}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </ModalBody>
  );
}
