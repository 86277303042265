// @ngInject
const articleTagsResolve = (currentOrganization, ArticleTagsApiService) =>
  ArticleTagsApiService.getAllByOrganizationId(currentOrganization.id);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.articleTags', {
    url: '/articletags',
    controller: 'ArticleTagsConfigurationController as vm',
    templateUrl: 'main/configuration/articleTags/articleTags.tpl.html',
    data: { pageTitle: 'Article tags' },
    resolve: {
      articleTags: articleTagsResolve,
    },
  });
};

class ArticleTagsConfigurationController {
  constructor(
    $scope,
    DS,
    ArticleTagsApiService,
    currentOrganization,
    ConfigItemDialogService,
    articleTags,
    loadSpinnerService,
    toasterService,
    AuthService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.DS = DS;
    this.ArticleTagsApiService = ArticleTagsApiService;
    this.ConfigItemDialogService = ConfigItemDialogService;
    this.currentOrganization = currentOrganization;
    this.loadSpinnerService = loadSpinnerService;
    this.toasterService = toasterService;

    this.allowedToCreateNew = AuthService.hasAnyClaim(['SystemAdministrator']);
    this.articleTags = articleTags;
    this.noop = _.noop;
    this.query = {
      filter: '',
      limit: 50,
      page: 1,
    };

    this.setWatchers();
  }

  openArticleTag(articleTag) {
    this.ConfigItemDialogService.showDialog({
      configItem: articleTag,
      archivable: false,
      allowedToEdit: this.allowedToCreateNew,
      onSave: (articleTag) => {
        return this.ArticleTagsApiService.updateArticleTag(
          articleTag,
          articleTag.id,
        );
      },
    });
  }

  newArticleTag() {
    this.ConfigItemDialogService.showDialog({
      allowedToEdit: this.allowedToCreateNew,
      onSave: (articleTag) => {
        return this.ArticleTagsApiService.newArticleTag(
          articleTag,
          this.currentOrganization.id,
        );
      },
    });
  }

  deleteArticleTag(articleTag) {
    this.loadSpinnerService.start('mainSpinner');
    this.ArticleTagsApiService.deleteArticleTag(articleTag.id)
      .then(() => this.toasterService.success())
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  setWatchers() {
    this.$scope.$watch('vm.query.filter', () => {
      this.query.page = 1;
    });

    this.$scope.$watch(
      () => {
        return this.DS.lastModified('articleTag');
      },
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.articleTags = this.DS.filter('articleTag');
        }
      },
    );
  }
}

angular
  .module('configuration.articleTags', [])
  .config(config)
  .controller(
    'ArticleTagsConfigurationController',
    ArticleTagsConfigurationController,
  );
