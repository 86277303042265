import { reactEvents } from '../../bridge/reactEvents';

// @ngInject
const setupBridgeEvents = (
  AuthService,
  ExportArticleExcelDialogService,
  $state,
) => {
  reactEvents.excelExportClicked.subscribe((exportArticleEvent) => {
    AuthService.getUser().then((u) => {
      ExportArticleExcelDialogService.showDialog({
        selectedArticles: exportArticleEvent.articles,
        branchIds: exportArticleEvent.branchIds,
        organization: { id: u.organizationId },
        hideBranchIds: exportArticleEvent.hideBranchIds,
        selectedTemplate: exportArticleEvent.template,
      });
    });
  });

  reactEvents.routingChanged.subscribe((path) => {
    $state.go(path.to, path.navObj);
  });
};

angular.module('app.bridgeEvents', []).run(setupBridgeEvents);
