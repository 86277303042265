import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  CloseButton,
  Text,
} from '@chakra-ui/react';
import { FileRejection } from 'react-dropzone/.';
import { SharedDisclosureProps } from '../types';

export interface FileRejectionProps extends SharedDisclosureProps {
  fileRejections: FileRejection[] | undefined;
}

export function FileRejections({
  fileRejections,
  isOpen,
  onClose,
}: FileRejectionProps) {
  return (
    <>
      {isOpen &&
        fileRejections?.map((rejection) => (
          <Alert
            status="error"
            key={rejection.file.path}
            borderRadius="md"
            display="grid"
            gridTemplateColumns="auto minmax(0, 1fr)"
            mt={2}
            position="relative"
          >
            <AlertIcon />
            <Box textAlign="left">
              <AlertDescription>
                <Text fontWeight="medium">{rejection.file.name}</Text>
                {rejection.errors.map((e) => (
                  <Text key={e.code}>{e.message}</Text>
                ))}
              </AlertDescription>
            </Box>
            <CloseButton
              position="absolute"
              right={0}
              top={0}
              onClick={onClose}
              variant="ghost"
            />
          </Alert>
        ))}
    </>
  );
}
