class FilesTabsController {
  constructor($scope, $state, rootFolderId, isArticle) {
    'ngInject';

    this.rootFolderId = rootFolderId;
    this.isArticle = isArticle;
    this.folderPath = $state.params.folderPath;
    this.identifier = $state.params.identifier;

    $scope.$watch(
      () => $state.params.folderPath,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.folderPath = $state.params.folderPath;
        }
      },
    );

    $scope.$watch(
      () => $state.params.identifier,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.identifier = $state.params.identifier;
        }
      },
    );
  }
}

angular
  .module('tabs.files', [])
  .controller('FilesTabsController', FilesTabsController);
