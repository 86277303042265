import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorMode,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ConfirmCopy } from '../shared/group/ConfirmCopy';
import { useActiveContext } from '@texas/hooks/useActiveContext';
import { VersionsContext } from './VersionsGridOverview';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';
import { CompositionGroupOverview } from '@texas/api/endpoints/compositionGroupApi';
import { RefObject } from 'react';
import { menuButtonProps } from '../shared/props';
import { useCustomDroppable } from '@texas/components/shared/dragAndDrop/useCustomDroppable';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { droppableZoneStyles } from '@texas/components/shared/dragAndDrop/droppableZoneStyles';

export function NewVersion({
  option,
  gridRef,
}: {
  option: CompositionGroupOverview;
  gridRef: RefObject<HTMLDivElement>;
}) {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    createGroupRequest,
    copyCompositionGroupRequest,
    locationData,
    isCreating,
  } = useActiveContext(VersionsContext);
  const { colorMode } = useColorMode();

  const { setNodeRef, isOver, validDropZone, isDragging } = useCustomDroppable({
    id: `${locationData.current.supplierId}-${option.id}`,
    data: {
      location: locationData,
      optionId: option.id,
    },
    condition: (data) => {
      if (!data) return false;
      return data.optionId === option.id;
    },
    onDrop: () => {
      copyCompositionGroupRequest(option.id, option.id, undefined);
    },
  });

  return (
    <>
      <Menu placement="top">
        <MenuButton
          ref={setNodeRef}
          animation={fadeInScaleAnimation()}
          as={Button}
          isLoading={isCreating}
          border="2px solid"
          borderStyle="dashed"
          borderRadius="md"
          w="full"
          bg="texas.bg.950"
          borderColor="texas.bg.700"
          _hover={{
            borderColor: 'white',
            borderStyle: 'solid',
            _light: { borderColor: 'black' },
          }}
          _light={{
            borderColor: 'gray.300',
            bg: 'gray.80',
            color: 'black',
          }}
          {...menuButtonProps(
            gridRef,
            locationData.current.supplierId ?? 0,
            colorMode === 'dark',
          )}
          {...droppableZoneStyles(
            isDragging,
            validDropZone,
            isOver,
            colorMode === 'dark',
          )}
        >
          {validDropZone ? (
            isOver ? (
              <Text key="drop" animation={fadeInScaleAnimation()}>
                {t('dragAndDrop.dropToCopy')}
                <Icons.ContentCopy ml={1} boxSize={defaultIconSize} />
              </Text>
            ) : (
              <Text key="copy" animation={fadeInScaleAnimation()}>
                {t('dragAndDrop.dropHere')}
                <Icons.PackageVariant ml={1} boxSize={defaultIconSize} />
              </Text>
            )
          ) : isOver ? (
            t('dragAndDrop.canNotDropHere')
          ) : (
            t('composition.newSupplierVersion')
          )}
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => createGroupRequest(option.id)}>
            {t('composition.emptyVersion')}
          </MenuItem>
          <MenuItem onClick={onOpen}>
            {t('composition.copyFromOption', { nr: option.version })}
          </MenuItem>
        </MenuList>
      </Menu>
      <ConfirmCopy
        title={t('composition.copyFromOption', { nr: option.version })}
        isOpen={isOpen}
        onClose={onClose}
        compositionGroupId={option.id}
        onConfirm={(id) => {
          onClose();
          copyCompositionGroupRequest(id, option.id, undefined);
        }}
      />
    </>
  );
}
