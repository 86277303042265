import { reactEvents } from '../../../../bridge/reactEvents';

// @ngInject
const branchesResolve = (currentUser, BranchesApiService) =>
  BranchesApiService.getAllByOrganizationId(currentUser.organization.id);

// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.branches', {
    url: '/branches',
    controller: 'BranchesConfigurationController as vm',
    templateUrl: 'main/configuration/branches/branches.tpl.html',
    data: { pageTitle: 'Branches' },
    resolve: {
      branches: branchesResolve,
    },
  });
};

class BranchesConfigurationController {
  constructor(
    $scope,
    DS,
    currentUser,
    BranchDialogService,
    branches,
    AuthService,
  ) {
    'ngInject';

    this._BranchDialogService = BranchDialogService;
    this._currentUser = currentUser;
    this.branches = branches;
    this.noop = _.noop;
    this.query = {
      filter: '',
      order: 'displayOrder',
      limit: 50,
      page: 1,
    };
    this.isSysAdmin = AuthService.hasAnyClaim(['SystemAdministrator']);

    $scope.$watch('vm.query.filter', () => {
      this.query.page = 1;
    });

    $scope.$watch(
      () => {
        return DS.lastModified('branch');
      },
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.branches = currentUser.organization.branches;
        }
      },
    );

    this.unsubEvent = reactEvents.branchCreated.subscribe((branch) => {
      this.branches.push(branch);
      $scope.$digest();
    });

    $scope.$on('$destroy', () => {
      this.unsubEvent();
    });
  }

  openBranch(branch) {
    this._BranchDialogService.showDialog({
      branch,
      allowedToEdit: this.isSysAdmin,
    });
  }
}

angular
  .module('configuration.branches', [])
  .config(config)
  .controller(
    'BranchesConfigurationController',
    BranchesConfigurationController,
  );
