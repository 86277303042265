import { Flex, Heading, Text } from '@chakra-ui/react';
import { AngularNavLink } from '@texas/components/shared/AngularNavLink';
import { BodyOverride } from '@texas/components/shared/BodyOverride';
import { fadeInRightAnimation } from '@texas/resources/animations/animations';
import React from 'react';
import sales from '@assets/article/sales.webp';
import purchase from '@assets/article/purchase.webp';
import garp from '@assets/article/garp.webp';
import suppliers from '@assets/article/suppliers.webp';
import options from '@assets/article/options.webp';
import versions from '@assets/article/versions.webp';
import matrix from '@assets/article/matrix.webp';
import { useTranslation } from 'react-i18next';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { nodeGroupIsPopulated } from '@texas/utils/helpers/nodeGroupHelpers';

export function Overview({
  articleNodeGroup,
}: {
  articleNodeGroup: ArticleNodeGroup;
}) {
  const { t } = useTranslation();
  return (
    <BodyOverride>
      <Flex gap={2} align="center" justify="center" pt={4} flexWrap="wrap">
        <AngularNavLink to="variant.options">
          <Card imgUrl={options}>
            <Heading>{t('variant.overview.options.title')}</Heading>
            <Text>{t('variant.overview.options.desc')}</Text>
          </Card>
        </AngularNavLink>
        <AngularNavLink to="variant.suppliers">
          <Card imgUrl={suppliers}>
            <Heading>{t('variant.overview.suppliers.title')}</Heading>
            <Text>{t('variant.overview.suppliers.desc')}</Text>
          </Card>
        </AngularNavLink>
        <AngularNavLink to="variant.versions">
          <Card imgUrl={versions}>
            <Heading>{t('variant.overview.versions.title')}</Heading>
            <Text>{t('variant.overview.versions.desc')}</Text>
          </Card>
        </AngularNavLink>
        <AngularNavLink to="variant.purchase">
          <Card imgUrl={purchase}>
            <Heading>{t('variant.overview.purchase.title')}</Heading>
            <Text>{t('variant.overview.purchase.desc')}</Text>
          </Card>
        </AngularNavLink>
        <AngularNavLink to="variant.sales">
          <Card imgUrl={sales}>
            <Heading>{t('variant.overview.sales.title')}</Heading>
            <Text>{t('variant.overview.sales.desc')}</Text>
          </Card>
        </AngularNavLink>
        {nodeGroupIsPopulated(articleNodeGroup) && (
          <>
            <AngularNavLink to="variant.matrix">
              <Card imgUrl={matrix}>
                <Heading>{t('variant.overview.matrix.title')}</Heading>
                <Text>{t('variant.overview.matrix.desc')}</Text>
              </Card>
            </AngularNavLink>
          </>
        )}
        <AngularNavLink to="variant.general.garp">
          <Card imgUrl={garp}>
            <Heading>{t('variant.overview.garp.title')}</Heading>
            <Text>{t('variant.overview.garp.desc')}</Text>
          </Card>
        </AngularNavLink>
      </Flex>
    </BodyOverride>
  );
}

function Card({
  imgUrl,
  children,
}: {
  imgUrl: string;
  children: React.ReactNode;
}) {
  return (
    <Flex
      animation={fadeInRightAnimation()}
      _light={{
        background: `linear-gradient(to top, #FFFFFF90 20%, transparent), url(${imgUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
      height="400px"
      width="260px"
      flexDir="column"
      bg="texas.bg.800"
      padding={4}
      borderRadius="lg"
      justify="end"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundSize="cover"
      background={`linear-gradient(to top, #00000090 20%, transparent), url(${imgUrl})`}
      transition="transform 500ms ease, filter 400ms ease"
      filter="grayscale(0.3)"
      _hover={{
        transform: 'scale(1.01)',
        filter: 'grayscale(0)',
      }}
    >
      {children}
    </Flex>
  );
}
