// @ngInject
const CountriesApiService = (DS, $q, ResourcesService) => ({
  getAllByBranchId(branchId) {
    const deferred = $q.defer();

    DS.findAll('country', null, {
      bypassCache: true,
      endpoint: `metadata/countries/branch/${branchId}`,
    }).then(
      (response) => {
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  newCountry(country, branchId) {
    const deferred = $q.defer();

    DS.create('country', country, {
      endpoint: `metadata/countries/branch/${branchId}`,
    }).then(
      (response) => {
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  updateCountry(data, countryId) {
    const deferred = $q.defer();

    DS.update('country', countryId, data, {
      cacheResponse: false,
      params: { branchId: false },
    }).then(
      (response) => {
        const country = DS.get('country', countryId);
        country.name = response.name;
        country.code = response.code;
        country.archived = response.archived;
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  getAllFromCache(branchId) {
    return DS.filter('country', { branchId });
  },
});

angular
  .module('resources.countries', [])
  .factory('CountriesApiService', CountriesApiService);
