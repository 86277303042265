import { useSyncExternalStore } from 'react';
import { createPortal } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { getElements, subscribe } from '../bridge/bridgeManager';
import {
  ChakraProvider,
  extendTheme,
  Box,
  ThemeConfig,
  ColorModeScript,
} from '@chakra-ui/react';
import { customTheme } from './resources/theme/theme';
import './i18n/config';
import { CustomFonts } from './resources/fonts';
import { HashRouter } from 'react-router-dom';
import { AutoUpdateEvents } from './components/shared/autoUpdate/AutoUpdateEvents';
import { SessionStatus } from './api/endpoints/sessionApi';
import { HttpStatus } from './api/httpClient/types';
import { ErrorDetails } from './components/shared/alert/ErrorDetails';
import {
  useSessionState,
  SessionStateProvider,
  useSession,
} from './hooks/useSession';
import { LoadingSessionView } from './LoadingSessionView';

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const theme = extendTheme({ config }, customTheme);

function Root() {
  const session = useSessionState();

  return (
    <SessionStateProvider value={session}>
      <HashRouter>
        <AutoUpdateEvents>
          <ChakraProvider theme={theme}>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <CustomFonts />
            <BridgeElements />
          </ChakraProvider>
        </AutoUpdateEvents>
      </HashRouter>
    </SessionStateProvider>
  );
}

function BridgeElements() {
  const elements = useSyncExternalStore(subscribe, getElements);
  const { sessionState } = useSession();

  if (sessionState.status === SessionStatus.Inactive) {
    return null;
  }

  if (sessionState.status === SessionStatus.Fetching) {
    return <LoadingSessionView />;
  }

  if (
    sessionState.status === SessionStatus.Error &&
    sessionState.error.status !== HttpStatus.Unauthorized
  ) {
    return <ErrorDetails error={sessionState.error} />;
  }

  return (
    <>
      {elements.map((e) =>
        createPortal(
          <Box color="chakra-body-text">{e.element}</Box>,
          e.container,
        ),
      )}
    </>
  );
}

export function renderReactRoot(container: Element) {
  const root = createRoot(container);
  root.render(<Root />);
}
