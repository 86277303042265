import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Button,
  ModalFooter,
  Flex,
  ModalBody,
  useToast,
  FormControl,
  FormLabel,
  Text,
  Input,
  Switch,
  Box,
} from '@chakra-ui/react';
import { SubmitButton } from '../shared/form/SubmitButton';
import { useTranslation } from 'react-i18next';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { branchesApi } from '@texas/api/endpoints/branchesApi';
import { countriesApi } from '@texas/api/endpoints/metadata/countriesApi';
import { CreateUserRequest, Role, userApi } from '@texas/api/endpoints/userApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { request } from '@texas/utils/helpers/httpHelpers';
import { ReactSelectOption } from '@texas/types';
import { ErrorLabel } from '../shared/ErrorLabel';
import { TexasSelect } from '../shared/form/TexasSelect';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';
import { defaultIconSize, Icons } from '../shared/Icons';
import { ErrorDetails } from '../shared/alert/ErrorDetails';
import { internalRole } from '@texas/utils/helpers/claimHelpers';
import { userEvents } from './events';
import { RoleSelector } from './role/RoleSelector';
import { RoleRestrict } from '../shared/RoleRestrict';

export function CreateUser() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation();
  return (
    <RoleRestrict allow={[Role.SystemAdministrator]}>
      <Button variant="texas-solid" onClick={onOpen}>
        {t('user.createUser')}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('user.createUser')}</ModalHeader>
          <ModalCloseButton />
          <InnerModal onClose={onClose} />
        </ModalContent>
      </Modal>
    </RoleRestrict>
  );
}

function InnerModal({ onClose }: { onClose: () => void }) {
  const toast = useToast();
  const { t } = useTranslation();

  const {
    data: branches,
    refetch: refetchBranches,
    loading: branchesLoading,
  } = useApiResource(branchesApi.getBranches);
  const {
    data: countries,
    refetch: refetchCountries,
    loading: countriesLoading,
    set: setCountries,
  } = useApiResource(countriesApi.getCountries);

  const { request: createRequest, error } = useApiRequest(userApi.createUser);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting, isValid },
    reset,
    watch,
    setValue,
  } = useForm<CreateUserRequest>();

  useEffect(() => {
    refetchBranches();
  }, [refetchBranches]);

  const branchId = watch('branchId');
  const role = watch('role');

  useEffect(() => {
    setValue('countryId', undefined);
    if (!branchId) {
      setCountries([]);
      return;
    }
    refetchCountries(branchId);
  }, [branchId, refetchCountries, setCountries, setValue]);

  const onSubmit = async (data: CreateUserRequest) =>
    await request(createRequest, [data], (_) => {
      toast({
        title: t('general.created'),
        status: 'success',
        isClosable: true,
      });
      reset();
      onClose();
      userEvents.newUserCreated.dispatch();
    });

  return (
    <>
      {error && <ErrorDetails error={error} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody as={Flex} gap={2} flexDir="column">
          <Flex gap={2}>
            <FormControl isInvalid={!!errors.email?.message}>
              <FormLabel>{t('general.email')}</FormLabel>
              <Input
                type="email"
                {...register('email', { required: true })}
                variant="texas"
              />
              <ErrorLabel text={errors.email?.message} />
            </FormControl>
            <FormControl isInvalid={!!errors.name?.message}>
              <FormLabel>{t('general.name')}</FormLabel>
              <Input
                {...register('name', { required: true })}
                variant="texas"
              />
              <ErrorLabel text={errors.name?.message} />
            </FormControl>
          </Flex>
          <Box>
            <Text fontSize="md" fontWeight="bold">
              {t('user.sendWelcomeEmail')}
            </Text>
            <Text fontSize="sm" color="gray.200" _light={{ color: 'gray.600' }}>
              {t('user.sendWelcomeMailDesc')}
            </Text>
            <FormControl display="flex" alignItems="center" pt={2} gap={2}>
              <Controller
                name="sendWelcomeMail"
                control={control}
                render={({ field }) => (
                  <Switch
                    isChecked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
              <FormLabel mb="0">{t('user.sendWelcomeMailToggle')}</FormLabel>
            </FormControl>
          </Box>
        </ModalBody>
        <ModalBody bg="texas.bg.950" py={4} my={4} _light={{ bg: 'gray.10' }}>
          <FormControl>
            <FormLabel pb={4}>{t('user.whatKindOfUserCreating')}</FormLabel>
            <Controller
              control={control}
              rules={{ required: true }}
              name="role"
              render={({ field }) => <RoleSelector {...field} />}
            />
            <ErrorLabel text={errors.role?.message} />
          </FormControl>
        </ModalBody>
        <ModalBody mb={4}>
          <Flex gap={2} flexDir="column">
            {internalRole(convertToEnum(Role, role)) && (
              <>
                <Flex gap={2}>
                  <Icons.AlertCircle boxSize={defaultIconSize} />
                  <Flex flexDir="column" gap={1}>
                    <Text fontWeight="bold">
                      {t('user.createInternalInfo')}
                    </Text>
                    <Text fontSize="sm">
                      {t('user.internalAdditionalInfo')}
                    </Text>
                  </Flex>
                </Flex>
                <Flex gap={2}>
                  <FormControl>
                    <FormLabel>{t('user.belongsToBranch')}</FormLabel>
                    <Controller
                      name="branchId"
                      control={control}
                      render={({ field }) => (
                        <TexasSelect
                          {...field}
                          value={branches
                            ?.map((x) => ({ label: x.name, value: x.id }))
                            .find((x) => x.value === field.value)}
                          onChange={(value) =>
                            field.onChange(value?.value ?? null)
                          }
                          isClearable={true}
                          isLoading={branchesLoading}
                          options={
                            branches?.map<ReactSelectOption>((u) => ({
                              label: u.name,
                              value: u.id,
                            })) ?? []
                          }
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>{t('user.operatesInCountry')}</FormLabel>
                    <Controller
                      name="countryId"
                      control={control}
                      render={({ field }) => (
                        <TexasSelect
                          {...field}
                          value={
                            field.value
                              ? countries
                                  ?.map((x) => ({ label: x.name, value: x.id }))
                                  .find((x) => x.value === field.value)
                              : null
                          }
                          onChange={(value) =>
                            field.onChange(value?.value ?? null)
                          }
                          isClearable={true}
                          isLoading={countriesLoading}
                          options={
                            countries?.map<ReactSelectOption>((u) => ({
                              label: u.name,
                              value: u.id,
                            })) ?? []
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Flex>
                <FormControl display="flex" alignItems="center" pt={2} gap={2}>
                  <Controller
                    name="emailNotifications"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        isChecked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  <FormLabel mb="0">{t('user.emailNotifications')}</FormLabel>
                </FormControl>
              </>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter
          bg="texas.bg.950"
          borderBottomRadius="md"
          as={Flex}
          gap={6}
          alignItems="end"
          _light={{ bg: 'gray.50' }}
        >
          <FormControl
            opacity={internalRole(convertToEnum(Role, role)) ? 1 : 0}
            transition="opacity 100ms linear"
          >
            <FormLabel>
              <Flex gap={2}>
                <Icons.ShieldKey boxSize={defaultIconSize} />
                <Text>{t('user.passwordRequiredCreate')}</Text>
              </Flex>
            </FormLabel>
            <Input
              type="password"
              placeholder={t('user.enterYourPassword')}
              {...register('providedPassword')}
              variant="texas"
            />
          </FormControl>
          <SubmitButton disabled={!isValid} loading={isSubmitting}>
            {t('user.createUser')}
          </SubmitButton>
        </ModalFooter>
      </form>
    </>
  );
}
