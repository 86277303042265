import { Role } from '@texas/api/endpoints/userApi';
import { RoleRadioCard } from './RoleRadioCard';
import { RoleRadioGroup } from './RoleRadioGroup';
import React from 'react';

interface Props {
  onChange: (value: Role) => void;
  value: Role;
}

export const RoleSelector = React.forwardRef(function RoleSelector(
  { onChange, value }: Props,
  ref,
) {
  return (
    <RoleRadioGroup
      ref={ref}
      onChange={onChange}
      value={value}
      options={[
        {
          component: (
            <RoleRadioCard
              role={Role.InternalAdministrator}
              delayMultiplier={1}
            />
          ),
          value: Role.InternalAdministrator,
        },
        {
          component: (
            <RoleRadioCard role={Role.InternalAgent} delayMultiplier={2} />
          ),
          value: Role.InternalAgent,
        },
        {
          component: <RoleRadioCard role={Role.Supplier} delayMultiplier={3} />,
          value: Role.Supplier,
        },
        {
          component: <RoleRadioCard role={Role.Brand} delayMultiplier={4} />,
          value: Role.Brand,
        },
      ]}
    />
  );
});
