import {
  userApi,
  UserListItem,
  UserSearchQuery,
} from '@texas/api/endpoints/userApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BodyOverride } from '../shared/BodyOverride';
import { VStack, Flex, Box, useToast } from '@chakra-ui/react';
import { SearchFilterInput } from '../SearchFilterInput';
import { DataTableContainer } from '../shared/dataTable/DataTableContainer';
import { TexasSwitch } from '../shared/form/TexasSwitch';
import { useTranslation } from 'react-i18next';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { ArchiveUser } from './ArchiveUser';
import { EditUser } from './EditUser';
import { usersColumns } from './Columns';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ServerError } from '@texas/types';
import { CreateUser } from './CreateUser';
import { Container } from '../shared/Container';
import { userEvents } from './events';
import { SelectRole } from './SelectRole';
import { VerifyDialogWithRequest } from '../shared/dialog/VerifyDialogWithRequest';

const defaultSearchQuery: UserSearchQuery = {
  sortBy: 'code',
  sortDesc: false,
  searchTerm: '',
  includeArchived: false,
  role: null,
  page: 1,
  pageSize: 24,
};

export function Users() {
  const { data, refetch, loading, error } = useApiResource(userApi.searchUsers);
  const toast = useToast();
  const { t } = useTranslation();
  const {
    value: archiveValue,
    isOpen: isArchiveOpen,
    onOpen: onArchiveOpen,
    onClose: onArchiveClose,
  } = useValueDisclosure<UserListItem>();
  const {
    value: editValue,
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useValueDisclosure<number>();
  const {
    value: roleEdit,
    isOpen: isRoleEditOpen,
    onOpen: onRoleEditOpen,
    onClose: onRoleEditClose,
  } = useValueDisclosure<number>();
  const {
    value: sendWelcomeEmail,
    isOpen: isSendWelcomeEmailOpen,
    onOpen: onSendWelcomeEmailOpen,
    onClose: onSendWelcomeEmailClose,
  } = useValueDisclosure<number>();

  const logoutDevices = useCallback(
    async (id: number) => {
      const response = await userApi.signOutDevices(id);

      if (response.hasError) {
        toast({
          title: t('auth.deviceLogout.failed'),
          description: response.error.message,
          status: 'error',
          isClosable: true,
        });
        return;
      }

      toast({
        title: t('auth.deviceLogout.titleSuccess'),
        description: t('auth.deviceLogout.descSuccess'),
        status: 'success',
        isClosable: true,
      });
    },
    [t, toast],
  );

  const [searchQuery, setSearchQuery] =
    useState<UserSearchQuery>(defaultSearchQuery);

  const refetchCallback = useCallback(
    () => refetch(searchQuery),
    [refetch, searchQuery],
  );

  useEffect(() => {
    refetch(searchQuery);
  }, [refetch, searchQuery]);

  useEffect(() => {
    const event1 = userEvents.newUserCreated.subscribe(() => {
      refetchCallback();
    });
    const event2 = userEvents.userUpdated.subscribe(() => {
      refetchCallback();
    });
    const event3 = userEvents.currentUserUpdated.subscribe(() => {
      refetchCallback();
    });

    return () => {
      event1();
      event2();
      event3();
    };
  }, [refetchCallback]);

  const { request: restureUserRequest } = useApiRequest(userApi.restore);

  const performRestore = useCallback(
    async (id: number) => {
      await request(
        restureUserRequest,
        [id],
        () => {
          toast({
            title: t('general.restored'),
            status: 'success',
            isClosable: true,
          });
          refetch(searchQuery);
        },
        (error: ServerError) => {
          toast({
            title: t('general.restoreFailed'),
            description: error.message,
            status: 'error',
            isClosable: true,
          });
        },
      );
    },
    [restureUserRequest, toast, t, refetch, searchQuery],
  );

  const columns = useMemo(() => {
    return usersColumns(
      t,
      onArchiveOpen,
      performRestore,
      onEditOpen,
      onRoleEditOpen,
      onSendWelcomeEmailOpen,
      logoutDevices,
    );
  }, [
    t,
    onArchiveOpen,
    performRestore,
    onEditOpen,
    onRoleEditOpen,
    onSendWelcomeEmailOpen,
    logoutDevices,
  ]);

  return (
    <BodyOverride>
      <Container title={t('general.users')}>
        {archiveValue && (
          <ArchiveUser
            user={archiveValue}
            isOpen={isArchiveOpen}
            onClose={onArchiveClose}
            onArchive={() => {
              refetch(searchQuery);
            }}
          />
        )}

        {editValue && (
          <EditUser id={editValue} onClose={onEditClose} isOpen={isEditOpen} />
        )}
        {roleEdit && (
          <SelectRole
            id={roleEdit}
            onClose={onRoleEditClose}
            isOpen={isRoleEditOpen}
          />
        )}
        {sendWelcomeEmail && (
          <VerifyDialogWithRequest
            headerTitle={t('user.sendWelcomeEmail')}
            secondaryButtonTitle="Close"
            primaryButtonTitle={t('general.sendEmail')}
            primaryRequest={userApi.sendWelcomeMail}
            args={[sendWelcomeEmail]}
            onSuccessTitle={t('general.emailSent')}
            onFailureTitle={t('general.failedToSendEmail')}
            isOpen={isSendWelcomeEmailOpen}
            onClose={onSendWelcomeEmailClose}
          >
            {t('user.verifySendWelcomeEmail')}
          </VerifyDialogWithRequest>
        )}

        <VStack w="100%" alignItems="start" p={4}>
          <Flex justify="space-between" w="100%">
            <Flex direction="column" gap={4}>
              <Flex gap={4} align="flex-end">
                <Box height="fit-content" mt="auto" minWidth={80}>
                  <SearchFilterInput
                    value={searchQuery.searchTerm}
                    onChange={(s) =>
                      setSearchQuery({ ...searchQuery, searchTerm: s })
                    }
                  />
                </Box>
              </Flex>
              <TexasSwitch
                checked={searchQuery.includeArchived}
                label={t('general.includeArchived')}
                onChange={(checked) =>
                  setSearchQuery({
                    ...searchQuery,
                    includeArchived: checked,
                  })
                }
              />
            </Flex>
            <CreateUser />
          </Flex>
          <DataTableContainer
            w="100%"
            datatable={{
              data: data?.items ?? [],
              sorted: {
                onSortedChange: ({ key, desc }) => {
                  setSearchQuery({
                    ...searchQuery,
                    sortBy: key,
                    sortDesc: desc,
                  });
                },
                key: searchQuery.sortBy,
                desc: searchQuery.sortDesc,
              },
              isLoading: loading,
              columns: columns,
              variant: 'configuration',
            }}
            error={error}
            pagination={{
              totalItems: data?.totalItems ?? 0,
              pageSize: searchQuery.pageSize,
              currentPage: searchQuery.page,
              onPageChange: (page) =>
                setSearchQuery({
                  ...searchQuery,
                  page,
                }),
            }}
          />
        </VStack>
      </Container>
    </BodyOverride>
  );
}
