import { WidgetType } from '../widgets/shared/types';
import { WidgetLayout } from '../widgets/useWidgetLayout';
import { defaultCustomerWidgetLayouts } from './defaultCustomerWidgetLayouts';

export const defaultCustomerWidgetsLayout: WidgetLayout = {
  layout: {
    lg: [
      {
        ...defaultCustomerWidgetLayouts.articles.lg,
        i: '0',
      },
      {
        ...defaultCustomerWidgetLayouts.articlesCount.lg,
        y: 0,
        x: 0,
        i: 'countWidget1',
      },
      {
        ...defaultCustomerWidgetLayouts.articlesCount.lg,
        y: 0,
        x: 3,
        i: 'countWidget3',
      },
      {
        ...defaultCustomerWidgetLayouts.articlesCount.lg,
        y: 0,
        x: 6,
        i: 'countWidget2',
      },
    ],
  },
  widgets: [
    {
      type: WidgetType.Articles,
      id: '0',
      layoutIndex: '0',
      name: WidgetType.Articles,
      categoryColor: null,
    },
    {
      type: WidgetType.ArticlesCount,
      id: 'countWidget1',
      layoutIndex: 'countWidget1',
      name: WidgetType.ArticlesCount,
      categoryColor: null,
    },
    {
      type: WidgetType.ArticlesCount,
      id: 'countWidget2',
      layoutIndex: 'countWidget2',
      name: WidgetType.ArticlesCount,
      categoryColor: null,
    },
    {
      type: WidgetType.ArticlesCount,
      id: 'countWidget3',
      layoutIndex: 'countWidget3',
      name: WidgetType.ArticlesCount,
      categoryColor: null,
    },
  ],
};
