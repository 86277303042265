import { Flex, Box, Text, Image } from '@chakra-ui/react';
import { fadeInLeftAnimation } from '@texas/resources/animations/animations';
import rhLogo from '@texas/assets/rh_logo.svg';
import { useTranslation } from 'react-i18next';

export function Slogan() {
  const { t } = useTranslation();
  return (
    <Flex animation={fadeInLeftAnimation(500)}>
      <Image src={rhLogo} />
      <Box w={0.5} bg="white" mx={4} />
      <Flex alignSelf="center" flexDir="column">
        <Text
          sx={{
            animationFillMode: 'forwards',
          }}
          opacity={0}
          animation={fadeInLeftAnimation(800, 400)}
          mb="-8px"
          fontSize="x-large"
          fontWeight="bold"
          color="white"
        >
          {t('slogan.texas')}
        </Text>
        <Text
          sx={{
            animationFillMode: 'forwards',
          }}
          opacity={0}
          animation={fadeInLeftAnimation(1000, 1000)}
          color="gray.400"
        >
          {t('slogan.text')}
        </Text>
      </Flex>
    </Flex>
  );
}
