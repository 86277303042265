// @ngInject
const MatrixNodeDialogService = ($mdDialog, $rootScope) => ({
  showDialog: ({
    organization = $rootScope.currentUser.organization || null,
    matrixNode = null,
    allowedToEdit = false,
  }) =>
    $mdDialog.show({
      clickOutsideToClose: true,
      controller: 'MatrixNodeDialogController as vm',
      templateUrl:
        'services/dialogs/matrixNodeDialog/matrixNodeDialog.tpl.html',
      locals: { organization, matrixNode, allowedToEdit },
    }),
});

class MatrixNodeDialogController {
  constructor(
    $mdDialog,
    $mdEditDialog,
    HelperService,
    MatrixNodesApiService,
    AuthService,
    matrixNodeService,
    toasterService,
    matrixNode,
    organization,
    allowedToEdit,
  ) {
    'ngInject';

    this.$mdDialog = $mdDialog;
    this.$mdEditDialog = $mdEditDialog;
    this.HelperService = HelperService;
    this.MatrixNodesApiService = MatrixNodesApiService;
    this.matrixNodeService = matrixNodeService;
    this.toasterService = toasterService;
    this.organization = organization;
    this.allowedToEdit = allowedToEdit;
    this.allowedToEditNodeValues = AuthService.hasAnyClaim([
      'SystemAdministrator',
    ]);

    this.validationErrors = [];
    this.newMatrixNodeValue = {};
    this.editMode = matrixNode !== null;
    this.allowedAxes = ['X', 'Y'];
    this.filterValues = (value) => !value.archived;
    this.query = {
      order: 'displayOrder',
      limit: 15,
      page: 1,
    };

    if (!this.editMode) {
      matrixNode = { allowedAxis: this.allowedAxes[0] };
    }

    this.originalMatrixNode = matrixNode;
    this.matrixNode = _.extend({}, matrixNode);
    this.matrixNode.matrixNodeValues = _.map(
      matrixNode.matrixNodeValues,
      _.clone,
    );
  }

  save() {
    this.validationErrors = [];

    if (this.editMode) {
      const fields = [
        'name',
        'displayOrder',
        'valueMinLength',
        'valueMaxLength',
        'matrixNodeValues',
        'archived',
      ];
      const data = this.HelperService.getChangedData(
        this.originalMatrixNode,
        this.matrixNode,
        fields,
      );

      // If changes are found, update them
      if (!_.isEmpty(data)) {
        this.$mdDialog.startLoadingSpinner();

        if (data.valueMinLength === '' || data.valueMinLength === null) {
          data.hasValueMinLength = false;
          delete data.valueMinLength;
        }

        if (data.valueMaxLength === '' || data.valueMaxLength === null) {
          data.hasValueMaxLength = false;
          delete data.valueMaxLength;
        }

        if (
          angular.isDefined(data.valueMaxLength) ||
          angular.isDefined(data.valueMinLength)
        ) {
          if (
            !angular.isDefined(data.matrixNodeValues) &&
            angular.isDefined(this.matrixNode.matrixNodeValues)
          ) {
            data.matrixNodeValues = this.matrixNode.matrixNodeValues;
          }
        }
        if (angular.isDefined(data.matrixNodeValues)) {
          if (
            !angular.isDefined(data.valueMaxLength) &&
            angular.isDefined(this.matrixNode.valueMaxLength)
          ) {
            data.valueMaxLength = this.matrixNode.valueMaxLength;
          }
          if (
            !angular.isDefined(data.valueMinLength) &&
            angular.isDefined(this.matrixNode.valueMinLength)
          ) {
            data.valueMinLength = this.matrixNode.valueMinLength;
          }
        }

        this.MatrixNodesApiService.updateMatrixNode(
          data,
          this.originalMatrixNode.id,
        )
          .then(
            () => {
              this.toasterService.success();
              this.$mdDialog.hide();
            },
            (error) => (this.validationErrors = error.errors),
          )
          .finally(() => this.$mdDialog.stopLoadingSpinner());
      } else {
        this.$mdDialog.hide();
      }
    } else {
      this.$mdDialog.startLoadingSpinner();
      this.MatrixNodesApiService.newMatrixNode(
        this.matrixNode,
        this.organization.id,
      )
        .then(
          (newNode) => {
            this.toasterService.success();
            this.$mdDialog.hide(newNode);
          },
          (error) => (this.validationErrors = error.errors),
        )
        .finally(() => this.$mdDialog.stopLoadingSpinner());
    }
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  archive() {
    this.matrixNode.archived = !this.matrixNode.archived;
    this.save();
  }

  validateInput(value) {
    this.matrixNodeService.validateInput(
      this.originalMatrixNode.matrixNodeValues,
      value,
    );
  }

  keyDown($event, value) {
    this.matrixNodeService.keyDown(
      $event,
      this.originalMatrixNode.matrixNodeValues,
      value,
    );
  }

  editNodeValueName(value) {
    if (this.allowedToEditNodeValues) {
      value.$edit = true;
    }
  }
}

angular
  .module('services.dialogs.matrixNodeDialog', [])
  .service('MatrixNodeDialogService', MatrixNodeDialogService)
  .controller('MatrixNodeDialogController', MatrixNodeDialogController);
