// @ngInject
const UsersApiService = (DS, DSHttpAdapter, $q, ResourcesService) => ({
  getSelf() {
    const deferred = $q.defer();

    DSHttpAdapter.GET(`${DS.defaults.basePath}users/me`).then(
      (response) => {
        const user = DS.inject('user', response.data);
        if (user.organizationId) {
          DS.loadRelations('user', user, ['organization']).then(
            (user) => {
              deferred.resolve(user);
            },
            (error) => {
              deferred.reject(ResourcesService.handleError(error));
            },
          );
        } else {
          deferred.resolve(user);
        }
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  getById(userId) {
    const deferred = $q.defer();

    DS.find('user', userId, { params: { organizationId: false } }).then(
      (response) => {
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  getAllByOrganizationId(organizationId) {
    const deferred = $q.defer();

    DS.findAll('user', null, {
      useFilter: true,
      params: { organizationId },
    }).then(
      (response) => {
        deferred.resolve(response);
      },
      (error) => {
        deferred.reject(ResourcesService.handleError(error));
      },
    );

    return deferred.promise;
  },
  getAllFromCache() {
    return DS.getAll('user');
  },
});

angular
  .module('resources.users', [])
  .factory('UsersApiService', UsersApiService);
