import {
  Box,
  Button,
  Flex,
  FormControl,
  IconButton,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Textarea,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  productApprovalsApi,
  ProductApprovalState,
  ChangeStateRequest,
} from '@texas/api/endpoints/productApprovalsApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { fadeInLeftAnimation } from '@texas/resources/animations/animations';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProductApprovalInfo } from './shared/ProductApprovalInfo';
import { AutoGrowTextareaContainer } from '@texas/components/shared/form/AutoGrowTextareaContainer';
import { TexasFormLabel } from '@texas/components/shared/form/TexasFormLabel';
import { VerifyButton } from '@texas/components/shared/verifyButton/VerifyButton';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { FormCounter } from '@texas/components/shared/form/FormCounter';

export function RevertToPendingModal({
  productApprovalId,
  onRevert,
}: {
  productApprovalId: number;
  onRevert: () => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  return (
    <>
      <Tooltip label={t('productApproval.revertToPending')}>
        <IconButton
          variant="texas-light"
          onClick={onOpen}
          size="sm"
          icon={<Icons.Undo boxSize="4" />}
          aria-label={t('productApproval.revertToPending')}
        />
      </Tooltip>
      <Modal isCentered={true} size="6xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalInner
            onRevert={onRevert}
            productApprovalId={productApprovalId}
            onClose={onClose}
          />
        </ModalContent>
      </Modal>
    </>
  );
}

interface ModalInnerProps {
  productApprovalId: number;
  onRevert: () => void;
  onClose: () => void;
}

function ModalInner({ productApprovalId, onClose, onRevert }: ModalInnerProps) {
  const { t } = useTranslation();
  const toast = useToast();

  const {
    data: productApproval,
    refetch: refetchProductApproval,
    error: productApprovalError,
    loading: productApprovalLoading,
  } = useApiResource(productApprovalsApi.get);

  const {
    request: revertRequest,
    loading,
    error,
  } = useApiRequest(productApprovalsApi.setAsPending);

  const {
    watch,
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<ChangeStateRequest>({ defaultValues: { note: '' } });

  const watchNote = watch('note');

  const onSubmit = async (data: ChangeStateRequest) =>
    await request(
      revertRequest,
      [productApprovalId, data],
      () => {
        toast({
          title: t('productApproval.reverted'),
          status: 'success',
          isClosable: true,
        });
        onRevert();
        onClose();
      },
      (error: ServerError) => {
        toast({
          title: t('productApproval.failedToSetAsPending'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  useEffect(() => {
    refetchProductApproval(productApprovalId);
  }, [productApprovalId, refetchProductApproval]);

  return (
    <>
      <ModalHeader
        borderBottom="1px solid"
        borderColor="texas.bg.700"
        _light={{ borderColor: 'gray.50', color: 'black' }}
      >
        {t('productApproval.revertToPending')}
      </ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        {productApprovalError && <ErrorDetails error={productApprovalError} />}
        {error && <ErrorDetails error={error} />}

        <Flex>
          <Box
            minW="50%"
            maxW="50%"
            p={4}
            bg="texas.bg.700"
            _light={{ bg: 'gray.50' }}
          >
            {productApprovalLoading && <Spinner />}
            {productApproval && (
              <>
                <Flex
                  animation={fadeInLeftAnimation(600)}
                  alignItems="center"
                  gap={1}
                >
                  <Text pb={2} fontFamily="Barlow" fontSize="xl">
                    {t('productApproval.thisWillBeReverted')}
                  </Text>
                  <Icons.ArrowDownRight boxSize="4" />
                </Flex>
                <ProductApprovalInfo
                  pa={productApproval}
                  state={ProductApprovalState.Pending}
                />
              </>
            )}
          </Box>

          <Flex flexDir="column" py="12" flexGrow={1} p={4} gap={2}>
            <FormControl>
              <TexasFormLabel>{t('productApproval.reason')}</TexasFormLabel>
              <AutoGrowTextareaContainer value={watch('note')}>
                <Textarea
                  {...register('note', {
                    maxLength: {
                      value: 200,
                      message: t('errors.maxLength', { count: 200 }),
                    },
                  })}
                  minH="28"
                  height="auto"
                  padding={1}
                  width="100%"
                  borderRadius={3}
                  borderColor="gray"
                  _hover={{ borderColor: 'hsl(0, 0%, 70%)' }}
                />
              </AutoGrowTextareaContainer>
              <FormCounter length={watchNote.length} maxLength={200} />
              <ErrorLabel text={errors.note?.message} />
            </FormControl>
            <Flex ml="auto" mt="auto">
              <Button mr={3} onClick={onClose}>
                {t('general.close')}
              </Button>
              <VerifyButton
                buttonProps={{
                  isDisabled: !isValid,
                  isLoading: loading,
                  leftIcon: <Icons.Undo boxSize={defaultIconSize} />,
                }}
                onVerified={() => handleSubmit(onSubmit)()}
                label={t('productApproval.setAsPending')}
              />
            </Flex>
          </Flex>
        </Flex>
      </form>
    </>
  );
}
