import {
  Flex,
  Spinner,
  TabList,
  Tabs,
  Tab,
  TabPanel,
  TabPanels,
  Box,
} from '@chakra-ui/react';
import { FileLink, filesApi } from '@texas/api/endpoints/filesApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { RefObject, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EditFileModal } from './EditFileModal';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { ArchiveFile } from './ArchiveFile';
import { FileBrowserMode } from './FileBrowserModal';
import { fileEvents } from '../dropzone/events';
import { RestoreFile } from './RestoreFile';
import { FolderFiles } from './FolderFiles';
import { isSupportedImage } from '@texas/utils/helpers/filesHelper';
import { SearchFilterInput } from '@texas/components/SearchFilterInput';
import { useRegexSearch } from '../hooks/useRegexSearch';

export function FolderOverview({
  id,
  mode,
  onSelect,
  extensionFilter,
  rejectExtensionFilter,
  containerRef,
}: {
  id: number;
  mode: FileBrowserMode;
  onSelect?: (image: FileLink) => void;
  extensionFilter?: string[];
  rejectExtensionFilter?: string[];
  containerRef: RefObject<HTMLDivElement>;
}) {
  const { t } = useTranslation();

  const {
    data: folder,
    refetch,
    loading,
    set,
  } = useApiResource(filesApi.getFolder);
  const {
    value: fileValue,
    isOpen: isEditFileOpen,
    onClose: onEditFileClose,
    onOpen: onEditFileOpen,
  } = useValueDisclosure<FileLink>();
  const {
    value: archiveFileValue,
    isOpen: isArchiveOpen,
    onClose: onArchiveClose,
    onOpen: onArchiveOpen,
  } = useValueDisclosure<FileLink>();
  const {
    value: restoreFileValue,
    isOpen: isRestoreOpen,
    onClose: onRestoreClose,
    onOpen: onRestoreOpen,
  } = useValueDisclosure<FileLink>();

  useEffect(() => {
    refetch(id, mode === 'Edit');
  }, [refetch, id, mode]);

  useEffect(() => {
    return fileEvents.fileUploadComplete.subscribe((file) => {
      if (file.folderId === folder?.id) {
        const files = folder.files;
        files.unshift(file);
        set({ ...folder, files: files });
      }
    });
  }, [folder, set]);

  const { search, setSearch, regexSearch } = useRegexSearch();

  const filteredFiles = useMemo(() => {
    let files = folder?.files;

    if (files) {
      if (extensionFilter && extensionFilter.length > 0)
        files = files.filter((file) =>
          extensionFilter.includes(file.extension),
        );

      if (rejectExtensionFilter && rejectExtensionFilter.length > 0)
        files = files.filter(
          (file) => !rejectExtensionFilter.includes(file.extension),
        );
    }

    return files;
  }, [folder?.files, extensionFilter, rejectExtensionFilter]);

  const regexFilteredIds = useMemo(() => {
    if (!filteredFiles) return {};
    const map: Record<number, number> = {};
    filteredFiles
      .filter((f) => f.name.toLowerCase().match(regexSearch))
      .forEach((x) => {
        map[x.id] = x.id;
      });
    return map;
  }, [filteredFiles, regexSearch]);

  return (
    <>
      {loading && (
        <Flex justifyContent="center">
          <Spinner />
        </Flex>
      )}
      {!loading && folder && (
        <Flex direction="column">
          <Box pb={2}>
            <SearchFilterInput
              placeholder={t('fileBrowser.searchFiles')}
              value={search}
              onChange={setSearch}
              debounceDelay={0}
            />
          </Box>
          {mode === 'Edit' && (
            <Tabs variant="button" size="sm" pb={4}>
              <TabList>
                <Tab>{t('fileBrowser.allFiles')}</Tab>
                <Tab>{t('fileBrowser.images')}</Tab>
                <Tab>{t('fileBrowser.documents')}</Tab>
                <Tab>{t('general.archived')}</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <FolderFiles
                    files={folder.files.filter((f) => !f.archived)}
                    filteredFileIds={regexFilteredIds}
                    mode={mode}
                    onEditFileOpen={onEditFileOpen}
                    onArchiveOpen={onArchiveOpen}
                    onRestoreOpen={onRestoreOpen}
                    containerRef={containerRef}
                  />
                </TabPanel>
                <TabPanel>
                  <FolderFiles
                    files={folder.files.filter(
                      (f) => !f.archived && isSupportedImage(f.extension),
                    )}
                    filteredFileIds={regexFilteredIds}
                    mode={mode}
                    onEditFileOpen={onEditFileOpen}
                    onArchiveOpen={onArchiveOpen}
                    onRestoreOpen={onRestoreOpen}
                    containerRef={containerRef}
                  />
                </TabPanel>
                <TabPanel>
                  <FolderFiles
                    files={folder.files.filter(
                      (f) => !f.archived && !isSupportedImage(f.extension),
                    )}
                    filteredFileIds={regexFilteredIds}
                    mode={mode}
                    onEditFileOpen={onEditFileOpen}
                    onArchiveOpen={onArchiveOpen}
                    onRestoreOpen={onRestoreOpen}
                    containerRef={containerRef}
                  />
                </TabPanel>
                <TabPanel>
                  <FolderFiles
                    files={folder.files.filter((f) => f.archived)}
                    filteredFileIds={regexFilteredIds}
                    mode={mode}
                    onEditFileOpen={onEditFileOpen}
                    onArchiveOpen={onArchiveOpen}
                    onRestoreOpen={onRestoreOpen}
                    containerRef={containerRef}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
          {mode === 'Select' && (
            <FolderFiles
              files={filteredFiles}
              filteredFileIds={regexFilteredIds}
              onSelect={onSelect}
              mode="Select"
              containerRef={containerRef}
            />
          )}
        </Flex>
      )}
      {fileValue && folder && (
        <EditFileModal
          file={fileValue}
          isOpen={isEditFileOpen}
          onClose={onEditFileClose}
          onUpdated={(response) =>
            set({
              ...folder,
              files: folder.files.map((file) => {
                if (file.id === response.id) {
                  return {
                    ...file,
                    name: response.name,
                    note: response.note,
                    updated: response.updated,
                  };
                }
                return file;
              }),
            })
          }
        />
      )}
      {archiveFileValue && folder && (
        <ArchiveFile
          file={archiveFileValue}
          isOpen={isArchiveOpen}
          onClose={onArchiveClose}
          onArchived={(response) => {
            set({
              ...folder,
              files: folder.files.map((file) => {
                if (file.id === response.id) {
                  return {
                    ...file,
                    archived: response.archived,
                    updated: response.updated,
                  };
                }
                return file;
              }),
            });
          }}
        />
      )}
      {restoreFileValue && folder && (
        <RestoreFile
          file={restoreFileValue}
          isOpen={isRestoreOpen}
          onClose={onRestoreClose}
          onRestored={(response) => {
            set({
              ...folder,
              files: folder.files.map((file) => {
                if (file.id === response.id) {
                  return {
                    ...file,
                    archived: response.archived,
                    updated: response.updated,
                  };
                }
                return file;
              }),
            });
          }}
        />
      )}
    </>
  );
}
