import { Role } from '@texas/api/endpoints/userApi';
import { Icons } from './Icons';
import { IconProps } from '@chakra-ui/react';

interface Props extends IconProps {
  userRole: Role;
}

export function RoleIcon({ userRole, ...props }: Props) {
  switch (userRole) {
    case Role.SystemAdministrator:
      return <Icons.ShieldCrown {...props} />;
    case Role.InternalAdministrator:
      return <Icons.ShieldAccount {...props} />;
    case Role.InternalAgent:
      return <Icons.AccountSupervisorCircle {...props} />;
    case Role.Brand:
    case Role.Supplier:
      return <Icons.AccountCircle {...props} />;
  }

  return null;
}
