import {
  Box,
  Img,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Text,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import { clientEndpoints } from '@texas/clientEndpoints';
import { defaultIconSize, Icons } from './Icons';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

type HoverVariant = 'label' | 'scale';

interface Props {
  identifier: string;
  children: React.ReactNode;
  variant?: HoverVariant;
}

export function ExpandImage({
  children,
  identifier,
  variant = 'label',
}: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation();

  return (
    <>
      <Box
        onClick={(e) => {
          e.stopPropagation();
          onOpen();
        }}
        pos="relative"
        role="group"
        cursor="zoom-in"
        {...(variant === 'scale'
          ? {
              transition: 'transform 200ms ease',
              _hover: {
                transform: 'scale(1.05)',
              },
            }
          : null)}
      >
        {children}
        {variant === 'label' && (
          <Box
            pos="absolute"
            left={0}
            right={0}
            bottom={0}
            transition="opacity 200ms ease, bottom 200ms ease"
            opacity={0}
            _groupHover={{ opacity: 1, bottom: 4 }}
          >
            <Flex
              margin="auto"
              align="center"
              justify="center"
              textAlign="center"
              gap={2}
              backgroundColor="blackAlpha.800"
              padding={2}
              w="fit-content"
              _light={{
                backgroundColor: 'whiteAlpha.800',
              }}
            >
              <Icons.ArrowExpand boxSize={defaultIconSize} />
              <Text fontWeight="bold">{t('fileBrowser.clickToExpand')}</Text>
            </Flex>
          </Box>
        )}
      </Box>
      <Modal isCentered={true} isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <InnerModal identifier={identifier} />
      </Modal>
    </>
  );
}

export function InnerModal({ identifier }: { identifier: string }) {
  const [loaded, setLoaded] = useState(false);
  const { t } = useTranslation();
  return (
    <ModalContent w="fit-content">
      <ModalCloseButton bg="whiteAlpha.400" color="white" />
      <Box>
        {!loaded && (
          <Flex gap={2} boxSize="250px" align="center" justify="center">
            <Spinner />
            <Text variant="sub">{t('fileBrowser.loadingImage')}</Text>
          </Flex>
        )}
        <Img
          onLoad={() => setLoaded(true)}
          src={clientEndpoints.previewImage(identifier, 640)}
        />
      </Box>
    </ModalContent>
  );
}
