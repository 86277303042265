// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('users', {
    parent: 'main',
    url: '/users',
    views: {
      'main-content': {
        controller: 'MainUsersController as vm',
        templateUrl: 'main/users/users.tpl.html',
      },
    },
    data: { pageTitle: 'Users' },
  });
};

class MainUsersController {
  constructor() {
    'ngInject';
  }
}

angular
  .module('main.users', [])
  .config(config)
  .controller('MainUsersController', MainUsersController);
