// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration', {
    parent: 'main',
    url: '/configuration',
    views: {
      'main-content': {
        controller: 'MainConfigurationController as vm',
        templateUrl: 'main/configuration/configuration.tpl.html',
      },
    },
    resolve: {
      configurationOptions() {
        return { selectedBranch: null };
      },
    },
    data: { pageTitle: 'Configuration' },
  });
};

class MainConfigurationController {
  constructor($scope, $state, currentOrganization, AuthService) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;

    this.isSystemAdmin = AuthService.hasAnyClaim(['SystemAdministrator']);

    this.tabData = [
      {
        heading: 'Article templates',
        route: 'configuration.articleTemplates',
        active: this.isSystemAdmin,
        hidden: !this.isSystemAdmin,
      },
      {
        heading: 'Article tags',
        route: 'configuration.articleTags',
        hidden: !this.isSystemAdmin,
      },
      {
        heading: 'Branches',
        route: 'configuration.branches',
        hidden: !this.isSystemAdmin,
      },
      {
        heading: 'Countries',
        route: 'configuration.countries',
        hidden: !this.isSystemAdmin,
      },
      {
        heading: 'Currencies',
        route: 'configuration.currencies',
        hidden: !this.isSystemAdmin,
      },
      {
        heading: 'Matrix groups',
        route: 'configuration.matrixGroups',
        hidden: !this.isSystemAdmin,
      },
      {
        heading: 'Matrix nodes',
        route: 'configuration.matrixNodes',
        hidden: !this.isSystemAdmin,
      },
      {
        heading: 'Technical option',
        route: 'configuration.technicalOptions',
        hidden: !this.isSystemAdmin,
      },
      {
        heading: 'Delivery method',
        route: 'configuration.deliveryMethods',
        hidden: !this.isSystemAdmin,
      },
      {
        heading: 'Delivery terms',
        route: 'configuration.deliveryTerms',
        hidden: !this.isSystemAdmin,
      },
      {
        heading: 'Payment terms',
        route: 'configuration.paymentTerms',
        hidden: !this.isSystemAdmin,
      },
      {
        heading: 'Freight costs',
        route: 'configuration.freightCosts',
        hidden: !this.isSystemAdmin,
      },
      {
        heading: 'Handling fees',
        route: 'configuration.handlingFees',
        hidden: !this.isSystemAdmin,
      },
      {
        heading: 'Materials',
        route: 'configuration.materials',
        hidden: !this.isSystemAdmin,
      },
      {
        heading: 'Product Groups',
        route: 'configuration.productGroups',
        hidden: !this.isSystemAdmin,
      },
      {
        heading: 'Techniques',
        route: 'configuration.techniques',
        hidden: !this.isSystemAdmin,
      },
      {
        heading: 'VAT codes',
        route: 'configuration.vatCodes',
        hidden: !this.isSystemAdmin,
      },
      {
        heading: 'Treatments',
        route: 'configuration.treatments',
        hidden: !this.isSystemAdmin,
      },
      {
        heading: 'Dimension groups',
        route: 'configuration.dimensionGroups',
        hidden: !this.isSystemAdmin,
      },
      {
        heading: 'Qualities',
        route: 'configuration.qualities',
        hidden: !this.isSystemAdmin,
      },
      {
        heading: currentOrganization.name,
        route: 'configuration.organization',
        hidden: !this.isSystemAdmin,
      },
      {
        heading: 'Garp',
        route: 'configuration.garp',
        active: !this.isSystemAdmin,
      },
    ];

    $scope.$on('$stateChangeSuccess', () => {
      if (this.$state.is('configuration')) {
        this.goToDefaultState();
      }
    });
  }

  goToDefaultState() {
    const initialTab = _.find(this.tabData, { active: true });
    if (initialTab) {
      this.$scope.$applyAsync(() => {
        this.$state.go(initialTab.route, initialTab.params(), {
          location: 'replace',
        });
      });
    }
  }
}

angular
  .module('main.configuration', [
    'configuration.articleTemplates',
    'configuration.articleTags',
    'configuration.branches',
    'configuration.countries',
    'configuration.matrixGroups',
    'configuration.matrixNodes',
    'configuration.currencies',
    'configuration.garp',
    'configuration.technicalOptions',
    'configuration.deliveryMethods',
    'configuration.deliveryTerms',
    'configuration.paymentTerms',
    'configuration.freightCosts',
    'configuration.handlingFees',
    'configuration.materials',
    'configuration.productGroups',
    'configuration.techniques',
    'configuration.organization',
    'configuration.vatCodes',
    'configuration.treatments',
    'configuration.dimensionGroups',
    'configuration.qualities',
  ])
  .config(config)
  .controller('MainConfigurationController', MainConfigurationController);
