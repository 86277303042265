import { Flex, Text, Grid, GridItem, Spinner } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Role } from '@texas/api/endpoints/userApi';
import { useActiveSession } from '@texas/hooks/useSession';
import { hasRole } from '@texas/utils/helpers/claimHelpers';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { useEffect } from 'react';
import { variantsApi } from '@texas/api/endpoints/variantsApi';
import { ErrorLabel } from '@texas/components/shared/ErrorLabel';
import { ArtworkDocument } from './ArtworkDocument';
import { ArtworkImage } from './ArtworkImage';
import { Photo } from './Photo';

export function GeneralFiles({ variantId }: { variantId: number }) {
  const { t } = useTranslation();

  const {
    data: variantFilesInfo,
    refetch: refetchVariantFilesInfo,
    loading: loadingVariantFilesInfo,
    set: setVariantFilesInfo,
    error,
  } = useApiResource(variantsApi.getVariantFilesInfo);

  useEffect(() => {
    refetchVariantFilesInfo(variantId);
  }, [refetchVariantFilesInfo, variantId]);

  const session = useActiveSession();
  const allowEdit = hasRole(session.currentUser.role, [
    Role.InternalAdministrator,
    Role.InternalAgent,
    Role.SystemAdministrator,
  ]);

  return (
    <>
      {loadingVariantFilesInfo && (
        <Flex gap={4}>
          <Text>{t('general.loading')}</Text>
          <Spinner />
        </Flex>
      )}
      {variantFilesInfo && (
        <Grid templateRows="1fr auto" templateColumns="repeat(2, auto)" gap={6}>
          <GridItem>
            <ArtworkImage
              variantId={variantId}
              allowEdit={allowEdit}
              variantFilesInfo={variantFilesInfo}
              setVariantFilesInfo={setVariantFilesInfo}
            />
          </GridItem>
          <GridItem>
            <Photo
              variantId={variantId}
              allowEdit={allowEdit}
              variantFilesInfo={variantFilesInfo}
              setVariantFilesInfo={setVariantFilesInfo}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <ArtworkDocument
              variantId={variantId}
              allowEdit={allowEdit}
              variantFilesInfo={variantFilesInfo}
              setVariantFilesInfo={setVariantFilesInfo}
            />
          </GridItem>
        </Grid>
      )}
      {!variantFilesInfo && <ErrorLabel text={error?.message} />}
    </>
  );
}
