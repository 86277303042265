// @ngInject
const config = ($stateProvider) => {
  $stateProvider.state('configuration.currencies', {
    url: '/currencies',
    controller: 'CurrenciesConfigurationController as vm',
    templateUrl: 'main/configuration/currencies/currencies.tpl.html',
    data: { pageTitle: 'Currencies' },
  });
};

class CurrenciesConfigurationController {
  constructor(
    $scope,
    DS,
    CurrenciesApiService,
    loadSpinnerService,
    CurrencyDialogService,
    BranchesApiService,
    configurationOptions,
    currentOrganization,
    AuthService,
  ) {
    'ngInject';

    this.CurrenciesApiService = CurrenciesApiService;
    this.loadSpinnerService = loadSpinnerService;
    this.CurrencyDialogService = CurrencyDialogService;
    this.BranchesApiService = BranchesApiService;
    this.configurationOptions = configurationOptions;

    this.allowedToCreateNew = AuthService.hasAnyClaim(['SystemAdministrator']);
    this.currencies = [];
    this.selectedBranch = {};
    this.branches = currentOrganization.branches;
    this.noop = _.noop;
    this.query = {
      filter: '',
      order: 'name',
      limit: 50,
      page: 1,
    };

    this.selectBranch(configurationOptions.selectedBranch);

    $scope.$watch(
      () => DS.lastModified('currency'),
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.currencies = CurrenciesApiService.getAllFromCache(
            this.selectedBranch.id,
          );
        }
      },
    );
  }

  loadCurrencies() {
    this.loadSpinnerService.start('mainSpinner');
    this.CurrenciesApiService.getAllByBranchId(this.selectedBranch.id)
      .then((currencies) => (this.currencies = currencies))
      .finally(() => this.loadSpinnerService.stop('mainSpinner'));
  }

  newCurrency() {
    this.CurrencyDialogService.showDialog({
      branch: this.selectedBranch,
      allowedToEdit: this.allowedToCreateNew,
    });
  }

  openCurrency(currency) {
    if (this.preventOpen) {
      return;
    }

    this.CurrencyDialogService.showDialog({
      branch: this.selectedBranch,
      currency,
      allowedToEdit: this.allowedToCreateNew,
    });
  }

  selectBranch(branch) {
    this.selectedBranch = branch || this.branches[0];
    this.configurationOptions.selectedBranch = this.selectedBranch;
    this.loadCurrencies();
  }

  setDefault(currency) {
    if (currency) {
      this.preventOpen = true;
      this.loadSpinnerService.start('mainSpinner');
      this.BranchesApiService.updateBranch(
        { defaultCurrencyId: currency.id },
        this.selectedBranch.id,
      ).finally(() => {
        this.preventOpen = false;
        this.loadSpinnerService.stop('mainSpinner');
      });
    }
  }
}

angular
  .module('configuration.currencies', [])
  .config(config)
  .controller(
    'CurrenciesConfigurationController',
    CurrenciesConfigurationController,
  );
