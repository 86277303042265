import { HStack, Spinner, VStack, Text, Box, Flex } from '@chakra-ui/react';
import { SearchFilterInput } from '../../SearchFilterInput';
import { useEffect, useState } from 'react';
import { BrandCard } from './BrandCard';
import { angularEvents } from '../../../../bridge/angularEvents';
import { useTranslation } from 'react-i18next';
import { SwitchFilterElement } from '../../filter/SwitchFilter';
import { customersApi, BrandsDto } from '@texas/api/endpoints/customersApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { useActiveSession } from '@texas/hooks/useSession';
import { RoleRestrict } from '@texas/components/shared/RoleRestrict';
import { BaseWidgetProps } from '../widgets';
import { WidgetWrapper } from '../shared/components/WidgetWrapper';
import { BaseWidgetOptions, WidgetType } from '../shared/types';
import { Role } from '@texas/api/endpoints/userApi';

interface Props extends BaseWidgetProps {
  options?: BaseWidgetOptions;
}

export function BrandsWidget({ color, customTitle, options, widgetId }: Props) {
  const {
    data: brands,
    refetch,
    loading,
    set,
  } = useApiResource(customersApi.filterCustomers);
  const [filter, setFilter] = useState('');
  const { currentUser } = useActiveSession();
  const [userId, setUserId] = useState<number | null>(currentUser.id);

  useEffect(() => {
    if (!userId && filter.length === 0) {
      set(null);
      return;
    }
    refetch({
      assignedTo: userId,
      customerName: filter,
    });
  }, [userId, filter, refetch, set]);

  const { t } = useTranslation();
  useEffect(() => {
    return angularEvents.newBrandCreated.subscribe((brand: BrandsDto) => {
      set((s) => (s === null ? [brand] : [...s, brand]));
    });
  });

  return (
    <WidgetWrapper
      widgetId={widgetId}
      loading={loading}
      settings={options?.componentSettings}
      customColor={color}
      type={WidgetType.Brands}
      style="borderless"
      header={customTitle ?? t('widgets.brands.yourBrands')}
    >
      <VStack align="start" pos="relative" height="100%">
        <HStack>
          <RoleRestrict
            allow={[Role.InternalAdministrator, Role.SystemAdministrator]}
          >
            <SwitchFilterElement
              static={false}
              onChange={(value) => {
                setUserId(value ? null : currentUser.id);
              }}
              offLabel={t('filter.myBrands')}
              onLabel={t('filter.allBrands')}
              checked={userId === null}
              name={t('filter.myBrands')}
            />
            <SearchFilterInput
              style="unstyled"
              value={filter}
              placeholder={t('widgets.brands.filterBrands')}
              onChange={(value) => setFilter(value)}
            />
          </RoleRestrict>

          {loading && (
            <Box>
              <Spinner />
            </Box>
          )}
        </HStack>
        <Brands brands={brands} isLoading={loading} />
      </VStack>
    </WidgetWrapper>
  );
}

function Brands({
  brands,
  isLoading,
}: {
  brands: BrandsDto[] | null;
  isLoading: boolean;
}) {
  const { t } = useTranslation();
  if (!brands && !isLoading) {
    return (
      <Text margin="auto !important" alignSelf="center">
        {t('widgets.brands.filterAbove')}
      </Text>
    );
  }
  return <BrandsInnerComponent brands={brands} />;
}

function BrandsInnerComponent({ brands }: { brands: BrandsDto[] | null }) {
  return (
    <Flex gap={2} flexWrap="wrap" overflow="auto">
      {brands?.map((b) => (
        <BrandCard
          key={b.id}
          id={b.id}
          name={b.name}
          nrOfArticles={b.nrOfArticles}
        />
      ))}
    </Flex>
  );
}
