import { httpClient } from '../httpClient/httpClient';

export interface CreateConceptRequest {
  name: string;
}
interface Concept {
  id: number;
  name: string;
  archived?: string;
  customerId: number;
  folderId: number;
}
export const conceptsApi = {
  addConcept: (customerId: number, data: CreateConceptRequest) =>
    httpClient.post<Concept>(`concepts/create/${customerId}`, { data }),
};
