import { httpClient } from '../httpClient/httpClient';

export const authApi = {
  login: (email: string, password: string) =>
    httpClient.post('auth', { data: { email, password } }),
  logout: () => httpClient.delete('auth'),
  sendResetPasswordToken: (userEmail: string) =>
    httpClient.post('auth/send-reset-password-token', { data: { userEmail } }),
  resetPassword: (userId: string, token: string, newPassword: string) =>
    httpClient.post('auth/reset-password', {
      data: { userId, token, newPassword },
    }),
};
