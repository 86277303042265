import {
  Flex,
  Menu,
  MenuButton,
  Box,
  Portal,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { Icons } from '../shared/Icons';
import { IconWithBadge } from '../shared/IconWithBadge';
import { ArchivedTooltip } from '../shared/tooltip/ArchivedTooltip';
import { Role, UserListItem } from '@texas/api/endpoints/userApi';
import { RoleRestrict } from '../shared/RoleRestrict';
import { roleFriendlyName } from '@texas/utils/helpers/claimHelpers';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';

export const usersColumns = (
  t: TFunction,
  onArchiveOpen: (user: UserListItem) => void,
  onRestore: (id: number) => Promise<void>,
  onEditOpen: (id: number) => void,
  onRoleOpen: (id: number) => void,
  onWelcomeSent: (id: number) => void,
  onLogoutDevices: (id: number) => Promise<void>,
) => {
  const columnHelper = createColumnHelper<UserListItem>();
  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: t('general.name'),
      maxSize: 100,
    }),
    columnHelper.accessor('email', {
      cell: (info) => info.getValue(),
      header: t('general.email'),
      maxSize: 200,
    }),
    columnHelper.accessor('branch', {
      cell: (info) => info.getValue(),
      header: t('general.branch'),
      maxSize: 200,
    }),
    columnHelper.accessor('role', {
      cell: (info) => roleFriendlyName(convertToEnum(Role, info.getValue()), t),
      header: t('auth.role'),
      maxSize: 200,
    }),
    columnHelper.accessor('id', {
      cell: (info) => (
        <>
          <Flex justifyContent="end" alignItems="center" gap={4}>
            {info.row.original.archived && (
              <ArchivedTooltip archived={info.row.original.archived} />
            )}
            <RoleRestrict allow={[Role.SystemAdministrator]}>
              <Menu placement="left">
                <MenuButton float="right">
                  <Box>
                    <IconWithBadge
                      tooltipLabel="More"
                      icon={<Icons.DotsHorizontal boxSize={4} />}
                    />
                  </Box>
                </MenuButton>
                <Portal>
                  <MenuList color="white" zIndex={99}>
                    <MenuItem
                      icon={<Icons.Pencil boxSize={5} />}
                      onClick={() => onEditOpen(info.row.original.id)}
                    >
                      {t('general.edit')}
                    </MenuItem>

                    {info.row.original.archived ? (
                      <MenuItem
                        color="texas.sand.100"
                        icon={<Icons.Undo boxSize={5} />}
                        onClick={() => onRestore(info.row.original.id)}
                      >
                        {t('general.restore')}
                      </MenuItem>
                    ) : (
                      <MenuItem
                        color="texas.sand.50"
                        icon={<Icons.Archive boxSize={5} />}
                        onClick={() => onArchiveOpen(info.row.original)}
                      >
                        {t('general.archive')}
                      </MenuItem>
                    )}
                    <MenuDivider />
                    <MenuItem
                      icon={<Icons.EmailFast boxSize={5} />}
                      onClick={() => onWelcomeSent(info.row.original.id)}
                    >
                      {t('user.sendWelcomeEmail')}
                    </MenuItem>
                    <MenuItem
                      icon={<Icons.ShieldEdit boxSize={5} />}
                      onClick={() => onRoleOpen(info.row.original.id)}
                    >
                      {t('user.changeRole')}
                    </MenuItem>
                    <MenuItem
                      icon={<Icons.CellphoneLinkOff boxSize={5} />}
                      onClick={() => onLogoutDevices(info.row.original.id)}
                    >
                      {t('user.signOutDevices')}
                    </MenuItem>
                  </MenuList>
                </Portal>
              </Menu>
            </RoleRestrict>
          </Flex>
        </>
      ),
      header: '',
      enableSorting: false,
      maxSize: 100,
    }),
  ];

  return columns;
};
